import {
  AppBar,
  Box,
  Container,
  FormControl,
  OutlinedInput,
  Paper,
  Toolbar,
  Typography,
  Button,
  FormHelperText,
  Alert,
  AlertTitle,
  CircularProgress,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Col, FormLabel, Row, Table } from "react-bootstrap";
import { useNavigate, useParams,useLocation } from "react-router-dom";
import { getApi } from "../services";
import { PaymentState as SearchReduxState, useSelector } from "../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../redux/User/actions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import { Image } from "react-bootstrap";
import { useAuth } from "../auth/AuthProvider";
import "./login.css";
import moment from "moment";
// import "./Login.css";

export const Login: React.FC = () => {
  const { login } = useAuth();
  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const {name,password} = useParams()

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [mobilenumber, setMobileNumber] = useState(searchRedux.user_phone_no);
  const [nameerror, seterror1] = useState(false);
  const [passerror, setpasserror] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const location = useLocation();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const getapi = getApi();


  /* password production */

  const passwordRef = useRef(null);

  // const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
  //   e.preventDefault();
  //   navigator.permissions.query({ name: 'clipboard-read' } ).then((result) => {
  //     if (result.state === 'granted' || result.state === 'prompt') {
  //       // You have clipboard read access.
  //     }
  //   });
  // };

useEffect(() =>{
console.log(location.pathname)
},[searchRedux,location])
  
const [uniqueValue,setuniqueValue] = useState<any>()
useEffect(() =>{



  function getCookie(name:any) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Check if this cookie starts with the name we're looking for
        if (cookie.startsWith(name + '=')) {
            // If found, return the value of the cookie
            return cookie.substring(name.length + 1);
        }
    }
    // If cookie not found, return null
    return null;
}

// Usage
const uniqueValue = getCookie('uniquevalue');
console.log(uniqueValue); 
if(uniqueValue){
Tockencheck(uniqueValue)
}
  },[searchRedux])



  const Tockencheck = (uniqueValue:any) => {
    setLoading(true)
    let data = {
      'ids' : uniqueValue
    };
    //console.log(data);
    getapi
      .post("halologinunique/",data)
      .then((response) => {
        if(response.data.Success){
          setLoading(false)
          console.log("response data",response.data.data[0].UserName);
          navigate("/login/"+response.data.data[0].UserName +'/'+response.data.data[0].Password);
        }
        else{
          setLoading(false)
          console.log(response.data.Message)
        }
        
      
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
      });
  };



  const Login2 = () => {
    let data = {
      username: username,
      password: password,
    };
    //console.log(data);
    getapi
      .post("halologin/")
      .then((response) => {
        //console.log("response data");
        //console.log(JSON.stringify(response.data.data));
        dispatch(UpdatePaymentData("userid", response.data.UserId));
        dispatch(UpdatePaymentData("location_id", response.data.LocationId));
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const [responseData, setResponseData] = useState(null);

/* device checking function */

const [loginfrom,setLoginFrom] = useState('')
function isMobileDevice() {
  // Check if the user agent contains 'Mobi', 'Android', or 'iPhone'
  // and also check if it's not running in an MSI environment
  return /Mobi|Android|iPhone/i.test(navigator.userAgent) && !/msi/i.test(navigator.userAgent);
}
function isMSI() {
  // Check if the user agent contains 'msi'
  return /msi/i.test(navigator.userAgent);
}

function isDesktop() {
  // Check if the platform is not 'iPhone', 'iPad', 'iPod', 'Android', or 'Mobile'
  return !/(iPhone|iPad|iPod|Android|Mobile)/i.test(navigator.userAgent);
}
function isTabletDevice() {
  return /iPad|Tablet|PlayBook|Silk|Kindle|Nexus 7|Nexus 10|KFAPWI|SM-T|GT-P|SCH-I800|Xoom|Transformer|TF101|Slate|Acer|Iconia|A500|A501|BNTV250|SHIELD|K00F/i.test(navigator.userAgent);
 // return /iPad|Tablet|PlayBook|Silk|Kindle|Nexus 7|Nexus 10|KFAPWI/i.test(navigator.userAgent) && !/Mobi|iPhone/i.test(navigator.userAgent);
}

useEffect(() =>{
  if (isMobileDevice()) {
   // console.log("This is a mobile device");
    setLoginFrom('Mobile')
  }
  else if (isMSI()){
   // console.log("This app is running in an MSI environment.");
    setLoginFrom('MSI')
  }
  else if (isDesktop()){
   // console.log("This website is running on a desktop.");
    setLoginFrom('Desktop')
  }
  else if(isTabletDevice()){
   //  console.log("This website is running on a tablet_view.");
    setLoginFrom('tablet_view')
    dispatch(UpdatePaymentData("Loginfrom", 'tablet_view'));
  }
  else{
   // console.log("This website is running on a other.");
  }
  
},[])


 const deleteCookie = (name:any) => {
    // Set the cookie's expiration date to a date in the past
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

const [submitting,setSubmiting] = useState(false)
  const Login = async () => {
    setSubmiting(true)
    if (username.trim() === "" || password.trim() === "") {
      seterror1(username.trim() === "");
      setpasserror(password.trim() === "");
      setSubmiting(false)
      return;
    }


    seterror1(false);
    setpasserror(false);

    var loginfrom = "";
    if (isMobileDevice()) {
     // console.log("This is a mobile device");
      loginfrom = "Mobile"
      dispatch(UpdatePaymentData("Loginfrom", 'Mobile'));
    }
    else if (isMSI()){
     // console.log("This app is running in an MSI environment.");
      loginfrom = "MSI" 
    }
    else if (isDesktop()){
   //   console.log("This website is running on a desktop.");
    loginfrom ="Desktop"

    }
    else if(isTabletDevice()){
     // console.log("This website is running on a tablet_view.");
      loginfrom="tablet_view"
      dispatch(UpdatePaymentData("Loginfrom", 'tablet_view'));
   }
    else{
     // console.log("This website is running on a other.");
    }
    
  
    try {
      //const apiUrl = "http://101.0.92.122:8008/api/halologin/"; // Replace with your API URL
      const apiUrl = "https://coddgpapi.coddapps.com.au/api/halologinotp/"; // Replace with your API URL
      const requestBody = {
        username: username,
        password: password, 
        is_diect:'2',
        login_from:loginfrom    /* device checking */
      };
     // console.log("details", requestBody);
      
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Validate: "y2s4pyj52nzr49jnuxxgqk5jtj28cj",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        setSuccess(true);
        const data = await response.json();

        setSubmiting(false)
        dispatch(UpdatePaymentData("username", username));
        dispatch(UpdatePaymentData("password", password));

      //  navigate("/two-factor");
        // dispatch(UpdatePaymentData("id", data.data[0].Id));
        // dispatch(UpdatePaymentData("userid", data.data[0].UserId));
        // dispatch(UpdatePaymentData("location_id", data.data[0].LocationId));
        // dispatch(UpdatePaymentData("username", data.data[0].UserName));
        // dispatch(UpdatePaymentData("password", data.data[0].Password));
        // dispatch(UpdatePaymentData("mobilenumber", data.data[0].MobileNumber));
        // dispatch(UpdatePaymentData("role", data.data[0].Role));
        // login();
        // const todat = moment().add().format("YYYY-MM-DD");
        // dispatch(UpdatePaymentData("FromDate", todat));
        // dispatch(UpdatePaymentData("ToDate", todat));
        // dispatch(UpdatePaymentData("selecteddate", todat));
       
        var isTherecooki =0;
        const name="userCookie";
        const cookieArray = document.cookie.split('; ');
        for (const cookie of cookieArray) {
          const [cookieName, cookieValue] = cookie.split('=');

          if (cookieName === name) {

            //console.log("cookieName...");
            if(cookieValue !=''){

              const parts = cookieValue.split("#_#");

              //console.log('cookie name==',parts[0])
              if(parts[0] === username && parts[1] === password){
                isTherecooki =1;
                const loginparam = cookieValue.replace("#_#","/");
                navigate("/login/"+loginparam);
              }
           
            }else{
              deleteCookie("myCookieName");
              isTherecooki =0;
              setSubmiting(false)
            }
          }
        }

        if(isTherecooki ==0){
         // console.log('isTherecooki 0')
          /* check the user otp status */
          if(data.data && data.data[0]?.OTPStatus === 0){
         //   console.log('OTPStatus 0')
            const loginparam = username+'/'+password;
            navigate("/login/"+loginparam);
          }
          else{
         //   console.log('OTPStatus no')
            navigate("/two-factor");
          }
              
        }




      } else if (response.status == 404) {
        setError(true);
        setSubmiting(false)
        console.error("Wrong username or password");
      } else {
        console.error("Error:", response.status, response.statusText);
        seterror1(true);
        setpasserror(true);
        setSubmiting(false)
      }
    } catch (error) {
      console.error("Error:", error);
      setSubmiting(false)
    }
  };

  const handleRecoverAccount = () => {
    navigate("/recover-account");
  };

  useEffect(() => {
    dispatch(UpdatePaymentData("userid", ""));
  }, []);

  useEffect(() => {
    const inputString = "d.t#_#d.t.p";
const parts = inputString.split("#_#");

//console.log('result----',parts[0]);

   
  }, []);


  // useEffect(() =>{
  //   const loginparam = username+'/'+password;
  //   console.log("/login/"+loginparam);
  // },[username])
  return (
    <Fragment>
      {loading && <p>Loading</p> ?

<div id='direct_login'>
<div className="loader">
  <div className="d-flex justify-content-center flex-direction-column" style={{
    flexDirection:'column'
  }}>
<Image
      style={{
        margin: 10,
      }}
      src={require("../assets/images/logo.png")}
      width="240"
      height="60"
    />
   <div className="text-center justify-content-center">
   <CircularProgress color='secondary' />
    </div> 
</div>

</div>
 
</div>

      :
      <Fragment>
      {!matches ? (
        <div className="login-web-view">
          <Container>
            <Row>
              <Col md={10} className="mx-auto">
                <Box
                  component={Paper}
                  elevation={2}
                  p={0}
                  mt={0}
                  className="boxes cus-b-radus"
                >
                  <div className="login-div">
                    <Row>
                      <Col md={6}>
                        <img
                          src={require("../assets/images/Maskgroupweb.png")}
                          alt="Logo"
                          className="img-fluid cus-b-radus-l"
                        />
                      </Col>
                      <Col md={6}>
                        <div>
                          <div className="text-center mt-4">
                            <Typography className="first-text-web" variant="h4">
                              Hey, Welcome Back!
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              className="mt-3 mb-2 second-text-web"
                            >
                              Login to your Account
                            </Typography>
                          </div>

                          {error && (
                            <div>
                              <Alert
                                severity="error"
                                onClose={() => setError(false)}
                              >
                                Incorrect username or password. Please try
                                again.
                              </Alert>
                            </div>
                          )}

                          {success && (
                            <Alert
                              elevation={60}
                              severity="success"
                              onClose={() => setSuccess(false)}
                            >
                              Login successful!
                            </Alert>
                          )}

                          <div>
                            <form autoComplete="on" className="custom-login-form">
                              <FormControl error={nameerror}>
                                <FormLabel className="login-label">
                                  Username
                                </FormLabel>
                                <OutlinedInput
                                  name="username"
                                  type="text"
                                  value={username}
                                  autoComplete="username"
                                  onChange={(e) => {
                                    seterror1(false);
                                    setError(false)
                                    setUsername(e.target.value);
                                  }}
                                  className="mt-1 input-border login-form"
                                  placeholder="Enter Username"
                                  sx={{
                                    input: {
                                      color: "#000",
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                      "&::placeholder": {
                                        // <----- Add this.
                                        opacity: 1,
                                      },
                                    },
                                  }}
                                />

                                {nameerror && (
                                  <FormHelperText>
                                    Please Enter Username
                                  </FormHelperText>
                                )}
                              </FormControl>


                              <FormControl error={passerror} className="mt-4">
                                <FormLabel className="login-label">
                                  Password
                                </FormLabel>
                                <OutlinedInput
                                  name="password"
                                  type="password"
                                  ref={passwordRef}
                                  // onPaste={(e: any) => {
                                  //   e.preventDefault();
                                  //   return false;
                                  // }}
                                  value={password}
                                  autoComplete="current-password"
                                  onChange={(e) => {
                                    setpasserror(false);
                                    setError(false)
                                    setPassword(e.target.value);
                                  }}
                                  onKeyDown={(event) => {
                                   // console.log(event.ctrlKey)
                                    if (event.key === 'Enter')
                                    Login()
                                  }}
                                  className="mt-1 input-border login-form"
                                  placeholder="Enter Password"
                                  sx={{
                                    input: {
                                      color: "#000",
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                      "&::placeholder": {
                                        // <----- Add this.
                                        opacity: 1,
                                      },
                                    },
                                  }}
                                />

                                {passerror && (
                                  <FormHelperText>
                                    Please Enter Password
                                  </FormHelperText>
                                )}
                              </FormControl>


                              <div>
                                <Button
                                  className="login-forgot"
                                  onClick={handleRecoverAccount}
                                >
                                  Forgot Password?
                                </Button>
                              </div>
                              <Button
                                className="login-login-btn"
                                onClick={Login}
                               disabled={submitting}
                              >
                               {submitting ? <CircularProgress style={{
                      color:"#FFF"
                     }}  size={24} />:  'Login'} 
                              </Button>
                              <div className="mt-3">
                            <p className="info_login">Don't have an account?<br/>
                            Contact <a className="ahover" href='mailto:support@codd.com.au'>support@codd.com.au</a> to set up one.</p>
                          </div>
                            </form>
                        

                          </div>
                         
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Box>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div id={location?.pathname && location?.pathname === '/' ? 'logincss' :''} className="login-mobile-view  loginbanner">
          <Row>
            <Col md={12} className="pd-r-top-0">
              <Box
                component={Paper}
                elevation={2}
                p={0}
                mt={0}
                className="boxes gray_bg"
              >
                 <div className="image-container">
                  <img
                    src={require("../assets/images/mob_banner.png")}
                    alt="Logo"
                    className="img-fluid custom-banner"
                  />
                  </div>
                  
                {/* <Col md={12}>
                <div className="image-container">
                  <img
                    src={require("../assets/images/mob_banner.png")}
                    alt="Logo"
                    className="img-fluid custom-banner"
                  />
                  </div>
                </Col> */}
                <div className="p-4">
                  <Row>
                    <Col md={12}>
                      <div className="text-center mt-3">
                        <Typography variant="h5" className="first-text">
                          Hey, Welcome Back!
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className="mt-3 second-text"
                        >
                          Login to your Account
                        </Typography>
                      </div>
                      {error && (
                        <div className="mt-2 mb-2">
                          <Alert
                            severity="error"
                            onClose={() => setError(false)}
                          >
                            Incorrect username or password. Please try again.
                          </Alert>
                        </div>
                      )}

                      {success && (
                        <Alert
                          severity="success"
                          onClose={() => setSuccess(false)}
                        >
                          Success Login successful!
                        </Alert>
                      )}
                      <div className="loginpageform">
                        <form autoComplete="on" className="custom-login-form mt-3">
                          <FormControl fullWidth error={nameerror}>
                            <FormLabel className="login-label">
                              Username
                            </FormLabel>
                            <OutlinedInput
                              name="username"
                              type="text"
                              autoComplete="username"
                              onChange={(e) => {
                                seterror1(false);
                                setUsername(e.target.value);
                              }}
                              className="mt-1 input-border input-phone login-form"
                              placeholder="Enter Username"
                              sx={{
                                input: {
                                  color: "#000000",
                                  fontSize: "15px",
                                  fontWeight: "900",
                                  "&::placeholder": {
                                    // <----- Add this.
                                    opacity: 1,
                                  },
                                },
                                label: { color: "blue" },
                              }}
                            />
                            {nameerror && (
                              <FormHelperText>
                                Please Enter Username
                              </FormHelperText>
                            )}
                          </FormControl>
                          <FormControl
                            fullWidth
                            error={passerror}
                            className="mt-2"
                          >
                            <FormLabel className="login-label">
                              Password
                            </FormLabel>
                            <OutlinedInput
                              onChange={(e) => {
                                setPassword(e.target.value);
                                setpasserror(false);
                              }}
                              className="mt-1 input-border input-phone login-form"
                              name="password"
                              type="password"
                              autoComplete="current-password"
                              placeholder="Enter Password"
                              onKeyDown={(event) => {
                                // console.log(event.ctrlKey)
                                 if (event.key === 'Enter')
                                 Login()
                               }}
                              sx={{
                                input: {
                                  color: "#000",
                                  fontSize: "15px",
                                  fontWeight: "900",
                                  "&::placeholder": {
                                    // <----- Add this.
                                    opacity: 1,
                                  },
                                },
                                label: { color: "blue" },
                              }}
                            />
                            {passerror && (
                              <FormHelperText>
                                Please Enter Password
                              </FormHelperText>
                            )}
                          </FormControl>
                          <div className="mt-3 mb-3 text-end">
                            <Button
                              className="login-forgot"
                              onClick={handleRecoverAccount}
                            >
                              Forgot Password?
                            </Button>
                          </div>
                          {/* <Button className="login-login-btn" onClick={Login}>
                            Login
                          </Button> */}
                          <Button
                                className="login-login-btn"
                                onClick={Login}
                               disabled={submitting}
                              >
                               {submitting ? <CircularProgress style={{
                      color:"#FFF"
                     }}  size={24} />:  'Login'} 
                              </Button>
                              <div className="mt-3">
                            <p className="info_login">Don't have an account?<br/>
                            Contact <a className="ahover" href='mailto:support@codd.com.au'>support@codd.com.au</a> to set up one.</p>
                          </div>
                        </form>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Box>
            </Col>
          </Row>
        </div>
      )}
      </Fragment>
}
    </Fragment>
  );
};
