import {
  Box,
  Paper,
  Typography,
  Button,
  TextField,
  InputAdornment,
  OutlinedInput,
  FormHelperText,
  FormControl,
  FormLabel,
} from "@mui/material";

import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Table, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PaymentState as SearchReduxState, useSelector } from "../redux";
import { useDispatch } from "react-redux";

import { getApi } from "../services";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";

export const Sendsms: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = useState("");

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const [mnumber, mnumberSet] = useState("61");
  const maxCharacters = 160;

  const [mnumbererror, setnumbererror] = useState(false);
  const [texterror, settexterror] = useState(false);
  const [mobilenoerror, setmobileerror] = useState(false);
  const [error, setError] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleChangeMB = (event: any) => {
    setnumbererror(false)
    const inputValue = event.target.value;
    const inputNumber = event.target.value.replace(/\D/g, '').slice(0, 11); // Restrict to 10 digits
    mnumberSet(inputNumber);
  };

  const handleChange = (event: any) => {
    const inputValue = event.target.value;
settexterror(false)
    if (inputValue.length <= maxCharacters) {
      setText(inputValue);
    }
  };

  // mobile_no: 61451043429,

  const getapi = getApi();

  const handleFocus = (e:any) => {
    e.preventDefault();
    e.target.focus({ preventScroll: true });
  };
  
  const handleMobileNumberChange = (event: any) => {
    setnumbererror(false)
    const inputValue = event.target.value;
    // Regex to match only 11-digit numbers
    const regex = /^\d{0,11}$/; // Allows only digits, up to 11 characters
    if (regex.test(inputValue)) {
      mnumberSet(inputValue);
    }
};


  const sendsms = () => {


    const trimmedNumber = mnumber.replace(/\s/g, '');

    if (trimmedNumber.length !== 11  && /^\d+$/.test(trimmedNumber))  {
      setnumbererror(true);
      settexterror(text.trim() === "");
      return;
    }

    
    if (!/^\d{11}$/.test(mnumber)) {
      setnumbererror(true);
      return;
    }

    setnumbererror(false);
    settexterror(false);


    if (mnumber.trim() === "" || text.trim() === "") {
      setnumbererror(mnumber.trim() === "");
      settexterror(text.trim() === "");

      return;
    }
    let data = { 
      ref_id: "201822315061111",
      loctn_id: searchRedux.location_id,
      usr_id: searchRedux.userid,
      mobile_no: mnumber,
      // mobile_no: 61451043429,
      text: text,
      crt_by: "b.gibson",
    };
   // console.log(data);

    getapi
      .post("halosentsms/", data)
      .then((res) => {
        //console.log("logout response:->", res);

        if (res.data.Success) {
          setMsg(res.data.Message);
          mnumberSet('61')
          setText('')
          setOpen(true);
        }
      })
      .catch((err) => {
        //console.log("error", err);
        setnumbererror(true);
        settexterror(true);
      });
  };

  useEffect(() => {
    // This is the cleanup function
    return () => {
      // dispatch(UpdatePaymentData("user_phone_no", ""));
      // //console.log("Cleanup ran");
    };
  }, []);

  return (
    <Fragment>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {msg}
        </Alert>
      </Snackbar>


      {!matches ? (
        <div className="sms-web-view">
          <Row>
            <Col md={11} className="mx-auto">
              <Box
                component={Paper}
                elevation={2}
                p={0}
                mt={3}
                className="boxes"
              >
                <div className="p-3 no_border">
                  {/* <Form className="sms-p-5"> */}
                  <FormControl sx={{ borderRadius:10 }} error={mnumbererror} fullWidth  variant="outlined">
                              <p style={{marginBottom:3,fontWeight:600}}>Mobile Number</p>
                              <OutlinedInput
                              sx={{ borderRadius:10 }}
                              error={mnumbererror}
                              fullWidth
                              className="mt-1 sinput-border"
                               
                              //  startAdornment={<InputAdornment position="start"><b>+61</b></InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  maxLength: 11, // Maximum length of the input
                                  pattern: '[0-9]*', // Pattern to allow only numeric input
                                  inputMode: 'numeric', // Specifies the input type as numeric
                                }}
                               // type="number"
                                placeholder='Enter Mobile Number'
                                id="mb_sms"
                                value={mnumber}
                                onFocus={handleFocus}
                                onChange={handleMobileNumberChange}
                                // onChange={(e) => {
                                // //   const enteredValue = e.target.value;
                                // // //  const numericValue = enteredValue.replace(/\D/g, ''); // Remove non-numeric characters
                                // // const numericValue = enteredValue.replace(/^\d{11}$/, '');
                                // // console.log('numericValue',numericValue)
                                // //   const maxLength = 11; // Maximum allowed length
                                  
                                // //   if (numericValue.length <= maxLength) {
                                // //     mnumberSet(numericValue); // Set the numeric value in the state
                                // //     setnumbererror(false);
                                // //   }

                                // }}
                              />
                            {mnumbererror &&  
                            <FormHelperText id="outlined-weight-helper-text">
                             Invalid Mobile Number 
                             </FormHelperText>
                             } 

                              </FormControl>





                    {/* {mnumbererror && (
                      <p style={{ color: "red" }}>
                        Please Enter 10 Digit Mobile number
                      </p>
                    )} */}
                    <Form.Group
                      className="mt-4"
                      controlId="exampleForm.ControlTextarea1"
                    >

                  
                      <Form.Control
                        className="custom-textarea smstxt2"
                        as="textarea"
                        rows={10}
                        maxLength={160}
                        isInvalid={texterror}
                        onChange={handleChange}
                        placeholder="Write Your SMS Content ... below 160 characters"
                      />
                    </Form.Group>
                    {texterror && (
                      <p style={{ color: "red", marginTop: "5px" }}>
                        Please Enter Characters
                      </p>
                    )}
                  {/* </Form> */}
                </div>
                <div className="p-3">
                  <div className="">
                    <Row>
                      <Col>
                        <Typography>
                          <b>
                            Characters Remaining: {text.length}/{maxCharacters}
                          </b>
                        </Typography>
                      </Col>
                      <Col>
                        <Typography style={{ textAlign: "end" }}>
                          <Button
                            className="send-btn"
                            onClick={() => sendsms()}
                          >
                            Send
                          </Button>
                        </Typography>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Box>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="sms-mob-view m-3 coddgp_mobile_view">
          <Row>
            <Col md={12} className="mx-auto kpi-pad">
              <Box
                component={Paper}
                elevation={2}
                p={0}
                mt={3}
                className="boxes"
              >
                <div className="">
                  <Form className="p-3 no_border">
                  <FormControl error={mobilenoerror} fullWidth  variant="outlined">
                      
                      <OutlinedInput
                      error={mobilenoerror}
                      fullWidth
                      className="mt-1 sinput-border"
                        id="mb_sms"
                      //  startAdornment={<InputAdornment position="start"><b>+61</b></InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          maxLength: 11, // Maximum length of the input
                          pattern: '[0-9]*', // Pattern to allow only numeric input
                          inputMode: 'numeric', // Specifies the input type as numeric
                        }}
                       // type="number"
                        placeholder='Enter Mobile Number'
                        value={mnumber}
                        onFocus={handleFocus}
                        onChange={handleMobileNumberChange}
                        // inputProps={{
                        //   'aria-label': 'phone',
                        //   'maxLength': 11,
                        // }}
                        // type="number"
                        // placeholder='Enter Mobile Number'
                        // value={mnumber}
                        // onChange={(e) => {
                        //   const enteredValue = e.target.value;
                        //   const numericValue = enteredValue.replace(/[^0-9+\-]/g, ''); // Remove non-numeric characters
                        //   const maxLength = 11; // Maximum allowed length
                          
                        //   if (numericValue.length <= maxLength) {
                        //     mnumberSet(numericValue); // Set the numeric value in the state
                        //     setmobileerror(false);
                        //   }
                        // }}
                      />
                    {mobilenoerror &&  
                    <FormHelperText id="outlined-weight-helper-text">
                     Invalid Mobile Number 
                     </FormHelperText>
                     } 

                      </FormControl>

                    {/* <Form.Group
                    
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    > */}
                       {/* <Form.Control
                       isInvalid={mnumbererror}
                        onChange={handleChangeMB}
                        type="number"
                        placeholder="Enter Mobile number"
                      />
                      {mnumbererror && (
                        <p style={{ color: "red" }}>
                          Please Enter 10 Digit Mobile number
                        </p>
                      )}
                    </Form.Group> */}
                    <Form.Group
                      className="mt-4"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        className="custom-textarea smstxt2"
                        as="textarea"
                        rows={10}
                        maxLength={160}
                        isInvalid={texterror}
                        onChange={handleChange}
                        placeholder="Write Your SMS Content ... below 160 characters"
                      />
                    </Form.Group>
                    {texterror && (
                      <p style={{ color: "red", marginTop: "5px" }}>
                        Please Enter Characters
                      </p>
                    )}
                  </Form>
                </div>
                <div className="mt-1 mb-5">
                  <div className="p-4 sms_small">
                    <Row>
                      <Col xs={9} className="char_box">
                        <Typography>
                          <b className="char-remain">
                            Characters Remaining: {text.length}/{maxCharacters}
                          </b>
                        </Typography>
                      </Col>
                      <Col xs={3} className="s_bs">
                        
                          <Button
                            className="send-btn"
                            onClick={() => sendsms()}
                          >
                            Send
                          </Button>
                       
                      </Col>
                    </Row>
                  </div>
                </div>
              </Box>
            </Col>
          </Row>
        </div>
      )}
    </Fragment>
  );
};
