import {
    AppBar,
    Box,
    Container,
    FormControl,
    OutlinedInput,
    Paper,
    Toolbar,
    Typography,
    Button,
    FormLabel,
    Stack,
    FormHelperText,
    Link,
    Card,
    CardContent,
    Divider,
    CircularProgress,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Table, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import CircleIcon from "@mui/icons-material/Circle";
import IconButton from "@mui/material/IconButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import { getApi } from "../../services";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
// Reducer Redux
import { PaymentState as SearchReduxState, useSelector } from "../../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../../redux/User/actions";
import SendIcon from '@mui/icons-material/Send';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment, { Moment } from "moment";

export const BlockedSessionForm: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const dispatch = useDispatch();
    const searchRedux = useSelector<SearchReduxState>(
        (state) => state.paymentReducer
    );
    const [selectedpractitioner, setSelectedpract] = useState(null);
    const [appointmenttype, setAptValue] = useState([]);
    const [selectedDay, setSelectedDay] = useState([]);

    const [fromdate, setfromdate] = React.useState(moment().format("YYYY-MM-DD"));
    const [todate, settodate] = React.useState(moment().format("YYYY-MM-DD"));

    const [submitting, setsubmitting] = React.useState(false)

    const [starttime, setstarttime] = useState(moment());
    const [endtime, setendtime] = React.useState(moment());

    const [dataReason, setdataReason] = useState<any>([]);

    const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];

    /* Error handling */
    const [apttype_error, setApttypeError] = useState(false)
    const [day_error, setdayError] = useState(false)



    const handleOnChange = (event: any, newValue: any) => {
        setAptValue(newValue);
        // You can perform additional actions with the selected value here
    };

    const navigate = useNavigate()
    const getapi = getApi();

    const [open, setOpen] = React.useState(false);
    const [msg, setMsg] = useState("");

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };




    useEffect(() => {
        UserReasonData()
    }, [])

    /* Appointment type api call */

    const UserReasonData = () => {
        // setloadingReason(true);
        
        let data = {
            location_id: searchRedux.location_id,
            user_id: searchRedux.userid
        };

       // console.log("send data", data);
        getapi
            .post("aptreasonbyuser/", data)
            .then((res) => {
                //  setloadingReason(false);
                //  console.log("new api data:->", res.data.data);
                setdataReason(res.data.data);
                dispatch(UpdatePaymentData("AptreasonList", res.data.data));
            })
            .catch((err) => {
                console.log("error", err);
            });
    };

    /* submit the form */

    const submit = () => {
        setsubmitting(true)
        if (appointmenttype === null || appointmenttype && appointmenttype.length === 0) {
            setApttypeError(true)
            setsubmitting(false)
            return
        }

        if (selectedDay === null || selectedDay && selectedDay.length === 0 || unmatchedDays && unmatchedDays.length > 0) {
            setdayError(true)
            setsubmitting(false)
            return
        }

        let data = {
            "location_id": searchRedux.location_id,
            "user_id": searchRedux.userid,
            "from_date": moment(fromdate).format("YYYY-MM-DD"),
            "to_date": moment(todate).format("YYYY-MM-DD"),
            "from_time": starttime.format("HH:mm:ss"),
            "to_time": endtime.format("HH:mm:ss"),
            "days": selectedDay.join(','),
            "reasons": appointmenttype.map((item: any) => item.ReasonId).join(',')

        }
      //  console.log('submitted data', data)

        getapi.post("sessionblockinsert/", data)
            .then((res) => {
                //console.log("update response:->", res);
                setsubmitting(false)
                if (res.data.Success) {
                    setMsg(res.data.Message);
                    setOpen(true);
                    setTimeout(() => {
                        navigate('/blockedsession')
                    }, 3000);
                }
            })
            .catch((err) => {
                console.log("error", err);
                setsubmitting(false)
            });


    }


    /* check the date and day matching function */

    const checkdays = (e: any) => {
       
        setSelectedDay(e);
        checkSelectedDaysInRange(e)
        setdayError(false)
        setunmatchedDays([]);

    }



    const [unmatchedDays, setunmatchedDays] = useState<string[]>([])


    useEffect(() => {
        checkSelectedDaysInRange(selectedDay);
    }, [selectedDay, fromdate, todate]);

    const checkSelectedDaysInRange = (selectedDays: string[]) => {
        const selectedDaysAsNumbers = selectedDay.map(day => daysOfWeek.indexOf(day));
        const daysBetweenRange: moment.Moment[] = [];
        let allSelectedDaysFound = true;
        let currentDate = moment(fromdate);
        const unmatchedDays: string[] = [];
        while (currentDate.isSameOrBefore(todate, 'day')) {
            if (selectedDaysAsNumbers.includes(currentDate.day())) {
                // daysBetweenRange.push(moment(currentDate));
                const index = selectedDaysAsNumbers.indexOf(currentDate.day());

                selectedDaysAsNumbers.splice(index, 1);

            }
            currentDate.add(1, 'day');
        }

        if (selectedDaysAsNumbers.length > 0) {
            allSelectedDaysFound = false;
        }

        selectedDaysAsNumbers.forEach(dayIndex => {
            unmatchedDays.push(daysOfWeek[dayIndex]);
        });

        setunmatchedDays(unmatchedDays)
        if (unmatchedDays.length > 0) {
            setdayError(true)
        }
    };



    /* check the date end  */

    const handleFocus = (e:any) => {
        e.preventDefault();
        e.target.focus({ preventScroll: true });
      };
    return (
        <Fragment>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                    {msg}
                </Alert>
            </Snackbar>

            {!matches ? (
                <div className="web-profile-div sessionform_page">
                    <Container>
                        <Row>
                            <Col md={8} className="mx-auto">
                            <form>
                                <Box
                                    component={Paper}
                                    elevation={2}
                                    p={2}
                                    mt={3}
                                    className="boxes bradius"
                                >







                                    <Row>
                                        <Col md={9}>
                                            <Typography className='tlte_b pt-1'>Block Session on a Specific Day</Typography>
                                        </Col>
                                        <Col className="text-right" md={3}>

                                            <IconButton
                                                aria-label="delete"
                                                // disabled 
                                                onClick={() => navigate('/blockedsession')}
                                                color="primary">
                                                <HighlightOffIcon />
                                            </IconButton>

                                        </Col>

                                    </Row>
                                    <hr />
                                    <Row>
                                        {/* <Col md={12} className="mt-4">
                                        <Typography>Select Practitioner</Typography>
                                            <Autocomplete
                                             className="form-field  mt-2"

                                             classes={{
                                                input: 'autocomplete1',
                                                option:'autocomplete1'
                                              }}
                                             style={{
                                                fontFamily:'Montserrat'
                                            }}
                                                options={Practitioner}
                                                getOptionLabel={(option) => option.label}
                                                value={selectedValue}
                                                onChange={handleOnChange}
                                                renderInput={(params) => <TextField 
                                                    className="form-field"
                                                    style={{
                                                        fontFamily:'Montserrat'
                                                    }}
                                                    {...params} label="Select Practitioner" />}
                                            />
                                        </Col> */}

                                        <Col md={12} className="mt-4">
                                            <Typography>Select Appointment Type</Typography>
                                            <Autocomplete

                                                className="form-field  mt-2"
                                                multiple
                                                filterSelectedOptions
                                                classes={{
                                                    input: 'autocomplete1',
                                                    option: 'autocomplete1'
                                                }}
                                                style={{
                                                    fontFamily: 'Montserrat'
                                                }}
                                                options={dataReason}
                                                getOptionLabel={(option: any) => option.MainReason}
                                                value={appointmenttype}
                                                onChange={(event: any, newValue: any) => {
                                                    setAptValue(newValue);
                                                    setApttypeError(false)
                                                    // You can perform additional actions with the selected value here
                                                }}
                                                renderInput={(params) => <TextField
                                                onFocus={handleFocus}
                                                    error={apttype_error}
                                                    helperText={apttype_error ? 'This field is required' : ''}
                                                    className="form-field"
                                                    style={{
                                                        fontFamily: 'Montserrat'
                                                    }}
                                                    {...params} label="Select Appointment Type" />}
                                            />
                                        </Col>






                                    </Row>

                                    <Row className="mt-4">
                                        <Col>
                                            <Typography>Start Date</Typography>
                                            <DatePicker
                                            
                                                minDate={moment()}
                                                closeOnSelect={true}
                                                format="DD/MM/YYYY"
                                                sx={{ borderRadius: "15px", fontWeight: 700 }}
                                                className="align-item-center d-flex text-center mt-2 br-20"
                                                //label="Controlled picker"
                                                value={moment(fromdate)}
                                                onChange={(newValue: any) => {
                                                    setfromdate(newValue)
                                                }}

                                            />
                                        </Col>
                                        <Col>
                                            <Typography>End Date</Typography>
                                            <DatePicker
                                                minDate={moment(fromdate)}
                                                closeOnSelect={true}
                                                format="DD/MM/YYYY"
                                                sx={{ borderRadius: "15px", fontWeight: 700 }}
                                                className="align-item-center d-flex text-center mt-2 br-20"
                                                //label="Controlled picker"
                                                value={moment(todate)}
                                                onChange={(newValue: any) => {
                                                    settodate(newValue)
                                                }}

                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="mt-4">
                                            <Typography>Select Day</Typography>
                                            <Autocomplete
                                                filterSelectedOptions
                                                className="form-field mt-2"
                                                multiple
                                                classes={{
                                                    input: 'autocomplete1',
                                                    option: 'autocomplete1'
                                                }}
                                                style={{
                                                    fontFamily: 'Montserrat'
                                                }}
                                                options={daysOfWeek}
                                                // getOptionLabel={(option) => option.label}
                                                value={selectedDay}
                                                // onChange={(event: any, newValue: any) => {
                                                //     setSelectedDay(newValue);
                                                //    // checkSelectedDaysInRange()
                                                //     setdayError(false)

                                                //   }}
                                                onChange={(event: any, newValue: any) => checkdays(newValue)}
                                                renderInput={(params) => <TextField
                                                    onFocus={handleFocus}
                                                    error={day_error || unmatchedDays.length > 0}
                                                    helperText={day_error || unmatchedDays.length > 0 ?
                                                        <>
                                                            {!unmatchedDays ?
                                                                'This field is required'
                                                                :
                                                                <>
                                                                    {/* {unmatchedDays && unmatchedDays.map((days, idx) =>
                                                        <span>{days}</span>
                                                    )} */}
                                                                    {unmatchedDays.join(', ')} are not available within the date range.
                                                                </>
                                                            }
                                                        </>

                                                        : ''}
                                                    className="form-field"
                                                    style={{
                                                        fontFamily: 'Montserrat'
                                                    }}
                                                    {...params} label="Select Day" />}
                                            />



                                        </Col>
                                    </Row>

                                    <Row className="mt-4">
                                        <Col>
                                            <Typography>Start Time</Typography>
                                            <TimePicker
                                         
                                                //  maxTime={moment(starttime)}
                                                className="mt-2 w-100"
                                                format="HH:mm A" // Use "HH" for 24-hour format
                                                views={['hours', 'minutes']}
                                                ampm={true} // Disable AM/PM selection
                                                value={moment(starttime)}
                                                onChange={(newValue: any) => setstarttime(newValue)}
                                            />




                                        </Col>

                                        <Col>
                                            <Typography>End Time</Typography>
                                            <TimePicker
                                                minTime={moment(starttime)}
                                                className="mt-2 w-100"
                                                value={moment(endtime)}
                                                format="HH:mm A" // Use "HH" for 24-hour format
                                                views={['hours', 'minutes']}
                                                ampm={true} // Disable AM/PM selection
                                                onChange={(newValue: any) => setendtime(newValue)}
                                            />

                                        </Col>

                                    </Row>
                                    <Button
                                        onClick={submit}
                                        fullWidth
                                        className="p-2 mt-3"
                                        variant='contained'
                                    >
                                        {submitting ? <CircularProgress style={{ color: "#FFF" }} size={24} /> : 'Submit'}

                                    </Button>

                                </Box>
                                </form>


                            </Col>
                        </Row>
                    </Container>
                </div>
            ) : (
                <div className="profile-mobile-view m-3 coddgp_mobile_view">

                    <Row>
                        <Col md={8} className="mx-auto">
                        <form>
                            <Box
                                component={Paper}
                                elevation={2}
                                p={2}
                                mt={3}
                                className="boxes bradius"
                            >
                                <Row>
                                    <Col md={9} xs={9}>
                                        <Typography className='tlte_b pt-1'>Block Session on a Specific Day</Typography>
                                    </Col>
                                    <Col className="text-right" md={3} xs={3}>

                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => navigate('/blockedsession')}
                                            color="primary">
                                            <HighlightOffIcon className="back_button" />
                                        </IconButton>

                                    </Col>

                                </Row>
                                <hr />
                                <Row>
                                    {/* <Col md={12} className="mt-4">
                                        <Typography>Select Practitioner</Typography>
                                            <Autocomplete
                                             className="form-field  mt-2"

                                             classes={{
                                                input: 'autocomplete1',
                                                option:'autocomplete1'
                                              }}
                                             style={{
                                                fontFamily:'Montserrat'
                                            }}
                                                options={Practitioner}
                                                getOptionLabel={(option) => option.label}
                                                value={selectedValue}
                                                onChange={handleOnChange}
                                                renderInput={(params) => <TextField 
                                                    className="form-field"
                                                    style={{
                                                        fontFamily:'Montserrat'
                                                    }}
                                                    {...params} label="Select Practitioner" />}
                                            />
                                        </Col> */}

                                    <Col md={12} className="mt-4">
                                        <Typography>Select Appointment Type</Typography>
                                        <Autocomplete
                                           
                                            filterSelectedOptions
                                            className="form-field  mt-2"
                                            multiple
                                            classes={{
                                                input: 'autocomplete1',
                                                option: 'autocomplete1'
                                            }}
                                            style={{
                                                fontFamily: 'Montserrat'
                                            }}
                                            options={dataReason}
                                            getOptionLabel={(option: any) => option.MainReason}
                                            value={appointmenttype}
                                            onChange={(event: any, newValue: any) => {
                                                setAptValue(newValue);
                                                setApttypeError(false)
                                                // You can perform additional actions with the selected value here
                                            }}
                                            renderInput={(params) => <TextField
                                                onFocus={handleFocus}
                                                error={apttype_error}
                                                helperText={apttype_error ? 'This field is required' : ''}
                                                className="form-field app_typedrop"
                                               
                                                style={{
                                                    fontFamily: 'Montserrat'
                                                }}
                                                {...params} label="Select Appointment Type" />}
                                        />
                                    </Col>



                                    {/* <Col md={12} className="mt-4">
                                        <Typography>Select Day</Typography>
                                            <Autocomplete
                                             className="form-field mt-2"
                                             multiple
                                             filterSelectedOptions
                                             classes={{
                                                input: 'autocomplete1',
                                                option:'autocomplete1'
                                              }}
                                             style={{
                                                fontFamily:'Montserrat'
                                            }}
                                                options={daysOfWeek}
                                               // getOptionLabel={(option) => option.label}
                                                value={selectedDay}
                                                onChange={(event: any, newValue: any) => {
                                                    setSelectedDay(newValue);
                                                    setdayError(false)
                                                    // You can perform additional actions with the selected value here
                                                  }}
                                                renderInput={(params) => <TextField 
                                                    error={day_error}
                                                    helperText={day_error ? 'This field is required':''}
                                                    className="form-field"
                                                    style={{
                                                        fontFamily:'Montserrat'
                                                    }}
                                                    {...params} label="Select Day" />}
                                            />
                                        </Col> */}

                                </Row>

                                <Row className="mt-4">
                                    <Col>
                                        <Typography>Start Date</Typography>
                                        <DatePicker
                                            minDate={moment()}
                                            closeOnSelect={true}
                                            format="DD/MM/YYYY"
                                            sx={{ borderRadius: "15px", fontWeight: 700 }}
                                            className="align-item-center d-flex text-center mt-2 br-20"
                                            //label="Controlled picker"
                                            value={moment(fromdate)}
                                            onChange={(newValue: any) => {
                                                setfromdate(newValue)
                                            }}

                                        />
                                    </Col>
                                    <Col>
                                        <Typography>End Date</Typography>
                                        <DatePicker
                                            minDate={moment(fromdate)}
                                            closeOnSelect={true}
                                            format="DD/MM/YYYY"
                                            sx={{ borderRadius: "15px", fontWeight: 700 }}
                                            className="align-item-center d-flex text-center mt-2 br-20"
                                            //label="Controlled picker"
                                            value={moment(todate)}
                                            onChange={(newValue: any) => {
                                                settodate(newValue)
                                            }}

                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12} className="mt-4">
                                        <Typography>Select Day</Typography>
                                        <Autocomplete
                                            filterSelectedOptions
                                            className="form-field mt-2"
                                            multiple
                                            classes={{
                                                input: 'autocomplete1',
                                                option: 'autocomplete1'
                                            }}
                                            style={{
                                                fontFamily: 'Montserrat'
                                            }}
                                            options={daysOfWeek}
                                            // getOptionLabel={(option) => option.label}
                                            value={selectedDay}
                                            // onChange={(event: any, newValue: any) => {
                                            //     setSelectedDay(newValue);
                                            //    // checkSelectedDaysInRange()
                                            //     setdayError(false)

                                            //   }}
                                            onChange={(event: any, newValue: any) => checkdays(newValue)}
                                            renderInput={(params) => <TextField
                                                onFocus={handleFocus}
                                                error={day_error || unmatchedDays.length > 0}
                                                helperText={day_error || unmatchedDays.length > 0 ?
                                                    <>
                                                        {!unmatchedDays ?
                                                            'This field is required'
                                                            :
                                                            <>
                                                                {/* {unmatchedDays && unmatchedDays.map((days, idx) =>
                                                        <span>{days}</span>
                                                    )} */}
                                                                {unmatchedDays.join(', ')} are not available within the date range.
                                                            </>
                                                        }
                                                    </>

                                                    : ''}
                                                className="form-field s_day"
                                                style={{
                                                    fontFamily: 'Montserrat'
                                                }}
                                                {...params} label="Select Day" />}
                                        />



                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col>
                                        <Typography>Start Time</Typography>
                                        <TimePicker
                                            //maxTime={moment(endtime)}
                                            className="mt-2 w-100"
                                            format="HH:mm A" // Use "HH" for 24-hour format
                                            views={['hours', 'minutes']}
                                            ampm={true} // Disable AM/PM selection
                                            value={moment(starttime)}
                                            onChange={(newValue: any) => setstarttime(newValue)}
                                        />




                                    </Col>

                                    <Col>
                                        <Typography>End Time</Typography>
                                        <TimePicker
                                            minTime={moment(starttime)}
                                            className="mt-2 w-100"
                                            value={moment(endtime)}
                                            format="HH:mm A" // Use "HH" for 24-hour format
                                            views={['hours', 'minutes']}
                                            ampm={true} // Disable AM/PM selection
                                            onChange={(newValue: any) => setendtime(newValue)}
                                        />

                                    </Col>

                                </Row>
                                <Button
                                    onClick={submit}
                                    fullWidth
                                    className="p-2 mt-3"
                                    variant='contained'
                                >
                                    {submitting ? <CircularProgress style={{ color: "#FFF" }} size={24} /> : 'Submit'}
                                </Button>
                            </Box>
                            </form>


                        </Col>
                    </Row>

                </div>
            )}
        </Fragment>
    );
};
