import { TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux';
import { combineReducers } from 'redux'
import paymentReducer from './User/reducer'

const rootReducers = combineReducers({
    paymentReducer,
})

export type PaymentState = ReturnType<typeof paymentReducer>
export type RootState = ReturnType<typeof rootReducers>

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

export default rootReducers