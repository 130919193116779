import {
  AppBar,
  Box,
  Container,
  FormControl,
  OutlinedInput,
  Paper,
  Toolbar,
  Typography,
  Button,
  TextareaAutosize,
} from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Table, Form, Image } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { PaymentState as SearchReduxState, useSelector } from "../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../redux/User/actions";
import { getApi } from "../services";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import { number } from "yup";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
export const Sms: React.FC = () => {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = useState("");
  const [mnumber, mnumberSet] = useState("");

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const maxCharacters = 160;
  const [texterror, settexterror] = useState(false);

  const goBackClincal = () => {
    navigate(-1); // This will navigate back one step in the history stack
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event: any) => {
    const inputValue = event.target.value;
    settexterror(false)
    if (inputValue.length <= maxCharacters) {
      setText(inputValue);
    }
  };

  const getapi = getApi();
  const sendsms = () => {
    if (text.trim() === "") {
      settexterror(text.trim() === "");

      return;
    }

    settexterror(false);

    let data = {
      ref_id: "201822315061111",
      loctn_id: searchRedux.location_id,
      usr_id: searchRedux.userid,
      mobile_no: searchRedux.user_phone_no
        ? searchRedux.user_phone_no
        : mnumber,
      text: text,
      crt_by: "b.gibson",
    };
    //console.log(data);

    getapi
      .post("halosentsms/", data)
      .then((res) => {
        //console.log("logout response:->", res);

        if (res.data.Success) {
          setMsg(res.data.Message);
          setOpen(true);
        }
      })
      .catch((err) => {
        //console.log("error", err);
        settexterror(true);
      });
  };

  useEffect(() => {
    // This is the cleanup function
    return () => {
      // dispatch(UpdatePaymentData("user_phone_no", ""));
      // //console.log("Cleanup ran");
    };
  }, []);


  /* sms content */
  const TelehealthText = () =>{
    let encoded = base64_encode(searchRedux.location_id+'_'+searchRedux.userid+'_'+searchRedux.selecteddate+'_'+searchRedux.PntId+'_'+searchRedux.user_name);
     let url = "Telehealth Consent Form  "+"https://app.coddgp.com.au/consent?data="+encoded
   
   
     setText(''+ url)
     //console.log('url',base64_decode(encoded) )
  }
  return (
    <Fragment>
      {!matches ? (
        <div className="sms-web-view">
          <Row>
            <Col md={11} className="mx-auto">
              <Box
                component={Paper}
                elevation={2}
                p={0}
                mt={3}
                className="boxes"
              >
                <div>
                  <Row>
                    <Col className="text-right mr-5 align-item-right">
                      {/* <Button
                       variant='contained'
                       color="primary"
                       onClick={()=> TelehealthText()}
                       style={{
                        borderRadius:8,
                        marginRight:50,
                        marginTop:20,
                        textTransform:'capitalize'
                       }}
                       >
                        Telehealth consent
                       </Button> */}
                    </Col>
                  </Row>
                  <Form className="p-5">
                    <Row>
                      <Col md={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            value={searchRedux.user_phone_no}
                            type="text"
                            placeholder="Enter Mobile Number"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}></Col>
                      <Col md={2}>
                        <div className="col-md-12 mt-0">
                          <Button
                            onClick={goBackClincal}
                            sx={{
                              p: 1.5,
                              mt: 0,
                              width: "99%",
                              fontWeight: "800",
                            }}
                            fullWidth
                            variant="contained"
                          >
                            &nbsp;<b>Close</b>&nbsp;
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <Form.Group
                      className="mt-4"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        className="custom-textarea"
                        as="textarea"
                        rows={10}
                        maxLength={160}
                        isInvalid={texterror}
                        value={text}
                        onChange={handleChange}
                        placeholder="Write Your SMS Content ... below 160 characters"                    
                      />
                    </Form.Group>
                    {texterror && (
                      <p style={{ color: "red", marginTop: "5px" }}>
                        Please Enter Characters
                      </p>
                    )}
                  </Form>
                </div>
                <div className="p-3">
                  <div className="sms-p-5">
                    <Row>
                      <Col md={10}>
                        <Typography>
                          <b>
                            Characters Remaining: {text.length}/{maxCharacters}
                          </b>
                        </Typography>
                      </Col>
                      <Col md={2}>
                        <Typography style={{ textAlign: "end" }}>
                          <Button
                            onClick={sendsms}
                            sx={{ p: 1.5, mt: 0 }}
                            fullWidth
                            variant="contained"
                          >
                            &nbsp;Send&nbsp;
                          </Button>
                        </Typography>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Box>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="sms-mob-view m-3 coddgp_mobile_view">
          <Row>
            <Col md={11} className="mx-auto">
              <Box
                component={Paper}
                elevation={2}
                p={0}
                mt={3}
                className="boxes"
              >
                <div className="">
                <Row>
                    <Col className="text-right mr-5 align-item-right">
                      {/* <Button
                       variant='contained'
                       onClick={()=> TelehealthText()}
                       color='primary'
                       style={{
                        borderRadius:8,
                        marginRight:15,
                        marginTop:20,
                        textTransform:'capitalize'
                       }}
                       >
                        Telehealth consent
                       </Button>
                       */}
                    </Col> 
                  </Row>

                  <Form className="p-3">
                    <Typography style={{ textAlign: "end" }}>
                      <Button className="send-btn sms_clos mb-3" onClick={goBackClincal}>
                        Close
                      </Button>
                    </Typography>
                    {/* <div
                      className="col-md-3 mb-3"
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        style={{
                          textTransform: "none",
                          background: "#4A6AB0",
                          borderRadius: "6px",
                          color: "white",
                          fontWeight: "800",
                        }}
                        onClick={goBackClincal}
                      >
                        Close
                      </Button>
                    </div> */}
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        value={searchRedux.user_phone_no}
                        type="number"
                        placeholder="Enter Mobile number"
                      />
                    </Form.Group>

                    <Form.Group
                      
                      className="mt-4"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                       
                        className="custom-textarea"
                        as="textarea"
                        rows={10}
                        maxLength={160}
                        value={text}
                        isInvalid={texterror}
                        onChange={handleChange}
                        placeholder="Write Your SMS Content ... below 160 characters"
                      />
                    </Form.Group>
                    {texterror && (
                      <p style={{ color: "red", marginTop: "5px" }}>
                        Please Enter Characters
                      </p>
                    )}
                  </Form>
                </div>
                <div className="mt-1 mb-5">
                  <div className="p-4 sms_small">
                    <Row>
                      <Col xs={9} className="char_box">
                        <Typography>
                          <b className="char-remain">
                            Characters Remaining: {text.length}/{maxCharacters}
                          </b>
                        </Typography>
                      </Col>
                      <Col xs={3}  className="s_bs">
                        <Typography style={{ textAlign: "end" }}>
                          <Button
                            className="send-btn"
                            onClick={() => sendsms()}
                          >
                            Send
                          </Button>
                        </Typography>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Box>
            </Col>
          </Row>
        </div>
      )}
              <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {msg}
          </Alert>
        </Snackbar>
    </Fragment>
  );
};
