import {
    AppBar,
    Box,
    Container,
    FormControl,
    OutlinedInput,
    Paper,
    Toolbar,
    Typography,
    Button,
    FormLabel,
    Stack,
    FormHelperText,
    Link,
    Card,
    CardContent,
    CircularProgress,
    Tooltip,
    InputAdornment,
  } from "@mui/material";
  import React, { Fragment, useEffect, useState } from "react";
  import { Col, Row, Table,Image } from "react-bootstrap";
  import { useNavigate } from "react-router-dom";
  import Form from "react-bootstrap/Form";
  import InputGroup from "react-bootstrap/InputGroup";
  import CircleIcon from "@mui/icons-material/Circle";
  import IconButton from "@mui/material/IconButton";
  import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
  import LockOpenIcon from "@mui/icons-material/LockOpen";
  import useMediaQuery from "@mui/material/useMediaQuery";
  import { styled, useTheme } from "@mui/material/styles";
  import "bootstrap/dist/css/bootstrap.min.css";
  import { getApi } from "../../services";
  import Snackbar from "@mui/material/Snackbar";
  import moment from "moment";
  import MuiAlert, { AlertProps } from "@mui/material/Alert";
  // Reducer Redux
  import { PaymentState as SearchReduxState, useSelector } from "../../redux";
  import { useDispatch } from "react-redux";
  import { UpdatePaymentData } from "../../redux/User/actions";
  import SendIcon from '@mui/icons-material/Send';
  import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
  import DeleteIcon from '@mui/icons-material/Delete';
  import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
  import Modal from '@mui/material/Modal';
  import TextField from '@mui/material/TextField';
  import Autocomplete from '@mui/material/Autocomplete';
  import UndoIcon from '@mui/icons-material/Undo';
import { Moment } from 'moment';

  export const BlockedPatient: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const dispatch = useDispatch();
    const searchRedux = useSelector<SearchReduxState>(
      (state) => state.paymentReducer
    );
    const [username, setusename] = useState('');
    const [mobileno, setmobileno] = useState('');
    const [usernameerror, setusenameerror] = useState(false);
    const [mobilenoerror, setmobileerror] = useState(false);
    const [submitting,setsubmitting] = React.useState(false)


    const [data,setData] = useState([])
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const getapi = getApi();
  
    const [sopen, setsOpen] = React.useState(false);
    const [smsg, setsMsg] = useState("");

    const [open, setOpen] = React.useState(false);
    const [msg, setMsg] = useState("");
    const [Mopen, setMOpen] = React.useState(false);
    const [addopen, setAddopen] = React.useState(false);
    const handleMOpen = () => setMOpen(true);
    const handleMClose = () => setMOpen(false);

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
      props,
      ref
    ) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
  
    const handleClose = (
      event?: React.SyntheticEvent | Event,
      reason?: string
    ) => {
      if (reason === "clickaway") {
        return;
      }
  
      setOpen(false);
    };
  

  const Reset = ( ) =>{
    setusename('')
    setmobileno('')
    setusenameerror(false)
    setmobileerror(false)
  }

   {/* form submition */}


   const formatPhoneNumber = (phoneNumber: any): any => {
    if (phoneNumber.startsWith('0')) {
      // Phone number already starts with zero, return as is
      setmobileno(phoneNumber) ;
    } else {
      // Add zero prefix to the phone number
      setmobileno('0' + phoneNumber);
    }
  };


   const submit = () =>{
    setsubmitting(true)
    if (username !== undefined && username !== null && username.trim() === '') {
        setusenameerror(true)
        setsubmitting(false)
        return
      }
      const trimmedNumber = mobileno.replace(/\s/g, '');
    if (trimmedNumber !== undefined && trimmedNumber !== null && /^\d+$/.test(trimmedNumber) ) {
        if (trimmedNumber.trim() === '') {
          setmobileerror(true)
          setsubmitting(false)
          return
        }
      } 
      if (!/^\d{10}$/.test(trimmedNumber)) {
        setmobileerror(true);
        setsubmitting(false)
        return;
      }
  let data ={
        "location_id": searchRedux.location_id,
        "user_id": searchRedux.userid,
        "mobile": mobileno,
        "name": username,
        "status": "1",
        "created_by": searchRedux.username          
    }
//console.log('data',data)

getapi.post("instpatientblock/", data)
.then((res) => {
 // console.log("update response:->", res);
  setLoading(false);
  setsubmitting(false)
  if (res.data.Success) {
    setsMsg(res.data.Message);
    Reset()
    setsOpen(true);
    setAddopen(false)
    getData()

  }
})
.catch((err) => {
  console.log("error", err);
  setLoading(false);
  setsMsg('somthing went wrong');
  setsOpen(true);
  setsubmitting(false)

});

   }

   

 
 
   const getData =() =>{
    setLoading(true);
    let data ={
        "location_id": searchRedux.location_id,
        "user_id": searchRedux.userid,      
    }
  //  console.log('submitted data',data )

  getapi.post("getpatientblock/", data)
    .then((res) => {
    //  console.log("get response:->", res);
      setLoading(false);
      if (res.data.Success) {
        setData(res.data.data)
      }
    })
    .catch((err) => {
      console.log("error", err);
      setLoading(false);
      setData([])
    });
}

useEffect (()=>{
  getData()
},[])
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24, 
  p: 3,
   
  };

const [dataReason, setdataReason] = useState<any>([]);
const [ReasonName, setReasonName] = useState<any>([]);
   

/* delete patient */
const [did,setdeleteid] = useState()
const deletepop = (id:any) =>{
  setdeleteid(id)
  handleMOpen()
  
}
 const delete_session = () =>{
  handleMClose()
    let data ={
      "pid":did 
  }
  // console.log('submitted data',data )
  
  getapi.post("delpatientblock/", data)
  .then((res) => {
   // console.log("delete response:->", res);
    
    if (res.data.Success) {
      setMsg(res.data.Message);
      setOpen(true);
      getData()
    }
  })
  .catch((err) => {
    console.log("error", err);
  });
  }






    return (
      <>
      {loading ?
  <Box  sx={{ display: 'flex',justifyContent:'center',alignItems:'center',height:'75vh' }}>
  <CircularProgress />
</Box>
:
      <Fragment>
        <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
            {msg}
          </Alert>
        </Snackbar>
        <Snackbar 
        style={{
          marginBottom:10
        }}
        //anchorOrigin={{ vertical: 'top', horizontal: 'center'}} 
        open={sopen} autoHideDuration={6000} onClose={()=> setsOpen(false)}>
          <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
            {smsg}
          </Alert>
        </Snackbar>

  
        <Modal
        className="confirm_msg"
        open={Mopen}
        onClose={handleMClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
       
        <Box id='confirm_msg' sx={style}>
        <div className="delet_btn text-end d-flex justify-content-end">
          <CancelOutlinedIcon onClick={() => handleMClose() }/>
        </div>
        <div className='align-middle mt-2 no_app justify-content-center text-center'>
        <p className='pt-1 pb-1 no-text'>
           Are you sure you want to delete?
          </p>
          <Row>
              <Col>

  
       <Button
        variant='contained'
        sx={{
          
          textTransform: 'capitalize',
          fontWeight: 600,
          fontSize: 16
        }}
        fullWidth
        color='primary'
        className='bbtn'
        onClick={() => delete_session()}
       >
        Yes
       </Button>
       </Col>
       <Col>
       <Button
        variant='contained'
        fullWidth
        sx={{

          textTransform: 'capitalize',
          fontWeight: 600,
          fontSize: 16
        }}
        color='secondary'
        className='cbtn'
        onClick={handleMClose}
       >
        No
       </Button>
       </Col>
       </Row>
       </div>
        </Box>
      </Modal>

{/* add patient popup*/}

<Modal
        open={addopen}
        onClose={()=>{ 
          setAddopen(false);
          Reset()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id='addpop' sx={style}>
            <div>
        <FormControl fullWidth error={usernameerror}>
                          <FormLabel className="form-label mt-3">Patient Name</FormLabel>

                          <OutlinedInput
                            error={usernameerror}
                            value={username}
                            onChange={(e) =>{ 
                              setusename(e.target.value);
                              setusenameerror(false)
                            }}
                            className="mt-1 sinput-border"
                            endAdornment={
                              <IconButton>
                                <PersonOutlineIcon />
                              </IconButton>
                            }
                          />
                        {usernameerror &&  <FormHelperText>This field is required</FormHelperText> }
                        </FormControl>
       </div>

       <div>
       <FormControl error={mobilenoerror} fullWidth className="mt-4" sx={{  width: '100%' }} variant="outlined">
                        <FormLabel className="form-label">Mobile</FormLabel>
                              <OutlinedInput
                              error={mobilenoerror}
                              fullWidth
                              className="mt-1 sinput-border"
                                id="outlined-adornment-phone"
                                startAdornment={<InputAdornment position="start"><b>+61</b></InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  'aria-label': 'weight',
                                  'maxLength': 10,
                                }}
                                type="number"
                                value={mobileno}
                                onChange={(e) =>
                               {   
                              //  setmobileno(e.target.value.slice(0, 10));
                              formatPhoneNumber(e.target.value.slice(0, 10))
                                setmobileerror(false)
                               
                                }}
                              />
                            {mobilenoerror &&  
                            <FormHelperText id="outlined-weight-helper-text">
                             Invalid Mobile Number 
                             </FormHelperText>
                             } 

                              </FormControl>
       </div>
       
       <div className="pt-3 pb-3 text-right align-right">
      
                      <Button 
                      sx={{mr:2}}
                      variant='contained'
                      color='secondary'
                      onClick={()=> setAddopen(false)}
                      >
                     Close
                      </Button>
                    <Button
                      onClick={() => submit()}
                      // color="secondary"
                      className="save-button5"
                      variant="contained"
                    
                    >
                    {submitting ? 
                    <CircularProgress style={{
                        color:"#fff",
                        width:20,
                        height:20
                }} />
                     :
                     'Submit'
                     }  
                    </Button>

                  </div>
      
        </Box>
      </Modal>





        {!matches ? (
          <div className="web-profile-div settings_page">
            <Container>
              <Row>
                <Col md={10} className="mx-auto">
                <div className="d-flex justify-content-end">
            <Button
                      className="text-white"
                      aria-label="open drawer"
                      onClick={() => navigate(-1)}
                     sx={{ height: "36px",minWidth:32 }}
                     color="secondary"
                     variant="contained"
                    >
                      <UndoIcon />
                    </Button>
                    </div>

                  <Box
                    component={Paper}
                    elevation={2}
                    p={2}
                    mt={3}
                    className="boxes bradius"
                  >
                    <Row>
                      <Col md={9}>
                      <Typography className='tlte_b'>Blocked Patient</Typography>
                      </Col>
                     <Col className="text-right" md={3}>
                      <Button 
                        onClick={()=>setAddopen(true)}
                      variant='contained'>Add Patient</Button>
                     </Col>
                   
                    </Row>
                    
                    
               
                  </Box>



               

                 




                  {data.length ? 
                  <>
  {data?.map((item:any,idx:any)=>
 
 <Card className="mt-4 bbo" sx={{ minWidth: 275 }}>
 <CardContent className="block_content">
   <Row>
     <Col md={11}>
    
      {/* { getReason(item.Reasons) } */}
      <Typography className="usernam">{item.Name}</Typography>
   <Typography className="mt-3 p_mob mb-3">{item.Mobile}</Typography> 
   <div className="d-flex p_dat flex-direction-row">
   <Typography style={{
       marginRight:20
     }}>
        { moment(item.CreatedDate).format('ddd DD MMM YYYY')}
     </Typography>
     </div>
     </Col>
     <Col md={1} className="align-middle">
     <Tooltip title="Delete">
     <Button 
    //  onClick={()=> delete_session(item.PId)}
     onClick={()=> deletepop(item.PId)}
     className="mt-5" 
     size="small" 
     sx={{minWidth:32,background:'#D7EAFF',color:'#4666AE'}}>
   <DeleteIcon />
 </Button>
 </Tooltip>
     </Col>
   </Row>                  
</CardContent>
</Card>
 )}
 </>
 :
 <Fragment>
  <div className="m-4-web">
   <Box
     className="date_box mt-4 p-5 justify-content-center text-center align-item-center mt-0"
     component={Paper}
     elevation={2}
     p={2}
     sx={{
       borderRadius: "10px",
     }}
   >
     <Image
       style={{
         margin: 10,
       }}
       src={require("../../assets/images/Datanotfound.png")}
       width="216"
       height="194"
     />
     <Typography className="not_fond">
       Data Not Found
     </Typography>
   </Box>
 </div> 
</Fragment>
  }
              
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          <div className="profile-mobile-view m-2 coddgp_mobile_view">
          
              <Row>
                <Col md={10} className="mx-auto">
                <div className="d-flex mt-1 justify-content-end">
            <Button
                      className="text-white"
                      aria-label="open drawer"
                      onClick={() => navigate(-1)}
                     sx={{ height: "36px",minWidth:32 }}
                     color="secondary"
                     variant="contained"
                    >
                      <UndoIcon className="back_button" />
                    </Button>
                    </div>

                  <Box
                    component={Paper}
                    elevation={2}
                    p={2}
                    mt={2}
                    className="boxes bradius"
                  >
                    <Row>
                      <Col className="bp" md={9} xs={7}>
                      <Typography className='tlte_b'>Blocked Patient</Typography>
                      </Col>
                     <Col className="text-right ap" md={3} xs={5}>
                     <Button 
                        onClick={()=>setAddopen(true)}
                      variant='contained'>Add Patient</Button>
                     </Col>
                   
                    </Row>
                    
                    
               
                  </Box>
                  {data.length ?
                  <>
                  {data?.map((item:any,idx:any)=>
                <Card className="mt-2 bbo" sx={{ minWidth: 275 }}>
                <CardContent className="block_content">
                  <Row>
                  <Col md={11} xs={10}>
                   
                     {/* { getReason(item.Reasons) } */}
                     <Typography className="usernam">{item.Name}</Typography>
                  <Typography className="mt-3 p_mob mb-3">{item.Mobile}</Typography> 
                  <div className="d-flex p_dat flex-direction-row">
                  <Typography style={{
                      marginRight:20
                    }}>
                       { moment(item.CreatedDate).format('ddd DD MMM YYYY')}
                    </Typography>
                    </div>
                    </Col>
                    <Col md={1} xs={2} className="align-middle">
                    <Tooltip title="Delete">
                    <Button 
                   // onClick={()=> delete_session(item.PId)}
                   onClick={()=> deletepop(item.PId)}
                    className="mt-5" 
                    size="small" 
                    sx={{minWidth:32,background:'#D7EAFF',color:'#4666AE'}}>
                  <DeleteIcon />
                </Button>
                </Tooltip>
                    </Col>
                  </Row>                  
               </CardContent>
               </Card>

                  )}
                </>
                :
                <Fragment>
                  <Row>
                    <Col>
                    <div className="m-4-web">
                  <Box
                    className="date_box mt-4 p-5 justify-content-center text-center align-item-center mt-0"
                    component={Paper}
                    elevation={2}
                    p={2}
                    sx={{
                      borderRadius: "10px",
                    }}
                  >
                    <Image
                      style={{
                        margin: 10,
                      }}
                      src={require("../../assets/images/Datanotfound.png")}
                      width="216"
                      height="194"
                    />
                    <Typography className="not_fond">
                      Data Not Found
                    </Typography>
                  </Box>
                </div>
                  </Col>
                  </Row>
               
               </Fragment>
  }

                </Col>
              </Row>
           
          </div>
        )}
      </Fragment>
  }
      </>
    );
  };
  