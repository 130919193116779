import {
  Box,
  CircularProgress,
  Container,
  Paper,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Row, Table,Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getApi } from "../services";
import moment from "moment";
import { PaymentState as SearchReduxState, useSelector } from "../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../redux/User/actions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";


export const Kpi: React.FC = () => {


  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );

  const dispatch = useDispatch();
  const [data, setdata] = useState([]);
  const navigate = useNavigate();
  const getapi = getApi();
  // const [fromdate, setfromdate] = useState("");

  /* new calander */
  const [fromdate, setfromdate] = React.useState(moment().format("YYYY-MM-DD"));

  const [todate, settodate] = useState("");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [openCalendarS, setOpenCalendarS] = useState(false);
  const [openCalendarE, setOpenCalendarE] = useState(false);
  const [loading, setLoading] = useState(true);
  const [nowDate, setnowDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  // useEffect(() => {
  //   getData();
  // }, []);

  const getData = () => {
    setLoading(true);
    let data = {
      userlocid: searchRedux.location_id,
      codd_fdate: moment(searchRedux.FromDate.toString()).format("YYYY-MM-DD")
        ? moment(searchRedux.FromDate.toString()).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),

      codd_tdate: moment(searchRedux.ToDate.toString()).format("YYYY-MM-DD")
        ? moment(searchRedux.ToDate.toString()).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      codd_duserid: searchRedux.userid,
    };
    //console.log("submitad data", data);
    getapi
      .post("halokpinew/", data)
      .then((response) => {
        setLoading(false)
      //  //console.log("response data");
      //  //console.log("Kpi datas", JSON.stringify(response.data.data));
        setdata(response.data.data);
      })
      .catch((error) => {
        setLoading(false)
        //console.log(error);
      });
  };

  const handleInputClickS = () => {
   // setOpenCalendarS((prevOpen) => !prevOpen);
    setOpenCalendarS(true);
    //console.log('function called')
  };
  const handleCalendarCloseS = () => {
    setOpenCalendarS(false);
  };

  const handleInputClickE = () => {
    setOpenCalendarE(true);
  };
  const handleCalendarCloseE = () => {
    setOpenCalendarE(false);
  };

  useEffect(() => {
    //console.log("searchRedux todate",searchRedux.ToDate );
  }, [searchRedux.ToDate]);


  // useEffect(() => {
  //   getData();   


  //   //console.log("API CALLED");      
   
  
  // }, [searchRedux.FromDate, searchRedux.ToDate]);


  useEffect(() => {
    getData();  

    //console.log("API CALLED");  
  }, [searchRedux.FromDate, searchRedux.ToDate]);





  const [selectedDate, setSelectedDate] = useState(moment());
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleDateChange = (date:any) => {
    setSelectedDate(date);
    setOpen(false); // Close the DatePicker after selecting a date
  };

  const toggleDatePicker = () => {
    setOpen(!open); // Toggle the DatePicker open/close state
  };

  return (
    <Fragment>
      {!matches ? (
        <div>
          <Container>
            <Row>
              <Col md={10} className="mx-auto">
                <Box
                  className="date_box"
                  component={Paper}
                  elevation={2}
                  p={2}
                  sx={{
                    backgroundColor: "primary.main",
                  }}
                >


                  <Row>
                    <Col md={4} className="mx-auto justify-content-center">
                      <div className="text-center align-item-center mb-2">
                        From Date
                      </div>

       


                      <div className="text-center align-item-center"
                      //  onClick={toggleDatePicker}
                       >
                    
                        <DatePicker
                       
                          maxDate={moment(nowDate)
                          }
                          closeOnSelect={true}
                          format="DD/MM/YYYY"
                          // open={open}
                          // onClose={() => setOpen(false)}
                          sx={{ borderRadius: "15px", fontWeight: 700 }}
                          className="align-item-center sidebar_datebicker d-flex text-center br-20"
                          //label="Controlled picker"
                          value={
                            moment(searchRedux.FromDate.toString())
                              ? moment(searchRedux.FromDate.toString())
                              : moment(fromdate)
                          }
                          onChange={(newValue: any) => {
                            dispatch(UpdatePaymentData("FromDate", newValue));
                            handleCalendarCloseS()
                      
                          }}
                          
                        />

                      </div>
                    </Col>
                    <Col md={4} className="mx-auto justify-content-center">
                      <div className="text-center align-item-center mb-2">
                        To Date
                      </div>
                      <div className="text-center align-item-center" 
                    //  onClick={()=> setOpen2(!open2)}
                      >
                        <DatePicker
                          minDate={
                            moment(searchRedux.FromDate.toString())
                              ? moment(searchRedux.FromDate.toString())
                              : moment().format("YYYY-MM-DD")
                          }
                          maxDate={moment(nowDate)
                          }
                          closeOnSelect={true}
                          format="DD/MM/YYYY"
                          // open={open2}
                          // onClose={() => setOpen2(false)}
                          sx={{ borderRadius: "15px" }}
                          className="align-item-center sidebar_datebicker d-flex text-center br-20"
                          //label="Controlled picker"
                          value={
                            moment(searchRedux.ToDate.toString())
                              ? moment(searchRedux.ToDate.toString())
                              : moment().format("YYYY-MM-DD")
                          }
                          onChange={(newValue: any) => {
                            dispatch(UpdatePaymentData("ToDate", newValue));
                           
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Box>
{loading ?
  <Box  sx={{ display: 'flex',justifyContent:'center',alignItems:'center',height:'75vh' }}>
  <CircularProgress />
</Box>
:

<>
{data.length ?(
  <Fragment>
                {data.map((item: any, idx: number) => (
                  <div className="kpi-mt-4">
                    <Box
                      component={Paper}
                      elevation={2}
                      p={0}
                      className="boxes"
                    >
                      <div className="justify-content-center d-flex primary_clr p-3">
                        <Typography className="text-white">
                          <b>Appointments</b>
                        </Typography>
                      </div>
                      <div className="p-3">
                        <div className="row pd-1">
                          <div key={idx} className="col-sm-10 col-10">
                            <Typography className="mleft-2">
                              <b>Available Appointments</b>
                            </Typography>
                          </div>
                          <div className="col-sm-2 col-2">
                            <Typography className="mleft-2 float-right">
                              <b>{item.TotalApts}</b>
                            </Typography>
                          </div>
                        </div>
                        <div className="row pd-1">
                          <div key={idx} className="col-sm-10 col-10">
                            <Typography className="mleft-2">
                              <b>Booked Appointments</b>
                            </Typography>
                          </div>
                          <div className="col-sm-2 col-2">
                            <Typography className="mleft-2 float-right">
                              <b>{item.BookedApts}</b>
                            </Typography>
                          </div>
                        </div>
                        <div className="row pd-1">
                          <div key={idx} className="col-sm-10 col-10">
                            <Typography className="mleft-2">
                              <b>Completed Appointments</b>
                            </Typography>
                          </div>
                          <div className="col-sm-2 col-2">
                            <Typography className="mleft-2 float-right">
                              <b>{item.CompApts}</b>
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Box>

                    <Box
                      component={Paper}
                      elevation={2}
                      p={0}
                      className="boxes mt-4"
                    >
                      <div className="justify-content-center d-flex primary_clr p-3">
                        <Typography className="text-white">
                          <b>Billings</b>
                        </Typography>
                      </div>
                      <div className="p-3">
                        <div className="row pd-1">
                          <div  key={idx} className="col-sm-10 col-10">
                            <Typography className="mleft-2">
                              <b>Hourly Earnings</b>
                            </Typography>
                          </div>
                          <div key={idx} className="col-sm-2 col-2">
                            <Typography className="mleft-2 float-right">
                              <b>{item.HourlyEarning}</b>
                            </Typography>
                          </div>
                        </div>
                        <div className="row pd-1">
                          <div key={idx} className="col-sm-10 col-10">
                            <Typography className="mleft-2">
                              <b>Actual Billing</b>
                            </Typography>
                          </div>
                          <div className="col-sm-2 col-2">
                            <Typography className="mleft-2 float-right">
                              <b>{item.TotalFees}</b>
                            </Typography>
                          </div>
                        </div>
                        <div className="row pd-1">
                          <div  key={idx} className="col-sm-10 col-10">
                            <Typography className="mleft-2">
                              <b>Per Patient Average</b>
                            </Typography>
                          </div>
                          <div className="col-sm-2 col-2">
                            <Typography className="mleft-2 float-right">
                              <b>{item.PpAvg}</b>
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </div>
                ))}
</Fragment> )
:(
  <Fragment>
  <div className="m-4-web">
    <Box
      className="date_box mt-4 p-5 justify-content-center text-center align-item-center mt-0"
      component={Paper}
      elevation={2}
      p={2}
      sx={{
        borderRadius: "10px",
      }}
    >
      <Image
        style={{
          margin: 10,
        }}
        src={require("../assets/images/Datanotfound.png")}
        width="216"
        height="194"
      />
      <Typography className="not_fond">
        Data Not Found
      </Typography>
    </Box>
  </div>
</Fragment>
) }
</>
}
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div className="kpi_mobile_view m-3  coddgp_mobile_view">
          <Row>
            <Col md={12}  className="mx-auto msi_kpi kpi-pad">
              <Box
                className="date_box mt-2"
                component={Paper}
                elevation={2}
                p={2}
                sx={{
                  backgroundColor: "primary.main",
                  borderRadius: "10px",
                }}
              >
                <Row>
                  <Col xs={6}>
                    <div className="text-center date_name align-item-center mb-2">
                      From Date
                    </div>
                    <div className="font-15">
                      <DatePicker
                        maxDate={
                          moment(searchRedux.ToDate.toString())
                            ? moment(searchRedux.ToDate.toString())
                            : moment().format("YYYY-MM-DD")
                        }
                        closeOnSelect={true}
                        format="DD/MM/YYYY"
                        sx={{ borderRadius: "15px", fontWeight: 700 }}
                        className="align-item-center sidebar_datebicker d-flex text-center br-20 font-15"
                        //label="Controlled picker"
                        value={
                          moment(searchRedux.FromDate.toString())
                            ? moment(searchRedux.FromDate.toString())
                            : moment(fromdate)
                        }
                        onChange={(newValue: any) => {
                          dispatch(UpdatePaymentData("FromDate", newValue));
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="text-center date_name align-item-center mb-2">
                      To Date
                    </div>
                    <div className="font-15">
                      <DatePicker
                        minDate={
                          moment(searchRedux.FromDate.toString())
                            ? moment(searchRedux.FromDate.toString())
                            : moment().format("YYYY-MM-DD")
                        }
                        maxDate={moment(nowDate)
                        }
                        closeOnSelect={true}
                        format="DD/MM/YYYY"
                        sx={{ borderRadius: "15px" }}
                        className="align-item-center sidebar_datebicker d-flex MK text-center br-20"
                        //label="Controlled picker"
                        value={
                          moment(searchRedux.ToDate.toString())
                            ? moment(searchRedux.ToDate.toString())
                            : moment().format("YYYY-MM-DD")
                        }
                        onChange={(newValue: any) => {
                          dispatch(UpdatePaymentData("ToDate", newValue));
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Box>
              {loading ?
  <Box  sx={{ display: 'flex',justifyContent:'center',alignItems:'center',height:'100%' }}>
  <CircularProgress />
</Box>
:

<>
{data.length ?(
  <Fragment>
              {data.map((item: any, idx: number) => (
                <div className="mt-3">
                  <Card className="custom-card">
                    <Card.Body>
                      <Button className="card-button" size="lg">
                        Appointments
                      </Button>
                      <div className="mt-3">
                        <Table>
                          <tr>
                            <td>
                              <Typography>
                                <b>Available Appointments</b>
                              </Typography>
                            </td>
                            <td>
                              <Typography className="kpi-values">{item.TotalApts}</Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography>
                                <b>Booked Appointments</b>
                              </Typography>
                            </td>
                            <td>
                              <Typography className="kpi-values">{item.BookedApts}</Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography>
                                <b>Completed Appointments</b>
                              </Typography>
                            </td>
                            <td>
                              <Typography className="kpi-values">{item.CompApts}</Typography>
                            </td>
                          </tr>
                        </Table>
                      </div>
                    </Card.Body>
                  </Card>

                  <Card className="custom-card mt-3">
                    <Card.Body>
                      <Button className="card-button" size="lg">
                        Billings
                      </Button>

                      <div className="mt-3">
                        <Table>
                          <tr>
                            <td>
                              <Typography>
                                <b> Hourly Earnings</b>
                              </Typography>
                            </td>
                            <td>
                              <Typography className="kpi-values">{item.HourlyEarning}</Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography>
                                <b>Actual Billing</b>
                              </Typography>
                            </td>
                            <td>
                              <Typography className="kpi-values">{item.TotalFees}</Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography>
                                <b>Per Patient Average</b>
                              </Typography>
                            </td>
                            <td>
                              <Typography className="kpi-values">{item.PpAvg}</Typography>
                            </td>
                          </tr>
                        </Table>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
</Fragment> )
:(
  <Fragment>
  <div className="m-4-web">
    <Box
      className="date_box mt-4 p-5 justify-content-center text-center align-item-center mt-0"
      component={Paper}
      elevation={2}
      p={2}
      sx={{
        borderRadius: "10px",
      }}
    >
      <Image
        style={{
          margin: 10,
        }}
        src={require("../assets/images/Datanotfound.png")}
        width="216"
        height="194"
      />
      <Typography className="not_fond">
        Data Not Found
      </Typography>
    </Box>
  </div>
</Fragment>
) }

</>
}
            </Col>
          </Row>
        </div>
      )}
    </Fragment>
  );
};
