import {
  Box,
  Container,
  FormControl,
  Paper,
  Typography,
  Button,
  TextareaAutosize,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { PaymentState as SearchReduxState, useSelector } from "../redux";
import { useDispatch } from "react-redux";
import { getApi } from "../services";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import moment from "moment";
import "get-random-values";
import useMediaQuery from "@mui/material/useMediaQuery";
import "bootstrap/dist/css/bootstrap.min.css";
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import { getTokenOrRefresh } from "./token_util";

const SPEECH_KEY = 'ebfdc305a8954acb85283785ed9ce80e';
const SPEECH_REGION = 'eastus';

export const DictateNotes: React.FC = () => {
  const [isListening, setIsListening] = useState(false);
  const speechConfig = useRef<sdk.SpeechConfig | null>(null);
  const audioConfig = useRef<sdk.AudioConfig | null>(null);
  const recognizer = useRef<sdk.SpeechRecognizer | null>(null);

  const [myTranscript, setMyTranscript] = useState<string>("");
  const [recognizingTranscript, setRecTranscript] = useState<string>("");

  const [value, onChangeText] = React.useState("");
  const [hide, sethide] = useState(false);
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );

  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event: any) => {
    setSearchTerm(event.target.value);
  };
  const getapi = getApi();
  const [ageOfDob, setageOfDob] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = useState("");

  const [text, setText] = useState("");
  const maxCharacters = 160;

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    //console.log("selecteddate: ", searchRedux.selecteddate);
    const dobNonForm = searchRedux.dob;
    var dobNonFormAra =dobNonForm.split("-");
    if(dobNonFormAra[0].length == 4){
      var dobForm =dobNonForm;
    }else{
      var dobForm =dobNonFormAra[2]+'-'+dobNonFormAra[1]+'-'+dobNonFormAra[0];
    }
    const ageOfDobConst =moment().diff(dobForm,'years');
    setageOfDob(ageOfDobConst);

  },[searchRedux]);
  const submit = () => {
    copyToClipboard()
    let data = {
      // location_id: searchRedux.location_id,
      // p_id: id,
      // mta_data: value,

      "location_id": searchRedux.location_id,
      "user_id": searchRedux.userid,
      "pnt_id": id,
      "apt_date":  moment(searchRedux.selecteddate).format('YYYY-MM-DD'),
      "mta_data": value
    };
    getapi
      .post("halomtarecordnew/", data)
      .then((res) => {
        //console.log("logout response:->", res);

        if (res.data.Success) {
          setMsg(res.data.Message);
          setOpen(true);
        }
      })
      .catch((err) => {
        //console.log("error", err);
      });
  };


  const getmtadata = () => {
    copyToClipboard()
    let data = {
      // location_id: searchRedux.location_id,
      // p_id: id,
      // mta_data: value,

      "location_id": searchRedux.location_id,
      "user_id": searchRedux.userid,
      "pnt_id": id,
      "apt_date":  moment(searchRedux.selecteddate).format('YYYY-MM-DD'),
    
    };
    getapi
      .post("halomtarecordview/", data)
      .then((res) => {
        //console.log("logout response:->", res);

        // if (res.data.Success) {
        //   setMsg(res.data.Message);
        //   setOpen(true);
        // }
      })
      .catch((err) => {
        //console.log("error", err);
      });
  };


useEffect(()=>{
  getmtadata()
},[])

  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value)
      .then(() => {
        setIsCopied(true);
        //console.log('text copied')
      })
      .catch(err => {
        console.error('Unable to copy text: ', err);
      });
  };



  const [data, setdata] = useState([]);

  useEffect(() => {
    checktocken();
  }, []);

  const checktocken = async () => {
    // check for valid speech key/region
    const tokenRes = await getTokenOrRefresh();
    if (tokenRes.authToken === null) {
      onChangeText("FATAL_ERROR: " + tokenRes.error);
    }
  };
  {
    /* timer function */
  }
  const [count, setCount] = useState("");
  const [timer, setTimer] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  let increment: any = useRef(null);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(true);
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(increment.current);
    setIsPaused(false);
  };

  const handleResume = () => {
    setIsPaused(true);
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handleReset = () => {
    clearInterval(increment.current);
    setIsActive(false);
    setIsPaused(false);
    setTimer(0);
  };

  const formatTime = () => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes: any = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };

  let intervalId: any = null;
  function AstartTimer() {
    setCount("00:00:00");
    intervalId = setInterval(startTimer, 1000);

    //console.log("intervalId===>", intervalId);
  }

  let totalSeconds = 0;
  function startTimer() {
    ++totalSeconds;
    let hour: any = Math.floor(totalSeconds / 3600);
    let minute: any = Math.floor((totalSeconds - hour * 3600) / 60);
    var Nminute = minute;
    let seconds: any = totalSeconds - (hour * 3600 + minute * 60);
    if (seconds.toString().length == 1) seconds = "0" + seconds;
    if (minute.toString().length == 1) minute = "0" + minute;
    if (hour.toString().length == 1) hour = "0" + hour;
    var hourminuteseconds = hour + ":" + minute + ":" + seconds;
    if (Nminute == 5) {
      totalSeconds = 0;
    }
    setCount(hourminuteseconds);
  }


  const startrecord = () =>{
    speechConfig.current = sdk.SpeechConfig.fromSubscription(
      SPEECH_KEY,
      SPEECH_REGION
    );
    speechConfig.current.speechRecognitionLanguage = 'en-US';

    audioConfig.current = sdk.AudioConfig.fromDefaultMicrophoneInput();
    recognizer.current = new sdk.SpeechRecognizer(
      speechConfig.current,
      audioConfig.current
    );
    // recognizer.current?.stopContinuousRecognitionAsync()
    const processRecognizedTranscript = (event: sdk.SpeechRecognitionEventArgs) => {
      const result = event.result;
      console.log('processRecognizedTranscript result:', result);

      if (result.reason === sdk.ResultReason.RecognizedSpeech) {
        const transcript = result.text;
        console.log('Transcript: -->', transcript);
        // Call a function to process the transcript as needed
        setMyTranscript(transcript);
        if (transcript !== undefined) {
          onChangeText((prevState) => prevState + transcript);
        }

      

      }
    };

    const processRecognizingTranscript = (event: sdk.SpeechRecognitionEventArgs) => {
      const result = event.result;
      console.log('processRecognizingTranscript result:', result);
      if (result.reason === sdk.ResultReason.RecognizingSpeech) {
        const transcript = result.text;
        //console.log('Transcript: -->', transcript);
        // Call a function to process the transcript as needed
        setRecTranscript(transcript);
      }
    };

    recognizer.current.recognized = (s, e) => processRecognizedTranscript(e);
    recognizer.current.recognizing = (s, e) => processRecognizingTranscript(e);

  
      recognizer.current.startContinuousRecognitionAsync(() => {
        //console.log('Speech recognition started.');
        setIsListening(true);
      });
    
   

    return () => {
      recognizer.current?.stopContinuousRecognitionAsync(() => {
        setIsListening(false);
      });
    };
  }
  const pauseListening = () => {
    setIsListening(false);
    recognizer.current?.stopContinuousRecognitionAsync();
    //console.log('Paused listening.');
  };

  const resumeListening = () => {
    startrecord();
    sethide(true);
    handleStart();
   
    if (!isListening) {
      setIsListening(true);
      recognizer.current?.startContinuousRecognitionAsync(() => {
        //console.log('Resumed listening...');
      });
    }
  };

  const stopListening = () => {
    handlePause();
    sethide(false);
    setIsListening(false);
    recognizer.current?.stopContinuousRecognitionAsync(() => {
      console.log('Speech recognition stopped.');
    });
    // recognizer.current?.close()
  };

  useEffect(()=>{
    stopListening()
  },[])

  return (
    // <div>
    //   <button onClick={pauseListening}>Pause Listening</button>
    //   <button onClick={resumeListening}>Resume Listening</button>
    //   <button onClick={stopListening}>Stop Listening</button>

    //   <div>
    //     <div>
    //       Recognizing Transcript : {recognizingTranscript}
    //     </div>

    //     <div>
    //       Recognized Transcript : {myTranscript}
    //     </div>
    //   </div>
    // </div>

    <Fragment>
    {!matches ? (
      <div>
        <Container>
          <Row>
            <Col md={10} className="mx-auto">
              <Box
                className="date_box"
                component={Paper}
                elevation={2}
                p={2}
                sx={{
                  backgroundColor: "primary.main",
                }}
                style={{
                  paddingBottom: 21,
                }}
              >
                <div
                  className="d-flex text-left justify-content-left"
                  style={{
                    width: 50,
                    float: "left",
                  }}
                >
                  {/* <Button
                    color="secondary"
                    sx={{ pt: 1, pb: 1 }}
                    variant="contained"
                  >
                    <RefreshIcon />
                  </Button> */}
                </div>

                <Typography
                  style={{
                    paddingTop: 10,
                  }}
                  className="d-flex text-center  justify-content-center "
                >
                  <p className="font-15">
                    {" "}
                    <b>
                      {" "}
                      {searchRedux.user_name} ({ageOfDob})
                      {/* {searchRedux.dob}  */}
                      
                    </b>
                  </p>
                  {/* <b>David Thomas 12/01/1978 44 Years Old</b> */}
                </Typography>
              </Box>

              <Row>
                <Col>
                  <Button
                    onClick={() => submit()}
                    sx={{ p: 1.5, mt: 3 }}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    startIcon={<ContentCopyIcon />}
                  >
                    Copy
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => onChangeText("")}
                    sx={{ p: 1.5, mt: 3 }}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    startIcon={<HighlightOffIcon />}
                  >
                    Clear
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      //console.log("Listening");
                      hide ? stopListening() : resumeListening();
                    }}
                    sx={{ p: 1.5, mt: 3 }}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    startIcon={hide ? <StopCircleIcon /> : <PlayArrowIcon />}
                  >
                    {hide ? "Stop" : "Start"}
                  </Button>
                </Col>
                <Col>
                  <Button
                    // onClick={() => navigate("/patient-details/" + id)}
                    onClick={() => {
                      submit();
                      navigate("/patient-details/" + id);
                    }}
                    sx={{ p: 1.5, mt: 3 }}
                    fullWidth
                    variant="contained"
                  >
                    Close
                  </Button>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md={6}>
                  <div
                    className="timer"
                    style={{
                      backgroundColor: "rgba(0, 38, 77, 0.10)",
                      color: "#4767AE",
                      fontWeight: "bold",
                    }}
                  >
                   
                    <Typography> <StopCircleIcon style={{marginTop:'-5px'}}/> <span style={{marginTop:'2px'}}>{formatTime()}</span></Typography>
                   
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormControl fullWidth className="mt-3">
                    <TextareaAutosize
                      style={{
                        backgroundColor: "white",
                        border: "1px solid #B0B0B0",
                      }}
                      disabled
                      value={value}
                      className="custom-text-area2 p-3"
                      aria-label="empty textarea"
                      minRows={10}
                      maxLength={160}
                      onChange={(e) => onChangeText(e.target.value)}
                    />
                  </FormControl>
                </Col>
              </Row>
            </Col>
          </Row>

          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              {msg}
            </Alert>
          </Snackbar>
        </Container>
      </div>
    ) : (
      <div className="dicatenotes-mobile-view p-2">
        <Row>
          <Col md={10} className="mx-auto">
            <Box
              className="date_box"
              component={Paper}
              elevation={2}
              p={2}
              sx={{
                backgroundColor: "primary.main",
              }}
              style={{
                paddingBottom: 21,
              }}
            >
              <Typography className="d-flex text-center justify-content-center head-div username">
                <p className="font-15">
                  {" "}
                  <b>
                    {" "}
                    {searchRedux.user_name} &nbsp; ({ageOfDob})
                    {/* {searchRedux.dob}  */}
                    {/* ({ageOfDob}) */}
                  </b>
                </p>
                {/* <b>David Thomas 12/01/1978 44 Years Old</b> */}
              </Typography>
            </Box>

            <div className="row mt-4">
              <div className="col-3">
                <Button
                  className="btns"
                  variant="contained"
                  color="secondary"
                  onClick={() => submit()}
                >
                  <ContentCopyIcon />
                  Copy
                </Button>
              </div>
              <div className="col-3">
                <Button
                  onClick={() => onChangeText("")}
                  className="btns"
                  variant="contained"
                  color="secondary"
                >
                  <HighlightOffIcon />
                  Clear
                </Button>
              </div>
              <div className="col-3">
                <Button
                  onClick={() => {
                    //console.log("Listening");
                    hide ? stopListening() : resumeListening();
                  }}
                  className="btns"
                  variant="contained"
                  color="secondary"
                  startIcon={hide ? <StopCircleIcon /> : <PlayArrowIcon />}
                >
                  {/* <PlayArrowIcon /> */}
                  {hide ? "Stop" : "Start"}
                </Button>
              </div>
              <div className="col-3">
                <Button
                  onClick={() => navigate("/patient-details/" + id)}
                  className="btns"
                  variant="contained"
                >
                  {/* <PlayArrowIcon /> */}
                  Close
                </Button>
              </div>
            </div>

            <Row className="mt-3">
              <Col xs={5}>
                {" "}
                <Button className="timer" startIcon={<StopCircleIcon />}>
                  {formatTime()}
                </Button>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormControl fullWidth className="mt-3">
                  <TextareaAutosize
                    disabled
                    className="custom-text-area2 p-3"
                    aria-label="empty textarea"
                    minRows={10}
                    maxLength={160}
                    value={value}
                    onChange={(e) => onChangeText(e.target.value)}
                    // placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
                  />
                </FormControl>
              </Col>
            </Row>
          </Col>
        </Row>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {msg}
          </Alert>
        </Snackbar>
      </div>
    )}
  </Fragment>
  );
};
