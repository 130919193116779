import {
  AppBar,
  Box,
  Container,
  FormControl,
  OutlinedInput,
  Paper,
  Toolbar,
  Typography,
  Button,
  Alert,
  AlertTitle,
  FormHelperText,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Col, FormLabel, Row, Table } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getApi } from "../services";
import { PaymentState as SearchReduxState, useSelector } from "../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../redux/User/actions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { useAuth } from "../auth/AuthProvider";
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


export const TwoFactor: React.FC = (props: any) => {
  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState(searchRedux.username);
  const [password, setPassword] = useState(searchRedux.password);
  const [mobilenumber, setMobileNumber] = useState(searchRedux.user_phone_no);
  const [userCookie, setuserCookie] = useState("");
  
  const [nameerror, seterror1] = useState(false);
  const [passerror, setpasserror] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const getapi = getApi();
  const { login } = useAuth();
  const [otpverify, setOTPVerify] = useState("");
  const [checkedtrustbrowser, settrustbrowser] = useState("");
  const [otpsuccess, setOTPsuccess] = useState("");
  const [otpSuccessAlert, setOtpSuccessAlert] = useState(false);
  const [otpErrorAlert, setOtpErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [dicloading, setDicLoading] = useState(false);
  const [checked, setChecked] = React.useState(true);
  const location = useLocation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  useEffect(()=>{
console.log('trustbrowser',checked)
console.log('uniquevalue',searchRedux.uniquevalue)
  },[checked])
  const handleOTPVerificationCode = async () => {
    setDicLoading(true)
    if(otpverify.length === 0 )
    {
      setDicLoading(false)
      setOtpErrorAlert(true)
      return
    }
    try {
      let data = {
        username: username,
        password: password,
        otp: otpverify,
        // otp: 182569,
      };

    
      const response = await getapi.post("halologinotpverify/", data);
      //console.log("halologinotpverify", response);

      await dispatch(UpdatePaymentData("otp", response.data.otp));
      //console.log("halologinotpverify", response.data.otp);

      if (response.data.Success) {
        await dispatch(UpdatePaymentData("otp", response.data.otp));
        //console.log("OTP successfully verified:", response.data.otp);
        setOtpSuccessAlert(true); // Show success alert
        setOtpErrorAlert(false); // Hide error alert


        dispatch(UpdatePaymentData("id", response.data.data[0].Id));
        dispatch(UpdatePaymentData("userid", response.data.data[0].UserId));
        dispatch(UpdatePaymentData("location_id", response.data.data[0].LocationId));
        dispatch(UpdatePaymentData("username", response.data.data[0].UserName));
        dispatch(UpdatePaymentData("password", response.data.data[0].Password));
        dispatch(UpdatePaymentData("LoginuserMobileno", response.data.data[0].MobileNumber));
        dispatch(UpdatePaymentData("role", response.data.data[0].Role));
        dispatch(UpdatePaymentData("LoginuserName", response.data.data[0].Name));
        dispatch(UpdatePaymentData("Loginuseremail", response.data.data[0].MailId));
        dispatch(UpdatePaymentData("LoginuserMobileno", response.data.data[0].MobileNo));
        dispatch(UpdatePaymentData("ProfPhotoN", response.data.data[0].ImagePath.split('/').pop()));

        login();
        const todat = moment().add().format("YYYY-MM-DD");
        dispatch(UpdatePaymentData("FromDate", todat));
        dispatch(UpdatePaymentData("ToDate", todat));
        dispatch(UpdatePaymentData("selecteddate", todat));
       
        setDicLoading(false)

       if(checked){         
            setuserCookie(username+"#_#"+password);
            const cockie_name="userCookie";
            const cockie_days =30;
            const cockie_expires = new Date();
            const cockie_value = username+"#_#"+password;
            cockie_expires.setTime(cockie_expires.getTime() + cockie_days * 24 * 60 * 60 * 1000);
            document.cookie = `${cockie_name}=${cockie_value};expires=${cockie_expires.toUTCString()};path=/`;

            // store the uinquevalue when user trust the browser
            const cockie_name2="uniquevalue";
            const cockie_days2 =30;
            const cockie_expires2 = new Date();
            const cockie_value2 = searchRedux.uniquevalue;
            cockie_expires.setTime(cockie_expires2.getTime() + cockie_days2 * 24 * 60 * 60 * 1000);
            document.cookie = `${cockie_name2}=${cockie_value2};expires=${cockie_expires2.toUTCString()};path=/`;

        }
        Login(username,password)
          navigate("/sidebar");
          
      } else {
        console.error("OTP verification failed:", response.data.ErrorMessage);
        setErrorMessage(response.data.ErrorMessage);
        setOtpSuccessAlert(false);
        setOtpErrorAlert(true);
        setDicLoading(false)
      }
    } catch (error) {
      console.error(error);
      //setErrorMessage("An error occurred while verifying OTP.");
     setOtpSuccessAlert(false);
      setOtpErrorAlert(true);
      setDicLoading(false)
    }
  };


  const Login = async (username:any,password:any) => {

   

    try {
      const apiUrl = "https://coddgpapi.coddapps.com.au/api/halologin/"; // Replace with your API URL
      const requestBody = {
        username: username,
        password: password,
        is_diect:'1',     

      };

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Validate: "y2s4pyj52nzr49jnuxxgqk5jtj28cj",
        },
        body: JSON.stringify(requestBody),
      });
 
      if (response.ok) {
        const data = await response.json();
       
        // Store the unique value to redux 
        dispatch(UpdatePaymentData("uniquevalue", data.data[0].UNIQUEVALUE));

        const cockie_name="uniquevalue";
        const cockie_days =30;
        const cockie_expires = new Date();
        const cockie_value = data.data[0].UNIQUEVALUE;
        cockie_expires.setTime(cockie_expires.getTime() + cockie_days * 24 * 60 * 60 * 1000);
        document.cookie = `${cockie_name}=${cockie_value};expires=${cockie_expires.toUTCString()};path=/`;
    
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


  useEffect(()=>{
//console.log(trustbrowser)
  },)
  return (
    <Fragment>
      {!matches ? (
        <div className="recover-web-view">
          <Container>
            <Row>
              <Col md={10} className="mx-auto">
                <Box
                  component={Paper}
                  elevation={2}
                  p={0}
                  mt={0}
                  className="boxes"
                >
                  <div className="login-div">
                    <Row>
                      <Col md={6}>
                        <img
                          src={require("../assets/images/Maskgroupweb.png")}
                          alt="Logo"
                          className="img-fluid"
                        />
                      </Col>
                      <Col md={6} className="mt-5">
                        <div className="p-5 mt-5">
                          <div>
                            <Typography className="recover-txt" >
                              Two Factor Authentication
                            </Typography>
                          </div>
                          <div className="mt-2">
                            {/* Success Alert */}
                            {otpSuccessAlert && (
                              <Alert severity="success">
                                <AlertTitle>OTP successfully verified!</AlertTitle>
                                
                              </Alert>
                            )}

                           
                            {otpErrorAlert && (
                              <Alert severity="error">
                                <AlertTitle>Invalid OTP</AlertTitle>
                                {errorMessage}
                              </Alert>
                            )}
                          </div>
                          <div>
                            <Form>
                              <Form.Group
                                className="mt-4 text-center "
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  value={otpverify}
                                  onChange={(e) =>{ 
                                    setOTPVerify(e.target.value);
                                    setOtpErrorAlert(false);
                                    setOtpSuccessAlert(false)
                                  }}
                                  className="input-phone"
                                  type="number"
                                  placeholder="Enter OTP"
                                />
                              </Form.Group>

 <FormControlLabel control={<Checkbox defaultChecked 
 checked={checked}
 onChange={handleChange}
 inputProps={{ 'aria-label': 'controlled' }}
 />} label="Trust this Browser?" />
                             {/* <p className="mt-3">
                                <input type="checkbox" className="checkbox" id="trustbrowser" value="1"
                                  onChange={(e) => settrustbrowser(e.target.value)}
                                /> Trust this Browser?
                              </p> */}
                            </Form>
                          </div>
                          <div>
                            <Button
                              className="send-btn mt-4"
                              onClick={handleOTPVerificationCode}
                            >
                                  {dicloading ?<CircularProgress style={{
                      color:"#FFF"
                     }}  size={24} /> : 'Verify'}   
                              
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Box>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div  className="login-mobile-view recover_psg loginbanner">
          <Container>
            <Row>
              <Col md={10} className="mx-auto" style={{padding:"0px"}}>
                <Box
                  component={Paper}
                  elevation={2}
                  p={0}
                  mt={0}
                  className="boxes gray_bg"
                >
                  <div className="login-div">
                    <Row>
                      <Col md={6}>
                        <img
                          src={require("../assets/images/Maskgroupweb.png")}
                          alt="Logo"
                          className="img-fluid custom-banner"
                        />
                      </Col>
                    
                      <Col md={6}>
                      <div className="p-4">
                          <div>
                            <Typography
                              variant="h5"
                              className="text-center first-text"
                              style={{fontSize:"20px"}}>
                              Two Factor Authentication
                            </Typography>
                          </div>
                          <div className="mt-2">
                            {/* Success Alert */}
                            {otpSuccessAlert && (
                              <Alert severity="success">
                                OTP successfully verified!
                              </Alert>
                            )}

                            {/* Error Alert */}
                            {otpErrorAlert && (
                              <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                {errorMessage}
                              </Alert>
                            )}
                          </div>
                          <div className="morepadding">
                          <Form>
                              <Form.Group
                                className="mt-4 text-center "
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  value={otpverify}
                                  onChange={(e) => {
                                    setOTPVerify(e.target.value)
                                    setOtpErrorAlert(false);
                                    setOtpSuccessAlert(false)
                                  }}
                                  className="input-phone"
                                  type="number"
                                  placeholder="Enter OTP"
                                />
                              </Form.Group>
                              <FormControlLabel control={<Checkbox defaultChecked 
 checked={checked}
 onChange={handleChange}
 inputProps={{ 'aria-label': 'controlled' }}
 />} label="Trust this Browser?" />

                             {/* <p className="mt-3">
                                <input type="checkbox" className="checkbox" id="trustbrowser" value="1"
                                  onChange={(e) => settrustbrowser(e.target.value)}
                                /> Trust this Browser?
                              </p> */}
                            </Form>
                          </div>
                          <div className="morepadding">
                            <Button
                              color="secondary"
                              variant="contained"
                              fullWidth
                              className="login-login-btn mt-4"
                              onClick={handleOTPVerificationCode}
                            >
                              {dicloading ?<CircularProgress style={{
                      color:"#FFF"
                     }}  size={24} /> : 'Verify'}   
                            </Button>
                          </div>
                          </div>
                      </Col>
                     
                    </Row>
                  </div>
                </Box>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </Fragment>
  );
};
