import { Box, Button, CircularProgress, Container, Paper } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { getApi } from "../../services";
import { PaymentState as SearchReduxState, useSelector } from "../../redux";
import { useDispatch } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import theme from "../../theme";
import { Col, Row } from "react-bootstrap";

export const CDM: React.FC = () => {
  const [loading, setLoading] = useState(false);
  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [data, setdata] = useState([]);
  const navigate = useNavigate();
  const getapi = getApi();

  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Fragment>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Fragment>
          {!matches ? (
            <div
              className="web-profile-div settings_page"
              style={{
                height: "85vh",
              }}
            >
              <Container>
                <Row>
                  <Col md={10} className="mx-auto">
                    <Box
                      component={Paper}
                      elevation={2}
                      p={0}
                      mt={1}
                      className="boxes"
                    >
                      <div className="p-3 mt-3">
                        <Button
                          variant="contained"
                          fullWidth
                          className="p-3 app_set"
                          style={{
                            fontSize: 18,
                            cursor: "auto",
                          }}
                        >
                          Clinical Audit
                        </Button>
                        <div className="mt-3">
                          <Button
                            onClick={() => navigate("/cdm/diabetes")}
                            variant="outlined"
                            fullWidth
                            className="p-3 link_button"
                            endIcon={<ArrowForwardIosIcon />}
                          >
                            <span>Diabetes</span>
                          </Button>
                        </div>
                        <div className="mt-3">
                          <Button
                            onClick={() =>
                              navigate("/cdm/ischemic-heart-disease")
                            }
                            variant="outlined"
                            fullWidth
                            className="p-3 link_button"
                            endIcon={<ArrowForwardIosIcon />}
                          >
                            <span>Ischemic heart disease</span>
                          </Button>
                        </div>

                        <div className="mt-3" style={{ marginLeft: "7px" }}>
                          <Button
                            onClick={() => navigate("/cdm/Healthoversevenfive")}
                            variant="outlined"
                            fullWidth
                            className="p-3 link_button"
                            endIcon={<ArrowForwardIosIcon />}
                          >
                            <span>Health Assessment over 75</span>
                          </Button>
                        </div>
                        <div className="mt-3" style={{ marginLeft: "7px" }}>
                          <Button
                            onClick={() =>
                              navigate("/cdm/HealthAssBtwFivetoNine")
                            }
                            variant="outlined"
                            fullWidth
                            className="p-3 link_button"
                            endIcon={<ArrowForwardIosIcon />}
                          >
                            <span>Health Assessment 45-49</span>
                          </Button>
                        </div>
                      </div>
                    </Box>
                  </Col>
                </Row>
              </Container>
            </div>
          ) : (
            <div className="settings_page coddgp_mobile_view">
              <Container>
                <Row>
                  <Col md={10} className="mx-auto">
                    <Box
                      component={Paper}
                      elevation={2}
                      p={0}
                      mt={1}
                      className="boxes"
                    >
                      <div className="p-3 mt-3">
                        <Button
                          variant="contained"
                          fullWidth
                          className="p-3 app_set"
                          style={{
                            fontSize: 18,
                            cursor: "auto",
                          }}
                        >
                          Clinical Audit
                        </Button>
                        <div className="mt-3">
                          <Button
                            onClick={() => navigate("/cdm/diabetes")}
                            variant="outlined"
                            fullWidth
                            className="p-3 link_button"
                            endIcon={<ArrowForwardIosIcon />}
                          >
                            <span>Diabetes</span>
                          </Button>
                        </div>
                        <div className="mt-3">
                          <Button
                            onClick={() =>
                              navigate("/cdm/ischemic-heart-disease")
                            }
                            variant="outlined"
                            fullWidth
                            className="p-3 link_button"
                            endIcon={<ArrowForwardIosIcon />}
                          >
                            <span>Ischemic heart disease</span>
                          </Button>
                        </div>

                        <div className="mt-3" style={{ marginLeft: "7px" }}>
                          <Button
                            onClick={() => navigate("/cdm/Healthoversevenfive")}
                            variant="outlined"
                            fullWidth
                            className="p-3 link_button"
                            endIcon={<ArrowForwardIosIcon />}
                          >
                            <span>Health Assessment over 75</span>
                          </Button>
                        </div>
                        <div className="mt-3" style={{ marginLeft: "7px" }}>
                          <Button
                            onClick={() =>
                              navigate("/cdm/HealthAssBtwFivetoNine")
                            }
                            variant="outlined"
                            fullWidth
                            className="p-3 link_button"
                            endIcon={<ArrowForwardIosIcon />}
                          >
                            <span>Health Assessment 45-49</span>
                          </Button>
                        </div>
                      </div>
                    </Box>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
          {/* <div>
            <p>Width of the device: {width}px</p>
          </div> */}
        </Fragment>
      )}
    </Fragment>
  );
};
