import {
  Box,
  Container,
  FormControl,
  OutlinedInput,
  Paper,
  Button,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import { getApi } from "../services";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
// Reducer Redux
import { PaymentState as SearchReduxState, useSelector } from "../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../redux/User/actions";

export const Profile: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );
  const [username, setusename] = useState(searchRedux.username);
  const [password, setPassword] = useState(searchRedux.password);

  const [usernameerror, setusenameerror] = useState(false);
  const [passworderror, setPassworderror] = useState(false);


  const getapi = getApi();

  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = useState("");

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const reset = () => {
    setusename(searchRedux.username);
    setPassword(searchRedux.password);
  };

  const submit = () => {
    if(username.trim() === ''){
      setusenameerror(true)
      return
    }
    if(password.trim() === ''){
      setPassworderror(true)
      return
    }
    let data = {
      id: searchRedux.id,
      username: username,
      password: password,
    };
    //console.log("submited data", data);

    getapi
      .post("halocredentialupdate/", data)
      .then((res) => {
        //console.log("profile response:->", res);

        if (res.data.Success) {
          setMsg(res.data.Message);
          dispatch(UpdatePaymentData("username", username));
          dispatch(UpdatePaymentData("password", password));
          setOpen(true);
        }
      })
      .catch((err) => {
        //console.log("error", err);
      });
  };

  return (
    <Fragment>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {msg}
        </Alert>
      </Snackbar>

      {!matches ? (
        <div className="web-profile-div">
          <Container>
            <Row>
              <Col md={10} className="mx-auto">
                <Box
                  component={Paper}
                  elevation={2}
                  p={2}
                  mt={3}
                  className="boxes"
                >
                  <div className="p-3">
                    <form>
                      <div>
                        <FormControl error={usernameerror}>
                          <FormLabel className="form-label">Username</FormLabel>

                          <OutlinedInput
                            error={usernameerror}
                            value={username}
                            onChange={(e) =>{ 
                              setusename(e.target.value);
                              setusenameerror(false)
                            }}
                            className="mt-1 input-border user-input"
                            endAdornment={
                              <IconButton>
                                <PersonOutlineIcon />
                              </IconButton>
                            }
                          />
                        {usernameerror &&  <FormHelperText>This field is required</FormHelperText> }
                        </FormControl>
                      </div>

                      <div>
                        <FormControl error={passworderror}>
                          <FormLabel className="form-label password-input mt-3">
                            Password
                          </FormLabel>
                          <OutlinedInput
                          error={passworderror}
                            value={password}
                            defaultValue={searchRedux.password}
                            onChange={(e) =>{ 
                              setPassword(e.target.value);
                              setPassworderror(false)
                            }}
                            className="mt-1 input-border"
                            endAdornment={
                              <IconButton>
                                <LockOpenIcon />
                              </IconButton>
                            }
                          />
                          {passworderror && <FormHelperText>This field is required</FormHelperText> }
                        </FormControl>
                      </div>
                    </form>
                  </div>
                  <div className="p-3">
                    <Button
                      onClick={() => submit()}
                      color="secondary"
                      className="save-button"
                      variant="contained"
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() => reset()}
                      color="secondary"
                      className="save-button ms-3"
                      variant="contained"
                    >
                      Reset
                    </Button>
                  </div>
                </Box>

                <Box
                  component={Paper}
                  elevation={2}
                  p={0}
                  mt={1}
                  className="boxes"
                >
                  {/* <div className="p-3 mt-3">
                    <div className="profile-div mt-3">
                      <Typography>Clinical Color Code</Typography>
                    </div>

                    <div className="mt-3" style={{ marginLeft: "7px" }}>
                      <Stack
                        className="mt-3"
                        direction="row"
                        alignItems="center"
                        gap={3}
                      >
                        <CircleIcon style={{ color: "#314E67" }} />
                        <Typography variant="body1">
                          Due for Management Plan
                        </Typography>
                      </Stack>
                      <Stack
                        className="mt-3"
                        direction="row"
                        alignItems="center"
                        gap={3}
                      >
                        <CircleIcon style={{ color: "#89BCE5" }} />
                        <Typography variant="body1">
                          Due for Management Plan Review
                        </Typography>
                      </Stack>
                      <Stack
                        className="mt-3 mb-3"
                        direction="row"
                        alignItems="center"
                        gap={3}
                      >
                        <CircleIcon style={{ color: "#58C18E" }} />
                        <Typography variant="body1">
                          Due for Health Assessment
                        </Typography>
                      </Stack>
                      <Stack
                        className="mt-3 mb-2"
                        direction="row"
                        alignItems="center"
                        gap={3}
                      >
                        <CircleIcon style={{ color: "#EFFB7B" }} />
                        <Typography variant="body1">Due for 10997</Typography>
                      </Stack>
                    </div>
                  </div> */}
                </Box>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div className="profile-mobile-view coddgp_mobile_view kpi-pad">
          <Row>
            <Col md={10}>
              <div className="mt-3">
                <form>
                  <FormControl className="profile-input">
                    <FormLabel className="form-label">User name</FormLabel>

                    <OutlinedInput
                      className="input-border"
                      value={username}
                      onChange={(e) => setusename(e.target.value)}
                      sx={{
                        input: {
                          color: "#000000",
                          fontSize: "15px",
                          fontWeight: 900,
                          lineHeight: "normal",
                          opacity: 2,
                          "&::placeholder": {
                            opacity: 1,
                            fontSize: "15px",
                            fontWeight: "900",
                          },
                        },
                        label: { color: "blue" },
                      }}
                      endAdornment={
                        <IconButton>
                          <PersonOutlineIcon />
                        </IconButton>
                      }
                    />
                  </FormControl>
                  <FormControl className="profile-input mt-3">
                    <FormLabel className="form-label">Password</FormLabel>
                    <OutlinedInput
                      className="input-border"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      sx={{
                        input: {
                          color: "#000000",
                          fontSize: "15px",
                          fontWeight: "900",
                          lineHeight: "normal",
                          "&::placeholder": {
                            opacity: 1,
                          },
                        },
                        label: { color: "blue" },
                      }}
                      endAdornment={
                        <IconButton>
                          <LockOpenIcon />
                        </IconButton>
                      }
                    />
                  </FormControl>
                </form>
              </div>
              <div className="mt-3">
                <Button
                  onClick={() => submit()}
                  color="secondary"
                  className="save-button"
                  variant="contained"
                >
                  Save
                </Button>
              </div>
              <div className="mt-3">
                <Button
                  onClick={() => reset()}
                  color="secondary"
                  className="reset-button"
                  variant="contained"
                >
                  Reset
                </Button>
              </div>

              {/* <Box
                component={Paper}
                elevation={2}
                p={0}
                mt={1}
                className="boxes"
              >
                <div className="p-3 mt-4">
                  <div className="profile-div">
                    <Typography className="text-center color-code-txt">
                      Clinical Color Code
                    </Typography>
                  </div>

                  <div className="mt-3" style={{ marginLeft: "7px" }}>
                    <Stack
                      className="mt-3"
                      direction="row"
                      alignItems="center"
                      gap={3}
                    >
                      <CircleIcon style={{ color: "#314E67" }} />
                      <Typography variant="body1">
                        Due for Management Plan
                      </Typography>
                    </Stack>
                    <Stack
                      className="mt-3"
                      direction="row"
                      alignItems="center"
                      gap={3}
                    >
                      <CircleIcon style={{ color: "#89BCE5" }} />
                      <Typography variant="body1">
                        Due for Management Plan Review
                      </Typography>
                    </Stack>
                    <Stack
                      className="mt-3"
                      direction="row"
                      alignItems="center"
                      gap={3}
                    >
                      <CircleIcon style={{ color: "#58C18E" }} />
                      <Typography variant="body1">
                        Due for Health Assessment
                      </Typography>
                    </Stack>
                    <Stack
                      className="mt-3"
                      direction="row"
                      alignItems="center"
                      gap={3}
                    >
                      <CircleIcon style={{ color: "#EFFB7B" }} />
                      <Typography variant="body1">Due for 10997</Typography>
                    </Stack>
                  </div>
                </div>
              </Box> */}
            </Col>
          </Row>
        </div>
      )}
    </Fragment>
  );
};
