import React, { Fragment, useEffect, useState } from "react";
import { PaymentState as SearchReduxState, useSelector } from "../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../redux/User/actions";
import { getApi } from "../services";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { Image } from "react-bootstrap";

interface Props {}

export const Logout: React.FC = () => {

      // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );
  const getapi = getApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
    useEffect(()=>{
        logout()
    },[])

    const logout = () => {
                // Check if we are in an iOS WebView environment
                const isIOSWebView = /iPhone|iPad|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

                if (isIOSWebView) {
                  // Check if 'webkit' and 'messageHandlers' are available
                  if ((window as any).webkit && (window as any).webkit.messageHandlers) {
                    // Call the 'postMessage' method
                    (window as any).webkit.messageHandlers.logoutAction.postMessage('logout');
                  } else {
                    // Handle the case where 'webkit' or 'messageHandlers' is not available
                    console.error('WebView environment does not support postMessage.');
                  }
                } else {
                  // Handle the case where we are not in an iOS WebView
                  console.error('This code should be executed in an iOS WebView environment.');
                }
                
        let data = {
          user_id: searchRedux.userid,
          user_name: searchRedux.username,
          role: searchRedux.role,
        };
        //console.log('logout data',data)

        getapi
          .post("halologout/", data)
          .then((res) => {
            //console.log("logout response:->", res);
    
            if (res.data.Success) {
              dispatch(UpdatePaymentData("userid", ""));
              dispatch(UpdatePaymentData("Loginfrom", ''));
              navigate('/')
            }
          })
          .catch((err) => {
            //console.log("error", err);
          });
      };


  return (
    <div id='direct_login'>
    <div className="loader">
      <div className="d-flex justify-content-center flex-direction-column" style={{
        flexDirection:'column'
      }}>
    <Image
          style={{
            margin: 10,
          }}
          src={require("../assets/images/logo.png")}
          width="240"
          height="60"
        />
       <div className="text-center justify-content-center">
       <CircularProgress color='secondary' />
        </div> 
</div>

    </div>
     
  </div>
  
  )
}

