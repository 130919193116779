import {
  AppBar,
  Box,
  Container,
  FormControl,
  OutlinedInput,
  Paper,
  Toolbar,
  Typography,
  Button,
  FormHelperText,
  CircularProgress,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Col, FormLabel, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getApi } from "../services";
import { PaymentState as SearchReduxState, useSelector } from "../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../redux/User/actions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
//import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
export const RecoverAccount: React.FC = () => {
  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = useState("");
  const [dicloading, setDicLoading] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const {name,password} = useParams()
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [nameerror, seterror1] = useState(false);
  const [passerror, setpasserror] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [responseData, setResponseData] = useState(null);

  // Forgot password mobile numner
  const [mobilenumber, setMobileNumber] = useState("");
  const [mobilenumberError, setMobilenumberError] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const getapi = getApi();

  const handleSendVerificationCode = async () => {
    setDicLoading(true)
    try {

      const trimmedNumber = mobilenumber.replace(/\s/g, '');



      if (( mobilenumber.length === 9) && /^\d+$/.test(mobilenumber)) {
        const sendmob =0+mobilenumber;
        let data = {
          mobile_no: sendmob,
          };
        const response = await getapi.post("haloforgotpassword/", data);
        //console.log(response);
        // Assuming that dispatch and navigate functions are async or return promises
        await dispatch(UpdatePaymentData("mobile_no", response.data.mobile_no));
       
       // setMsg(`Your OTP sent to ${mobilenumber}`);
       setMsg('SMS has been sent to your registered mobile no') 
            
        setOpen(true);
        setDicLoading(false)
        // setInfoMessage(`Your OTP sent to ${mobilenumber}`);
        setTimeout(() => {
          navigate("/otp-verify");
        }, 3000);
      } else {
        setMobilenumberError(true);
        setInfoMessage("");
        setDicLoading(false)
        setError(true);
      }
    } catch (error) {
      console.error(error);
      setDicLoading(false)
      setInfoMessage("");
    }
  };



  const handleOTPverify = () => {
    navigate("/otp-verify");
  };
  const handleLogin = () => {
    navigate("/");
  };

  return (
    <Fragment>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {msg}
        </Alert>
      </Snackbar>

      {!matches ? (
        <div className="recover-web-view">
          <Container>
            <Row>
              <Col md={10} className="mx-auto">
                <Box
                  component={Paper}
                  elevation={2}
                  p={0}
                  mt={0}
                  className="boxes"
                >
                  <div className="login-div">
                    <Row>
                      <Col md={6}>
                        <img
                          src={require("../assets/images/Maskgroupweb.png")}
                          alt="Logo"
                          className="img-fluid"
                        />
                      </Col>
                      <Col md={6}>
                        <div className="p-5 mt-5">
                          <div>
                            <Typography className="recover-txt">
                              Recover Account
                            </Typography>
                          </div>
                          <div>
                            {error && (
                              <div className="mt-4">
                                <Alert
                                  severity="error"
                                  onClose={() => setError(false)}
                                >
                                  Please enter your valid mobile number
                                </Alert>
                              </div>
                            )}

                            {success && (
                              <Alert
                                elevation={60}
                                severity="success"
                                onClose={() => setSuccess(false)}
                              >
                                OTP send successfully!
                              </Alert>
                            )}
                          </div>
                          <div>
                          <FormLabel className="login-label mt-4">Please enter your mobile number</FormLabel>
                            <FormControl error={mobilenumberError} fullWidth className="mt-2 text-center " sx={{ m: 1, width: '100%' }} variant="outlined">
                            
                              <OutlinedInput
                              error={mobilenumberError}
                              fullWidth
                               className="input-phone"
                                id="outlined-adornment-weight"
                                startAdornment={<InputAdornment position="start">+61</InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  'aria-label': 'weight',
                                  'maxLength': 9,
                                }}
                               // type="number"
                                value={mobilenumber}
                                onChange={(e) =>
                               {   
                                setMobileNumber(e.target.value);
                                setMobilenumberError(false)
                                setError(false)}
                               
                                }
                              />
                            {mobilenumberError &&  
                            <FormHelperText id="outlined-weight-helper-text">
                             Invalid Mobile Number 
                             </FormHelperText>
                             } 

                              </FormControl>

                              {/* <Form>
                              <Form.Group
                                className="mt-4 text-center "
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  // error={mobilenumberError}
                                  value={mobilenumber}
                                  onChange={(e) =>
                                 {   setMobileNumber(e.target.value);
                                  setError(false)}
                                  }
                                  className="input-phone"
                                 
                                  type="number"
                                  placeholder="Enter Mobile number"
                                />
                               
                            </Form.Group>
                          </Form> */}
                        </div>
                        <div>
                          <Button
                            className="send-btn mt-4"
                            onClick={handleSendVerificationCode}
                          >
                            {dicloading ? <CircularProgress style={{
                              color: "#FFF"
                            }} size={24} /> : 'Send'}
                          </Button>

                          {/* {infoMessage && <p>{infoMessage}</p>} */}
                        </div>
                        <div className="text-center">
                          <Button
                            className="login-txt mt-4 text-center"
                            onClick={() => navigate("/")}
                          >
                            Login
                          </Button>
                        </div>
                        <div className="text-left">
                           <p  className="mt-2 info_login">Please contact <a className='ahover' href='mailto:support@codd.com.au'>support@codd.com.au</a>  if the recovery process fails.</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Box>
            </Col>
          </Row>
        </Container>
        </div>
  ) : (
    <div className="login-mobile-view recover_psg loginbanner">
      <Container className={"m-0 p-0"}>
        <Row className={"m-0 p-0"}>
          <Col md={12} className="mx-auto m-0 p-0">
            <Box
              component={Paper}
              elevation={2}
              p={0}
              mt={0}
              className="boxes gray_bg"
            >
              <div className="login-div">
                <Row>
                  <Col md={12}>
                    <img
                      src={require("../assets/images/Maskgroupweb.png")}
                      alt="Logo"
                      className="img-fluid custom-banner"
                    />
                  </Col>
                  <Col md={12} className="p-0 m-0">
                    <div className="p-5">
                      <div>
                        <Typography
                          variant="h5"
                          className="text-center first-text"
                        >
                          Recover Account
                        </Typography>
                      </div>
                      <div>
                        {error && (
                          <div className="mt-4">
                            <Alert
                              severity="error"
                              onClose={() => setError(false)}
                            >
                              Please enter your valid mobile number
                            </Alert>
                          </div>
                        )}

                        {success && (
                          <Alert
                            elevation={60}
                            severity="success"
                            onClose={() => setSuccess(false)}
                          >
                            Login successful!
                          </Alert>
                        )}
                      </div>

                      <div className="loginpageform">
                      <FormLabel className="login-label mt-2">Please enter your mobile number</FormLabel>
                      <FormControl error={mobilenumberError} fullWidth className="mt-2 text-center " sx={{ m: 1, width: '100%' }} variant="outlined">
                    
                              <OutlinedInput
                              error={mobilenumberError}
                              fullWidth
                               className="input-phone"
                                id="outlined-adornment-weight"
                                startAdornment={<InputAdornment position="start">+61</InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  'aria-label': 'weight',
                                  'maxLength': 9,
                                }}
                               // type="number"
                                value={mobilenumber}
                                onChange={(e) =>
                               {   
                                setMobileNumber(e.target.value);
                                setMobilenumberError(false)
                                setError(false)}
                               
                                }
                              />
                            {mobilenumberError &&  
                            <FormHelperText id="outlined-weight-helper-text">
                             Invalid Mobile Number 
                             </FormHelperText>
                             } 

                              </FormControl>


                        {/* <Form>
                          <Form.Group
                            className="mt-4 text-center "
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control
                              value={mobilenumber}
                              onChange={(e) => {
                                setMobileNumber(e.target.value)
                                setError(false)
                              }
                              }
                              className="input-phone"
                              type="number"
                              placeholder="Enter Mobile number"
                            />
                            {mobilenumberError && (
                                  <FormHelperText className="error mt-3 ml-5">
                                    Please Enter Valid Mobile Number
                                  </FormHelperText>
                                )}
                          </Form.Group>
                        </Form> */}
                           <div>
                        <Button
                          color="secondary"
                          variant="contained"
                          fullWidth
                          className="login-login-btn mt-4"
                          onClick={handleSendVerificationCode}
                        >
                          Send
                        </Button>
                        {/* {infoMessage && <p>{infoMessage}</p>} */}
                      </div>
                      <div className="text-center">
                        <Button
                          id="btnt"
                          className="login-txt  mt-4 text-center"
                          onClick={() => navigate("/")}
                        >
                          Login
                        </Button>
                      </div>
                      <div className="text-left">
                      <p className="info_login mt-2">Please contact <a className='ahover' href='mailto:support@codd.com.au'>support@codd.com.au</a>  if the recovery process fails.</p>
                        </div>
                      </div>
                   
                    
                    </div>
                  </Col>
                </Row>
              </div>
            </Box>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
    </Fragment >
  );
};
