import axios from "axios";
export const ImageUrl = 'https://image.coddapps.com.au/'



export const getApi = () =>
  axios.create({
   // baseURL: "http://52.63.31.164:8008/api/",
   baseURL: "https://coddgpapi.coddapps.com.au/api/",
    headers: {
      'Content-Type':"application/json",
      Validate: "y2s4pyj52nzr49jnuxxgqk5jtj28cj",
  
    },
  });

  export const getFormApi = () =>
  axios.create({
   // baseURL: "http://52.63.31.164:8008/api/",
   baseURL: "https://coddgpapi.coddapps.com.au/api/",
    headers: {
      'Content-Type': "multipart/form-data",
      Validate: "y2s4pyj52nzr49jnuxxgqk5jtj28cj",
  
    },
  });

  // export const AptgetApi = () =>
  // axios.create({
  //   baseURL: "https://coddapt.coddapps.com.au/api",
  //   headers: {
  //     Validate: 'y2s4pyj52nzr49jnuxxgqk5jtj28cj', 
  //     "Content-Type": 'application/json'
  //   },
  // });


export const BulkClinicalAPI = () =>
  axios.create({
    baseURL:
      "https://coddgp.com.au/API/React_sidebar_clinical.php?UserLoc=S05088ANA&SDate=2023-09-15&User=76&GET_TYPE=Refresh&ID=Morris%20Jacobs_1961-06-27_##0404%20968%20232&APPTID=1668",
    headers: {
      "Content-Type": "application/json",
      // Validate: "y2s4pyj52nzr49jnuxxgqk5jtj28cj",
    },
  });
