import { PaymentData } from "../../../models/userdata";
import { UpdatePaymentData, UPDATE_PAYMENT_DATA,RESET_DATA,ResetData } from "../actions";
export const initialState: PaymentData = {
    location_id:'',
    AptLength:'',
    username: '',
    userid:'',
    APT_Time_Length: '',
    APTDate:'',
    APTTime:'',
    IsAvailable: '',
    Loginfrom:'',
    Academy: '',
    ProfPhotoN:'',
    ProfPic: '',
    AptreasonList:[],
    selectedreasonid: 0,
    selectedreason:'',
    selecteddate:'',
    selectedslot:'',
    uniqueid:'',
    uniquevalue:'',
    BTN_1_URL:'', 
    Location_Name:'',
    Location_mobile:"",
    FromDate:'',
    ToDate:'',
    role:'',
    user_phone_no:'',
    mobile_no:'',
    user_name:'',
    password:'',
    id:'',
    dob:'',
    sidebardata:'',
    detaildata:'',
    refreshdata:[],
    PntId:"",
    LoginuserName:"",
    Loginuseremail:"",
    LoginuserMobileno:"",
    Patients_phoneno:""
}

const paymentReducer = (state: PaymentData = initialState, action: UpdatePaymentData | ResetData):PaymentData => {
    switch (action.type) {
        case UPDATE_PAYMENT_DATA:
            return {

                ...state,
                [action.key]: action.value

            }
        case RESET_DATA:

            return initialState
        default:
            return state
    }
}

export default paymentReducer