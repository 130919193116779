import {
  AppBar,
  Box,
  Container,
  FormControl,
  OutlinedInput,
  Paper,
  Toolbar,
  Typography,
  Button,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getApi } from "../services";
import { PaymentState as SearchReduxState, useSelector } from "../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../redux/User/actions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import { useAuth } from "../auth/AuthProvider";
export const DirectLogin: React.FC = () => {
  const { login } = useAuth();
  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { name, password } = useParams();
  const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");
  const [nameerror, seterror1] = useState(false);
  const [passerror, setpasserror] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const getapi = getApi();
  const Login2 = () => {
    let data = {
      username: "SweeHanKhoo",
      password: "Khoocodd@321",
    };
    //console.log(data);
    getapi
      .post("halologin/")
      .then((response) => {
        //console.log("response data");
        //console.log(JSON.stringify(response.data.data));
        dispatch(UpdatePaymentData("userid", response.data.UserId));
        dispatch(UpdatePaymentData("location_id", response.data.LocationId));
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const [responseData, setResponseData] = useState(null);

  /* device checking function */

  function isMobileDevice() {
    // Check if the user agent contains 'Mobi', 'Android', or 'iPhone'
    // and also check if it's not running in an MSI environment
    return (
      /Mobi|Android|iPhone/i.test(navigator.userAgent) &&
      !/msi/i.test(navigator.userAgent)
    );
  }
  function isMSI() {
    // Check if the user agent contains 'msi'
    return /msi/i.test(navigator.userAgent);
  }

  function isDesktop() {
    // Check if the platform is not 'iPhone', 'iPad', 'iPod', 'Android', or 'Mobile'
    return !/(iPhone|iPad|iPod|Android|Mobile)/i.test(navigator.userAgent);
  }
  function isTabletDevice() {
    return (
      /iPad|Tablet|PlayBook|Silk|Kindle|Nexus 7|Nexus 10|KFAPWI/i.test(
        navigator.userAgent
      ) && !/Mobi|iPhone/i.test(navigator.userAgent)
    );
  }

  useEffect(() => {
    if (isMobileDevice()) {
      // console.log("This is a mobile device");
    } else if (isMSI()) {
      //  console.log("This app is running in an MSI environment.");
    } else if (isDesktop()) {
      //  console.log("This website is running on a desktop.");
    } else {
      //  console.log("This website is running on a other.");
    }
  }, [name, password]);

  useEffect(() => {
    Login();
  }, [name, password]);

  const Login = async () => {
    var loginfrom = "";
    if (isMobileDevice()) {
      //  console.log("This is a mobile device");
      loginfrom = "Mobile";
    } else if (isMSI()) {
      // console.log("This app is running in an MSI environment.");
      loginfrom = "MSI";
    } else if (isDesktop()) {
      //  console.log("This website is running on a desktop.");
      loginfrom = "Desktop";
    } else if (isTabletDevice()) {
      // console.log("This website is running on a tablet_view.");
      loginfrom = "tablet_view";
      dispatch(UpdatePaymentData("Loginfrom", "tablet_view"));
    } else {
      // console.log("This website is running on a other.");
    }

    try {
      const apiUrl = "https://coddgpapi.coddapps.com.au/api/halologin/"; // Replace with your API URL
      const requestBody = {
        username: name,
        password: password,
        is_diect: "1",
        login_from: loginfrom /* device checking */,
      };

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Validate: "y2s4pyj52nzr49jnuxxgqk5jtj28cj",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const data = await response.json();
        dispatch(UpdatePaymentData("id", data.data[0].Id));
        dispatch(UpdatePaymentData("userid", data.data[0].UserId));
        dispatch(UpdatePaymentData("location_id", data.data[0].LocationId));
        dispatch(UpdatePaymentData("username", data.data[0].UserName));
        dispatch(UpdatePaymentData("password", data.data[0].Password));
        dispatch(
          UpdatePaymentData("LoginuserMobileno", data.data[0].MobileNumber)
        );
        dispatch(UpdatePaymentData("role", data.data[0].Role));
        dispatch(UpdatePaymentData("LoginuserName", data.data[0].Name));
        dispatch(UpdatePaymentData("Loginuseremail", data.data[0].MailId));
        dispatch(UpdatePaymentData("LoginuserMobileno", data.data[0].MobileNo));
        dispatch(
          UpdatePaymentData(
            "ProfPhotoN",
            data.data[0].ImagePath.split("/").pop()
          )
        );
        // Store the unique value to redux
        dispatch(UpdatePaymentData("uniquevalue", data.data[0].UNIQUEVALUE));

        // const cockie_name="uniquevalue";
        // const cockie_days =30;
        // const cockie_expires = new Date();
        // const cockie_value = data.data[0].UNIQUEVALUE;
        // cockie_expires.setTime(cockie_expires.getTime() + cockie_days * 24 * 60 * 60 * 1000);
        // document.cookie = `${cockie_name}=${cockie_value};expires=${cockie_expires.toUTCString()};path=/`;

        login();
        const todat = moment().add().format("YYYY-MM-DD");
        dispatch(UpdatePaymentData("FromDate", todat));
        dispatch(UpdatePaymentData("ToDate", todat));
        dispatch(UpdatePaymentData("selecteddate", todat));
        //console.log("Response:", data);
        navigate("/sidebar");
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div id="direct_login">
      <div className="loader">
        <div
          className="d-flex justify-content-center flex-direction-column"
          style={{
            flexDirection: "column",
          }}
        >
          <Image
            style={{
              margin: 10,
            }}
            src={require("../assets/images/logo.png")}
            width="240"
            height="60"
          />
          <div className="text-center justify-content-center">
            <CircularProgress color="secondary" />
          </div>
        </div>
      </div>
    </div>
  );
};
