import {
  Box,
  Container,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Tooltip,
  Alert,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Col, Row, Table, Stack, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Image } from "react-bootstrap";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EditNoteIcon from "@mui/icons-material/EditNote";
import SmsIcon from "@mui/icons-material/Sms";
import moment from "moment";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { PaymentState as SearchReduxState, useSelector } from "../redux";
import { UpdatePaymentData, ResetData } from "../redux/User/actions";
import { useDispatch } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import "bootstrap/dist/css/bootstrap.min.css";
import { getApi, getFormApi } from "../services";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Popover from "@mui/material/Popover";
import axios from "axios";
import htmlDocx from "html-docx-js/dist/html-docx";
import { saveAs } from "file-saver";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

interface ExcelData {
  userlocid: string;
  pntid: string;
  pntName: string;
  practiceName: string;
  practiceAdd: string;
  practicePhone: string;
  pntDOB: string;
  pntAdd: string;
  pntPhone: string;
  pntMedicare: string;
  pntInsur: string;
  DoctorName: string;
  DoctorAdd: string;
  // CareplanDate: string;
  NewcareplanDate: string;
  Glucose: string;
  BMI: string;
  Height: string;
  Weight: string;
  BP: string;
  Cholesterol: string;
  HDL: string;
  LDL: string;
  Triglyceride: string;
  Ratio: string;
  HbA1c: string;
  DaysPerWeek: string;
  DrinksPerDay: string;
  Smoking: string;
}

export const PatientDetails: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event: any) => {
    setSearchTerm(event.target.value);
  };
  const [data, setdata] = useState([]);
  const [fromdate, setfromdate] = useState("");
  const [todate, settodate] = useState("");
  const [ageOfDob, setageOfDob] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dicloading, setDicLoading] = useState(false);
  const [Currentpage, setCurrentpage] = useState("");

  const dispatch = useDispatch();
  const { id, apptid } = useParams();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  //Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );
  //console.log("Search Redux Store", searchRedux);
  const getapi = getApi();
  const getformapi = getFormApi();

  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [downloading, setdownloading] = useState(false);

  const [copy, setcoped] = useState("Copy phone number");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    const handleCopyClick = async () => {
      try {
        await navigator.clipboard.writeText("+" + mobile);
        // window.open('tel:+' + mobile, '_system')
        setcoped("Copied");
        setTimeout(() => {
          handleClose();
        }, 3000);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    };
    handleCopyClick();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id2 = open ? "simple-popover" : undefined;

  useEffect(() => {
    //console.log("selecteddate: ", searchRedux.selecteddate);
    const dobNonForm = searchRedux.dob;
    var dobNonFormAra = dobNonForm.split("-");
    if (dobNonFormAra[0].length == 4) {
      var dobForm = dobNonForm;
    } else {
      var dobForm =
        dobNonFormAra[2] + "-" + dobNonFormAra[1] + "-" + dobNonFormAra[0];
    }
    const ageOfDobConst = moment().diff(dobForm, "years");
    setageOfDob(ageOfDobConst);
  }, [searchRedux]);

  useEffect(() => {
    const currentURL: string = window.location.href;
    setCurrentpage(currentURL);
    setDicLoading(false);
  }, []);

  // Python api data
  function isMobileDevice() {
    return /Mobi|Android|iPhone/i.test(navigator.userAgent);
  }
  const [mobile, setmobile] = useState(false);
  useEffect(() => {
    if (isMobileDevice()) {
      setmobile(true);
    }
    console.log("mobile", isMobileDevice());
  }, []);

  const [excelnewData, setExcelnewData] = useState<ExcelData | null>(null);

  const getData2 = () => {
    setLoading(true);
    let data = {
      userlocid: searchRedux.location_id,
      codd_sidebardate: moment(searchRedux.selecteddate.toString()).format(
        "YYYY-MM-DD"
      ),
      codd_duserid: searchRedux.userid,
      appt_id: id,
      id: searchRedux.uniqueid,
      get_type: searchRedux.detaildata ? searchRedux.detaildata : "Onload",
    };

    getapi
      .post("halosidebarclinicalnew/", data)
      .then((response) => {
        // console.log("API Full Response", response.data);
        // console.log("API Full Response", response.data.data[0])
        setLoading(false);
        setdata(response.data.data);
        setmobile(response.data.data[0].CallMobile);

        const responseData = response.data.data[0];
        // console.log("Response Data", responseData)

        const exceldata: ExcelData = {
          userlocid: searchRedux.location_id,
          pntid: searchRedux.PntId,
          pntName: responseData.PntName,
          practiceName: responseData.PracticeName,
          practiceAdd: responseData.PracticeAddress,
          practicePhone: responseData.PracticePhone,
          pntDOB: responseData.PntDob,
          pntAdd: responseData.Address,
          pntPhone: responseData.CallMobile,
          pntMedicare: responseData.Medicare,
          pntInsur: responseData.HealthInsurance,
          DoctorName: searchRedux.LoginuserName,
          DoctorAdd: responseData.PracticeAddress,
          // CareplanDate: responseData.CarePlanDate,
          NewcareplanDate: responseData.New_CarePlanDate,
          Glucose: responseData.Glucose["Glucose"],
          BMI: responseData.Bmi["BMI"],
          Height: responseData.Bmi["Height"],
          Weight: responseData.Bmi["Weight"],
          BP: `${responseData.BloodPressure["Systolic"]}/${responseData.BloodPressure["Diastolic"]}`,
          // BP: responseData.BloodPressure["Systolic"]["Diastolic"],

          Cholesterol: responseData.Cholestrol["Cholestrol"],
          HDL: responseData.Cholestrol["HDL"],
          LDL: responseData.Cholestrol["LDL"],
          Triglyceride: responseData.Cholestrol["Triglyceride"],
          Ratio: responseData.Cholestrol["Ratio"],
          HbA1c: responseData.Hba1c["HBA1C"],
          // HbA1c: responseData.PDF["HBA1C_VALUE"],
          DaysPerWeek: responseData.Alcohol["Days Per Week"],
          DrinksPerDay: responseData.Alcohol["Drinks Per Day"],
          Smoking: responseData.Smoke,
        };

        // Store the exceldata in the state
        setExcelnewData(exceldata);

        // Logging the exceldata to verify
        // console.log("Excel Data:", exceldata);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data", error);
      });
  };

  const [pdferror, setpdferror] = useState(false);

  const [success, setsuccess] = useState(false);

  const handleUpload = async () => {
    setdownloading(true);

    // saveAs("patient_report.docx");

    try {
      // Check if excelnewData exists before making the API call
      if (!excelnewData) {
        console.error("excelnewData is null or undefined");
        setdownloading(false);
        return;
      }

      console.log(
        "Sending request to generateGPExcel with data:",
        excelnewData
      );

      // Send the request to generate the GP Excel
      const response = await getformapi.post("generateGPExcel/", excelnewData);

      // console.log("Generate GP Excel API response:", response.data);

      if (response.data.Status === 200) {
        const docPath = response.data.docpath;
        const params = new URLSearchParams(window.location.search);

        // Update the URL parameters
        params.set("l", docPath);

        // Replace the current URL with the updated one
        window.history.replaceState(
          {},
          "",
          `${window.location.pathname}?l=${docPath}`
        );

        setsuccess(true);
        console.log("File generated successfully", response.data);

        setTimeout(() => {
          setsuccess(false);
        }, 3000);
      } else {
        console.error("API call failed with status:", response.data.Status);
        setpdferror(true);

        setTimeout(() => {
          setpdferror(false);
        }, 3000);
      }
    } catch (error) {
      console.error("Error generating file", error);
    } finally {
      setdownloading(false);
    }
  };

  useEffect(() => {
    // Cleanup the event listener on component unmount
    return () => {
      localStorage.removeItem("ColorCode"); // Remove a specific item
    };
  }, [navigate]);

  const getpatientPDF = () => {
    let data = {
      userlocid: searchRedux.location_id,
      codd_sidebardate: moment(searchRedux.selecteddate.toString()).format(
        "YYYY-MM-DD"
      ),
      codd_duserid: searchRedux.userid,
      appt_id: id,
      id: searchRedux.uniqueid,
    };

    // console.log("Details...  ", data);
    getapi
      .post("patientPDF/", data)
      .then((response) => {
        console.log(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getData2();
  }, [searchRedux.detaildata]);

  useEffect(() => {
    return () => {
      dispatch(UpdatePaymentData("detaildata", ""));
    };
  }, []);

  return (
    <>
      {loading ? (
        <Fragment>
          <Row>
            <Col
              md={12}
              xs={12}
              className={matches ? "mx-auto12 pad-clinic-box" : "mx-auto12"}
            >
              {!matches ? (
                <Box
                  className="date_box"
                  component={Paper}
                  elevation={2}
                  p={2}
                  sx={{
                    backgroundColor: "primary.main",
                  }}
                  style={{
                    paddingBottom: 3,
                    paddingTop: 5,
                  }}
                >
                  <Row style={{ padding: "5px" }}>
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                      <p className="text-center  justify-content-center mt-0 mb-0">
                        {" "}
                        <b>
                          {searchRedux.user_name}&nbsp;&nbsp;({ageOfDob})
                        </b>
                      </p>
                    </div>
                    <div className="col-md-3" style={{ textAlign: "right" }}>
                      <Image
                        width={30}
                        src={require("../assets/images/Closeicon.png")}
                        onClick={() => {
                          navigate("/sidebar");
                        }}
                      />
                    </div>
                  </Row>
                </Box>
              ) : (
                <Box
                  className="date_box"
                  component={Paper}
                  elevation={2}
                  p={2}
                  sx={{
                    backgroundColor: "primary.main",
                  }}
                  style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 12,
                    paddingRight: 12,
                  }}
                >
                  <Row>
                    <div className="col-10 pd-0">
                      <p className="d-flex text-center mt-0 mb-0 font-15 justify-content-center username">
                        {" "}
                        {searchRedux.user_name}&nbsp;({ageOfDob})
                        {/* <b>David Thomas 12/01/1978 44 Years Old</b> */}
                      </p>
                    </div>
                    <div className="col-1">
                      <Image
                        className="Closeicon"
                        width={30}
                        src={require("../assets/images/Closeicon.png")}
                        onClick={() => {
                          navigate("/sidebar");
                        }}
                      />
                    </div>
                  </Row>
                </Box>
              )}
            </Col>
          </Row>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "75vh",
            }}
          >
            <CircularProgress />
          </Box>
        </Fragment>
      ) : (
        <Fragment>
          {!matches ? (
            <div className="details-web-view mt--12">
              <Container>
                <Row>
                  {data.map((item: any, idx: number) => (
                    <Col md={12} xs={12} className="mx-auto12">
                      <Box
                        className="date_box"
                        component={Paper}
                        elevation={2}
                        p={2}
                        sx={{
                          backgroundColor: "primary.main",
                        }}
                        style={{
                          paddingBottom: 3,
                          paddingTop: 5,
                        }}
                      >
                        <Row style={{ padding: "5px" }}>
                          <div className="col-md-3"></div>
                          <div className="col-md-6">
                            <p className="text-center  justify-content-center mt-0 mb-0">
                              {" "}
                              <b>
                                {searchRedux.user_name} &nbsp;({ageOfDob})
                              </b>
                            </p>
                          </div>
                          <div
                            className="col-md-3"
                            style={{ textAlign: "right" }}
                          >
                            <Image
                              width={30}
                              src={require("../assets/images/Closeicon.png")}
                              onClick={() => {
                                navigate("/sidebar");
                              }}
                            />
                          </div>
                        </Row>
                      </Box>

                      <Row>
                        <Col xs={3} md={4}>
                          {/* <a href="tel:+1234567890">Call Us</a> */}
                          {/* <a href="window.open('tel:5551212')" className="external"> */}

                          {/* <a href="javascript:void(0);" onClick="handleCopyClick()" target="_system">  */}
                          <Button
                            onClick={handleClick}
                            sx={{ p: 1.5, mt: 1 }}
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className="mt-1"
                            startIcon={<LocalPhoneIcon />}
                          >
                            Call
                          </Button>
                          <Popover
                            id={id2}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                          >
                            <Typography sx={{ p: 2 }}>Copied</Typography>
                          </Popover>

                          {/* </a>   */}
                        </Col>
                        <Col xs={3} md={4}>
                          <Button
                            onClick={() => {
                              dispatch(
                                UpdatePaymentData(
                                  "user_phone_no",
                                  item.CallMobile
                                )
                              );
                              navigate("/sms");
                            }}
                            sx={{ p: 1.5, mt: 1 }}
                            fullWidth
                            variant="contained"
                            color="secondary"
                            startIcon={<SmsIcon />}
                          >
                            SMS
                          </Button>
                        </Col>
                        <Col xs={6} md={4}>
                          <Button
                            // onClick={() => navigate("/dictatenotes/" + id)}
                            className="dic_notes"
                            onClick={() => {
                              setDicLoading(true);

                              //  window.location.href ="https://dictation.coddapps.com.au/sidebarworking.php?p=" +searchRedux.user_name+"&age="+ageOfDob+"&u="+base64_encode(item.ListId)+"&link="+Currentpage}
                              window.location.href =
                                "https://dictation.coddapps.com.au/sidebarworking.php?p=" +
                                searchRedux.user_name +
                                "&age=" +
                                ageOfDob +
                                "&u=&param=" +
                                searchRedux.PntId +
                                "_" +
                                searchRedux.location_id +
                                "_" +
                                searchRedux.userid +
                                "_" +
                                moment(searchRedux.selecteddate).format(
                                  "YYYY-MM-DD"
                                ) +
                                base64_encode(item.ListId) +
                                "&link=" +
                                Currentpage;
                            }}
                            // onClick={() => window.open('https://coddgpphp.coddapps.com.au/sidebarworking.php?p='+searchRedux.user_name+'&age='+ageOfDob, '_blank')}
                            sx={{ p: 1.5, mt: 1 }}
                            fullWidth
                            variant="contained"
                            disabled={dicloading}
                            color="secondary"
                            startIcon={!dicloading && <EditNoteIcon />}
                          >
                            {dicloading ? (
                              <CircularProgress
                                style={{
                                  color: "#FFF",
                                }}
                                size={24}
                              />
                            ) : (
                              "Dictate Notes"
                            )}
                          </Button>
                        </Col>
                      </Row>

                      {/* details box */}
                      <Row className="mt-0">
                        <Col xs={12} md={4}>
                          <Box
                            component={Paper}
                            elevation={2}
                            p={0}
                            mt={1}
                            className="boxes new-box h-15rem"
                          >
                            <div className="p-2 mt-2">
                              <div className="profile-div">
                                <Typography className="color-code-txt">
                                  CDM
                                  <span className="float-right">
                                    {!item.ItemNo["lastDate"] ? (
                                      <PriorityHighIcon
                                        style={{
                                          fontSize: "25px",
                                          color: "#D06666",
                                        }}
                                      />
                                    ) : (
                                      <p>{item.ItemNo["lastDate"]}</p>
                                    )}
                                  </span>
                                </Typography>
                              </div>

                              <div className="mt-3">
                                <Row className="p-1">
                                  <Col md={3}>
                                    <Image
                                      className="icon-sid-web"
                                      src={require("../assets/images/clinicalicon/CDM.png")}
                                    />
                                  </Col>
                                  <Col xs={4} className="date_cdm">
                                    <Typography className="mt-1">
                                      <b>701</b>
                                      <b className="cdm-datas">
                                        {item.ItemNo["701"]
                                          ? " - " +
                                            moment(
                                              item.ItemNo["701"],
                                              "DD-MM-YYYY"
                                            ).format("DD-MM-YYYY")
                                          : "__________"}
                                      </b>
                                    </Typography>
                                    <Typography className="mt-3">
                                      <b>703</b>
                                      <b className="cdm-datas">
                                        {item.ItemNo["703"]
                                          ? " - " +
                                            moment(
                                              item.ItemNo["703"],
                                              "DD-MM-YYYY"
                                            ).format("DD-MM-YYYY")
                                          : "__________"}
                                      </b>
                                    </Typography>
                                    <Typography className="mt-3">
                                      <b>705</b>
                                      <b className="cdm-datas">
                                        {item.ItemNo["705"]
                                          ? " - " +
                                            moment(
                                              item.ItemNo["705"],
                                              "DD-MM-YYYY"
                                            ).format("DD-MM-YYYY")
                                          : "__________"}
                                      </b>
                                    </Typography>
                                    <Typography className="mt-3">
                                      <b>707</b>
                                      <b className="cdm-datas">
                                        {item.ItemNo["707"]
                                          ? " - " +
                                            moment(
                                              item.ItemNo["707"],
                                              "DD-MM-YYYY"
                                            ).format("DD-MM-YYYY")
                                          : "__________"}
                                      </b>
                                    </Typography>
                                  </Col>

                                  <Col md={5}>
                                    <Typography className="mt-1">
                                      <b>721</b>
                                      <b className="cdm-datas">
                                        {item.ItemNo["721"]
                                          ? " - " +
                                            moment(
                                              item.ItemNo["721"],
                                              "DD-MM-YYYY"
                                            ).format("DD-MM-YYYY")
                                          : "__________"}
                                      </b>
                                    </Typography>
                                    <Typography className="mt-3">
                                      <b>723</b>
                                      <b className="cdm-datas">
                                        {item.ItemNo["723"]
                                          ? " - " +
                                            moment(
                                              item.ItemNo["723"],
                                              "DD-MM-YYYY"
                                            ).format("DD-MM-YYYY")
                                          : "__________"}
                                      </b>
                                    </Typography>
                                    <Typography className="mt-3">
                                      <b>732</b>
                                      <b className="cdm-datas">
                                        {item.ItemNo["732"]
                                          ? " - " +
                                            moment(
                                              item.ItemNo["732"],
                                              "DD-MM-YYYY"
                                            ).format("DD-MM-YYYY")
                                          : "__________"}
                                      </b>
                                    </Typography>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Box>

                          <Box
                            component={Paper}
                            elevation={2}
                            p={0}
                            mt={3}
                            className="boxes new-box h-15rem mt-2"
                          >
                            <div className="p-2 mt-2">
                              <div className="profile-div">
                                <Typography className="color-code-txt">
                                  BMI
                                  <span className="float-right">
                                    {!item.Bmi["lastDate"] ? (
                                      <PriorityHighIcon
                                        style={{
                                          fontSize: "25px",
                                          color: "#D06666",
                                        }}
                                      />
                                    ) : (
                                      <p>{item.Bmi["lastDate"]}</p>
                                    )}
                                  </span>
                                </Typography>
                              </div>

                              <div className="mt-3">
                                <Row className="p-1">
                                  <Col xs={4} md={3}>
                                    <Image
                                      className="icon-sid-web"
                                      src={require("../assets/images/clinicalicon/BMI.png")}
                                    />
                                  </Col>
                                  <Col>
                                    <Row>
                                      <Col xs={9}>
                                        <Typography className="dt-typo mt-1">
                                          Height
                                        </Typography>
                                        <Typography className="dt-typo mt-3">
                                          Weight{" "}
                                        </Typography>
                                        <Typography className="dt-typo mt-3">
                                          BMI{" "}
                                        </Typography>
                                      </Col>
                                      <Col xs={3}>
                                        <Typography className="dt-typo mt-1">
                                          {item.Bmi["Height"]}
                                        </Typography>
                                        <Typography className="dt-typo mt-3">
                                          {item.Bmi["Weight"]}
                                        </Typography>
                                        <Typography className="dt-typo mt-3">
                                          {item.Bmi["BMI"]}
                                        </Typography>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Box>
                        </Col>

                        <Col xs={12} md={4}>
                          <Box
                            component={Paper}
                            elevation={2}
                            p={0}
                            className="boxes new-box h-15rem"
                          >
                            <div className="p-2 mt-2">
                              <div className="profile-div">
                                <Typography className="color-code-txt">
                                  Blood Pressure
                                  <span className="float-right">
                                    {!item.BloodPressure["lastDate"] ? (
                                      <PriorityHighIcon
                                        style={{
                                          fontSize: "25px",
                                          color: "#D06666",
                                        }}
                                      />
                                    ) : (
                                      <p>{item.BloodPressure["lastDate"]}</p>
                                    )}
                                  </span>
                                </Typography>
                              </div>

                              <div className="mt-3">
                                <Row className="p-1">
                                  <Col xs={4} md={3}>
                                    <Image
                                      className="icon-sid-web"
                                      src={require("../assets/images/clinicalicon/BloodPressure.png")}
                                    />
                                  </Col>
                                  <Col>
                                    <Row>
                                      <Col xs={9}>
                                        <Typography className="dt-typo mt-1">
                                          Systolic{" "}
                                        </Typography>
                                        <Typography className="dt-typo mt-3">
                                          {" "}
                                          Diastolic{" "}
                                        </Typography>
                                      </Col>
                                      <Col xs={3}>
                                        <Typography className="dt-typo mt-1">
                                          {item.BloodPressure["Systolic"]}
                                        </Typography>
                                        <Typography className="dt-typo mt-3">
                                          {item.BloodPressure["Diastolic"]}
                                        </Typography>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Box>

                          <Box
                            component={Paper}
                            elevation={2}
                            p={0}
                            mt={3}
                            className="boxes new-box h-15rem mt-2"
                          >
                            <div className="p-2 mt-2">
                              <div className="profile-div">
                                <Typography className="color-code-txt">
                                  Cholestrol
                                  <span className="float-right">
                                    {!item.Cholestrol["lastDate"] ? (
                                      <PriorityHighIcon
                                        style={{
                                          fontSize: "25px",
                                          color: "#D06666",
                                        }}
                                      />
                                    ) : (
                                      <p>{item.Cholestrol["lastDate"]}</p>
                                    )}
                                  </span>
                                </Typography>
                              </div>

                              <div className="mt-3">
                                <Row className="p-1">
                                  <Col xs={4} md={3}>
                                    <Image
                                      className="icon-sid-web"
                                      src={require("../assets/images/clinicalicon/Cholestrol.png")}
                                    />
                                  </Col>
                                  <Col>
                                    <Row>
                                      <Col xs={9}>
                                        <Typography className="dt-typo mt-1">
                                          Cholestrol
                                        </Typography>
                                        <Typography className="dt-typo mt-2">
                                          HDL
                                        </Typography>
                                        <Typography className="dt-typo mt-2">
                                          LDL
                                        </Typography>
                                        <Typography className="dt-typo mt-2">
                                          Triglyceride
                                        </Typography>
                                        <Typography className="dt-typo mt-2">
                                          Ratio
                                        </Typography>
                                      </Col>
                                      <Col xs={3}>
                                        <Typography className="dt-typo mt-1">
                                          {item.Cholestrol["Cholestrol"]}
                                        </Typography>
                                        <Typography className="dt-typo mt-2">
                                          {item.Cholestrol["HDL"]}
                                        </Typography>
                                        <Typography className="dt-typo mt-2">
                                          {item.Cholestrol["LDL"]}
                                        </Typography>
                                        <Typography className="dt-typo mt-2">
                                          {item.Cholestrol["Triglyceride"]}
                                        </Typography>
                                        <Typography className="dt-typo mt-2">
                                          {item.Cholestrol["Ratio"]}
                                        </Typography>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Box>
                        </Col>

                        <Col xs={12} md={4}>
                          <Box
                            component={Paper}
                            elevation={2}
                            p={0}
                            className="boxes new-box h-8rem"
                          >
                            <div className="p-2 mt-2">
                              <div className="profile-div">
                                <Typography className="color-code-txt">
                                  Glucose
                                  <span className="float-right">
                                    {!item.ItemNo["lastDate"] ? (
                                      <PriorityHighIcon
                                        style={{
                                          fontSize: "25px",
                                          color: "#D06666",
                                        }}
                                      />
                                    ) : (
                                      <p>
                                        {moment(
                                          item.ItemNo["lastDate"],
                                          "DD-MM-YYYY"
                                        ).format("DD-MM-YYYY")}
                                      </p>
                                    )}
                                  </span>
                                </Typography>
                              </div>

                              <div className="mt-2">
                                <Row className="p-1">
                                  <Col xs={4} md={3}>
                                    <Image
                                      style={{ marginLeft: "8px" }}
                                      className="icon-sid-web"
                                      src={require("../assets/images/clinicalicon/Glucose.png")}
                                    />
                                  </Col>
                                  <Col>
                                    <Row>
                                      <Col xs={9}>
                                        <Typography className="dt-typo mt-3">
                                          Glucose
                                        </Typography>
                                      </Col>
                                      <Col xs={3}>
                                        <Typography className="dt-typo mt-3">
                                          {item.Glucose["Glucose"]}
                                        </Typography>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Box>

                          <Box
                            component={Paper}
                            elevation={2}
                            p={0}
                            mt={3}
                            className="boxes new-box h-8rem mt-2"
                          >
                            <div className="p-2 mt-2">
                              <div className="profile-div">
                                <Typography className="color-code-txt">
                                  HbA1c
                                  <span className="float-right">
                                    {!item.Hba1c["lastDate"] ? (
                                      <PriorityHighIcon
                                        style={{
                                          fontSize: "25px",
                                          color: "#D06666",
                                        }}
                                      />
                                    ) : (
                                      <p>
                                        {moment(
                                          item.Hba1c["lastDate"],
                                          "DD-MM-YYYY"
                                        ).format("DD-MM-YYYY")}
                                      </p>
                                    )}
                                  </span>
                                </Typography>
                              </div>

                              <div className="mt-2">
                                <Row className="p-1">
                                  <Col xs={4} md={3}>
                                    <Image
                                      style={{ marginLeft: "12px" }}
                                      className="icon-sid-web"
                                      src={require("../assets/images/clinicalicon/HbA1c.png")}
                                    />
                                  </Col>

                                  <Col>
                                    <Row>
                                      <Col xs={9}>
                                        <Typography className="dt-typo mt-3">
                                          HbA1c
                                        </Typography>
                                      </Col>
                                      <Col xs={3}>
                                        <Typography className="dt-typo mt-3">
                                          {item.Hba1c["HBA1C"]}
                                        </Typography>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Box>

                          <Box
                            component={Paper}
                            elevation={2}
                            p={0}
                            mt={3}
                            className="boxes new-box h-8rem mt-2"
                          >
                            <div className="p-2 mt-2">
                              <div className="profile-div">
                                <Typography className="color-code-txt">
                                  Smoking
                                </Typography>
                              </div>

                              <div className="mt-2">
                                <Row className="p-3 pt-0 pb-0">
                                  <Col xs={4} md={3}>
                                    <Image
                                      className="icon-sid-web"
                                      src={require("../assets/images/clinicalicon/Smoking.png")}
                                    />
                                  </Col>
                                  <Col xs={9} className="mt-3">
                                    <Row>
                                      <Col xs={4}>
                                        <Typography className="dt-typo">
                                          Smoking
                                        </Typography>
                                      </Col>
                                      <Col xs={8} className="text-right">
                                        <Typography className="dt-typo">
                                          {item.Smoke}
                                        </Typography>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Box>

                          <Box
                            component={Paper}
                            elevation={2}
                            p={0}
                            mt={3}
                            className="boxes new-box h-8rem mt-2"
                          >
                            <div className="p-2 mt-2">
                              <div className="profile-div">
                                <Typography className="color-code-txt">
                                  Alcohol
                                </Typography>
                              </div>

                              <div className="mt-2">
                                <Row className="p-3 pt-0">
                                  <Col xs={4} md={3}>
                                    <Image
                                      className="icon-sid-web"
                                      src={require("../assets/images/clinicalicon/Alcohol.png")}
                                    />
                                  </Col>
                                  <Col>
                                    <Row>
                                      <Col xs={7}>
                                        <Typography className="dt-typo">
                                          Days Per Week
                                        </Typography>
                                        <Typography className="dt-typo mt-2">
                                          Drinks Per Day
                                        </Typography>
                                      </Col>
                                      <Col xs={4}>
                                        <Typography className="dt-typo text-right">
                                          {item.Alcohol["Days Per Week"]}
                                        </Typography>
                                        <Typography className="dt-typo mt-2 text-right">
                                          {item.Alcohol["Drinks Per Day"]}
                                        </Typography>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Box>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>

                <>
                  {(localStorage.getItem("ColorCode") === "#4666AE" ||
                    localStorage.getItem("ColorCode") === "#8CBBF0") && (
                    <Row>
                      <Col md={4}>
                        <Button
                          onClick={handleUpload}
                          variant="contained"
                          color="secondary"
                          disabled={downloading}
                        >
                          Generate GP Management Plan
                        </Button>
                      </Col>
                      <Col md={4}>
                        {pdferror && (
                          <Alert severity="error">
                            Something went wrong please try again.
                          </Alert>
                        )}
                        {success && (
                          <Alert severity="success">
                            GP Management Report generated successfully.
                          </Alert>
                        )}
                      </Col>
                    </Row>
                  )}
                </>
              </Container>
            </div>
          ) : (
            <div className="ddetails-mobile-view coddgp_mobile_view">
              <Row>
                {data.map((item: any, idx: number) => (
                  <Col md={12} xs={12} className="mx-auto12 pad-clinic-box">
                    <Box
                      className="date_box"
                      component={Paper}
                      elevation={2}
                      p={2}
                      sx={{
                        backgroundColor: "primary.main",
                      }}
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 12,
                        paddingRight: 12,
                      }}
                    >
                      <Row>
                        <div className="col-10 pd-0">
                          <Typography className="d-flex text-center justify-content-center username">
                            <p className="font-15">
                              {" "}
                              {searchRedux.user_name}&nbsp;({ageOfDob})
                            </p>
                            {/* <b>David Thomas 12/01/1978 44 Years Old</b> */}
                          </Typography>
                        </div>
                        <div className="col-1">
                          <Image
                            className="Closeicon"
                            width={30}
                            src={require("../assets/images/Closeicon.png")}
                            onClick={() => {
                              navigate("/sidebar");
                            }}
                          />
                        </div>
                      </Row>
                    </Box>

                    <Row>
                      <Col xs={3} md={4} className="pd-r-0">
                        <Button
                          onClick={handleClick}
                          sx={{ p: 1.5, mt: 3 }}
                          fullWidth
                          variant="contained"
                          color="secondary"
                          className="font-12 callb mt-2"
                          // className="callb mt-2"
                          startIcon={<LocalPhoneIcon />}
                        >
                          Call
                        </Button>
                        <Popover
                          id={id2}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                        >
                          <Typography sx={{ p: 2 }}>Copied</Typography>
                        </Popover>
                      </Col>

                      <Col xs={3} md={4} className="pd-r-0">
                        <Button
                          onClick={() => {
                            dispatch(
                              UpdatePaymentData(
                                "user_phone_no",
                                item.CallMobile
                              )
                            );
                            navigate("/sms");
                          }}
                          sx={{ p: 1.5, mt: 3 }}
                          fullWidth
                          variant="contained"
                          color="secondary"
                          className="font-12 smsb mt-2"
                          startIcon={<SmsIcon />}
                        >
                          SMS
                        </Button>
                      </Col>

                      <Col xs={6} md={4}>
                        <Button
                          className="dic_notes"
                          // onClick={() => navigate("/dictatenotes/" + id)}
                          //  onClick={() =>{
                          //   setDicLoading(true)
                          //   setTimeout(() => {
                          //     setDicLoading(false)
                          //   }, 300);

                          //   window.location.href ="https://dictation.coddapps.com.au/sidebarworking.php?p=" +searchRedux.user_name+"&age="+ageOfDob+"&u="+base64_encode(item.ListId)}
                          // }

                          // onClick={() => window.open('https://coddgpphp.coddapps.com.au/sidebarworking.php?p='+searchRedux.user_name+'&age='+ageOfDob, '_blank')}
                          onClick={() => {
                            setDicLoading(true);

                            // window.location.href ="https://dictation.coddapps.com.au/sidebarworking.php?p=" +searchRedux.user_name+"&age="+ageOfDob+"&u="+base64_encode(item.ListId)+"&link="+Currentpage}
                            window.location.href =
                              "https://dictation.coddapps.com.au/sidebarworking.php?p=" +
                              searchRedux.user_name +
                              "&age=" +
                              ageOfDob +
                              "&u=&param=" +
                              searchRedux.PntId +
                              "_" +
                              searchRedux.location_id +
                              "_" +
                              searchRedux.userid +
                              "_" +
                              moment(searchRedux.selecteddate).format(
                                "YYYY-MM-DD"
                              ) +
                              base64_encode(item.ListId) +
                              "&link=" +
                              Currentpage;
                          }}
                          sx={{ p: 1.4, mt: 1 }}
                          fullWidth
                          variant="contained"
                          disabled={dicloading}
                          color="secondary"
                          startIcon={!dicloading && <EditNoteIcon />}
                        >
                          {dicloading ? (
                            <CircularProgress
                              style={{
                                color: "#FFF",
                              }}
                              size={14}
                            />
                          ) : (
                            "Dictate Notes"
                          )}
                        </Button>
                      </Col>
                    </Row>

                    {/* Details box design new */}
                    <div>
                      <Box
                        component={Paper}
                        elevation={2}
                        p={0}
                        mt={1}
                        className="boxes"
                      >
                        <div className="p-2 mt-2">
                          <div className="profile-div">
                            <Typography className="color-code-txt">
                              <b>CDM</b>
                              <span className="float-right">
                                {!item.ItemNo["lastDate"] ? (
                                  <PriorityHighIcon
                                    style={{
                                      fontSize: "25px",
                                      color: "#D06666",
                                    }}
                                  />
                                ) : (
                                  <span>{item.ItemNo["lastDate"]}</span>
                                )}
                              </span>
                            </Typography>
                          </div>

                          <div className="mt-3">
                            <Row className="kpi-p-2 pt-0">
                              <Col xs={2} className="pl-0 cdm_image img-c">
                                <Image
                                  style={{ marginLeft: "15px" }}
                                  src={require("../assets/images/clinicalicon/CDM.png")}
                                  className="icon-lable"
                                />
                              </Col>
                              <Col xs={5} className="pd-0 text-center">
                                <Typography className="font-15">
                                  701
                                  {item.ItemNo["701"]
                                    ? " - " +
                                      moment(
                                        item.ItemNo["701"],
                                        "DD-MM-YYYY"
                                      ).format("DD-MM-YYYY")
                                    : "  _________"}
                                </Typography>
                                <Typography className="mt-3 font-15">
                                  703
                                  {item.ItemNo["703"]
                                    ? " - " +
                                      moment(
                                        item.ItemNo["703"],
                                        "DD-MM-YYYY"
                                      ).format("DD-MM-YYYY")
                                    : "  _________"}
                                </Typography>
                                <Typography className="mt-3 font-15">
                                  705
                                  {item.ItemNo["705"]
                                    ? " - " +
                                      moment(
                                        item.ItemNo["705"],
                                        "DD-MM-YYYY"
                                      ).format("DD-MM-YYYY")
                                    : "  _________"}
                                </Typography>
                              </Col>
                              <Col xs={5} className="pd-0 text-left">
                                <Typography className="font-15">
                                  721
                                  {item.ItemNo["721"]
                                    ? " - " +
                                      moment(
                                        item.ItemNo["721"],
                                        "DD-MM-YYYY"
                                      ).format("DD-MM-YYYY")
                                    : "  _________"}
                                </Typography>
                                <Typography className="mt-3 font-15">
                                  723
                                  {item.ItemNo["723"]
                                    ? " - " +
                                      moment(
                                        item.ItemNo["723"],
                                        "DD-MM-YYYY"
                                      ).format("DD-MM-YYYY")
                                    : "  _________"}
                                </Typography>
                                <Typography className="mt-3 font-15">
                                  732
                                  {item.ItemNo["732"]
                                    ? " - " +
                                      moment(
                                        item.ItemNo["732"],
                                        "DD-MM-YYYY"
                                      ).format("DD-MM-YYYY")
                                    : "  _________"}
                                </Typography>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Box>

                      <Box
                        component={Paper}
                        elevation={2}
                        p={0}
                        mt={1}
                        className="boxes"
                      >
                        <div className="p-2 mt-2">
                          <div className="profile-div">
                            <Typography className="color-code-txt">
                              <b>BMI</b>
                              <span className="float-right">
                                {!item.Bmi["lastDate"] ? (
                                  <PriorityHighIcon
                                    style={{
                                      fontSize: "25px",
                                      color: "#D06666",
                                    }}
                                  />
                                ) : (
                                  <span>{item.Bmi["lastDate"]}</span>
                                )}
                              </span>
                            </Typography>
                          </div>

                          <div className="mt-0">
                            <Row className="kpi-p-2 pb-0">
                              <Col xs={3} className="pl-0 img-c">
                                <Image
                                  src={require("../assets/images/clinicalicon/BMI.png")}
                                  className="icon-lable"
                                />
                              </Col>
                              <Col xs={9}>
                                <Row>
                                  <Col xs={9}>
                                    <Typography className="font-15">
                                      Height
                                    </Typography>
                                    <Typography className="mt-2 font-15">
                                      Weight{" "}
                                    </Typography>
                                    <Typography className="mt-2 font-15">
                                      BMI{" "}
                                    </Typography>
                                  </Col>
                                  <Col xs={3} className="text-right">
                                    <Typography className="font-15">
                                      {item.Bmi["Height"]}
                                    </Typography>
                                    <Typography className="mt-2 font-15">
                                      {item.Bmi["Weight"]}
                                    </Typography>
                                    <Typography className="mt-2 font-15">
                                      {item.Bmi["BMI"]}
                                    </Typography>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Box>

                      <Box
                        component={Paper}
                        elevation={2}
                        p={0}
                        mt={1}
                        className="boxes"
                      >
                        <div className="p-2 mt-2">
                          <div className="profile-div">
                            <Typography className="color-code-txt">
                              <b>Blood Pressure</b>
                              <span className="float-right">
                                {!item.BloodPressure["lastDate"] ? (
                                  <PriorityHighIcon
                                    style={{
                                      fontSize: "25px",
                                      color: "#D06666",
                                    }}
                                  />
                                ) : (
                                  <span>{item.BloodPressure["lastDate"]}</span>
                                )}
                              </span>
                            </Typography>
                          </div>

                          <div className="mt-0">
                            <Row className="kpi-p-2 pb-0">
                              <Col xs={3} className="pl-0 img-c">
                                <Image
                                  src={require("../assets/images/clinicalicon/BloodPressure.png")}
                                  className="icon-lable"
                                />
                              </Col>
                              <Col xs={9}>
                                <Row>
                                  <Col xs={9}>
                                    <Typography className="font-15">
                                      Systolic
                                    </Typography>
                                    <Typography className="mt-3 font-15">
                                      {" "}
                                      Diastolic
                                    </Typography>
                                  </Col>
                                  <Col xs={3} className="text-right">
                                    <Typography className="font-15">
                                      {item.BloodPressure["Systolic"]}
                                    </Typography>
                                    <Typography className="mt-3 font-15">
                                      {item.BloodPressure["Diastolic"]}
                                    </Typography>
                                  </Col>
                                </Row>

                                {/* <Typography>701_______</Typography>
                      <Typography>701_______</Typography>
                      <Typography>701_______</Typography> */}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Box>

                      <Box
                        component={Paper}
                        elevation={2}
                        p={0}
                        mt={1}
                        className="boxes"
                      >
                        <div className="p-2 mt-2">
                          <div className="profile-div">
                            <Typography className="color-code-txt">
                              <b>Cholestrol</b>
                              <span className="float-right">
                                {!item.Cholestrol["lastDate"] ? (
                                  <PriorityHighIcon
                                    style={{
                                      fontSize: "25px",
                                      color: "#D06666",
                                    }}
                                  />
                                ) : (
                                  <span>{item.Cholestrol["lastDate"]}</span>
                                )}
                              </span>
                            </Typography>
                          </div>

                          <div className="mt-2">
                            <Row className="kpi-p-2 pd-0">
                              <Col xs={3} className="pl-0 img-c">
                                <Image
                                  style={{
                                    marginTop: "10px",
                                    marginLeft: "20px",
                                  }}
                                  src={require("../assets/images/clinicalicon/Cholestrol.png")}
                                  className="icon-lable"
                                />
                              </Col>
                              <Col xs={9}>
                                <Row>
                                  <Col xs={9}>
                                    <Typography className="font-15">
                                      Cholestrol
                                    </Typography>
                                    <Typography className="mt-2 font-15">
                                      HDL
                                    </Typography>
                                    <Typography className="mt-2 font-15">
                                      LDL
                                    </Typography>
                                    <Typography className="mt-2 font-15">
                                      Triglyceride
                                    </Typography>
                                    <Typography className="mt-2 font-15">
                                      Ratio
                                    </Typography>
                                  </Col>
                                  <Col xs={3} className="text-right">
                                    <Typography className="font-15">
                                      {item.Cholestrol["Cholestrol"]}
                                    </Typography>
                                    <Typography className="mt-2 font-15">
                                      {item.Cholestrol["HDL"]}
                                    </Typography>
                                    <Typography className="mt-2 font-15">
                                      {item.Cholestrol["LDL"]}
                                    </Typography>
                                    <Typography className="mt-2 font-15">
                                      {item.Cholestrol["Triglyceride"]}
                                    </Typography>
                                    <Typography className="mt-2 font-15">
                                      {item.Cholestrol["Ratio"]}
                                    </Typography>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Box>

                      <Box
                        component={Paper}
                        elevation={2}
                        p={0}
                        mt={1}
                        className="boxes"
                      >
                        <div className="p-2 mt-2">
                          <div className="profile-div">
                            <Typography className="color-code-txt">
                              <b>Glucose</b>
                              <span className="float-right">
                                {!item.Glucose["lastDate"] ? (
                                  <PriorityHighIcon
                                    style={{
                                      fontSize: "25px",
                                      color: "#D06666",
                                    }}
                                  />
                                ) : (
                                  <span>{item.Glucose["lastDate"]}</span>
                                )}
                              </span>
                            </Typography>
                          </div>

                          <div className="mt-0">
                            <Row className="kpi-p-2 pb-0">
                              <Col xs={3} className="pl-0 img-c">
                                <Image
                                  src={require("../assets/images/clinicalicon/Glucose.png")}
                                  className="icon-lable"
                                />
                              </Col>
                              <Col xs={9}>
                                <Row className="mt-2">
                                  <Col xs={9}>
                                    <Typography className="font-15">
                                      Glucose
                                    </Typography>
                                  </Col>
                                  <Col xs={3} className="text-right">
                                    <Typography className="font-15">
                                      {item.Glucose["Glucose"]}
                                    </Typography>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Box>

                      <Box
                        component={Paper}
                        elevation={2}
                        p={0}
                        mt={1}
                        className="boxes"
                      >
                        <div className="kpi-p-2 mt-2">
                          <div className="profile-div">
                            <Typography className="color-code-txt font-15">
                              <b>HbA1c</b>
                              <span className="float-right">
                                {!item.Hba1c["lastDate"] ? (
                                  <PriorityHighIcon
                                    style={{
                                      fontSize: "25px",
                                      color: "#D06666",
                                    }}
                                  />
                                ) : (
                                  <p>
                                    {moment(
                                      item.Hba1c["lastDate"],
                                      "DD-MM-YYYY"
                                    ).format("DD-MM-YYYY")}
                                  </p>
                                )}
                              </span>
                            </Typography>
                          </div>

                          <div className="mt-0">
                            <Row className="p-2 pb-0">
                              <Col xs={3} className="pl-0 img-c">
                                <Image
                                  src={require("../assets/images/clinicalicon/HbA1c.png")}
                                  className="icon-lable"
                                />
                              </Col>
                              <Col xs={9}>
                                <Row className="mt-2">
                                  <Col xs={9}>
                                    <Typography className="font-15">
                                      HbA1c
                                    </Typography>
                                  </Col>
                                  <Col xs={3} className="text-right">
                                    <Typography className="font-15">
                                      {item.Hba1c["HBA1C"]}
                                    </Typography>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Box>

                      <Box
                        component={Paper}
                        elevation={2}
                        p={0}
                        mt={1}
                        className="boxes"
                      >
                        <div className="kpi-p-2 mt-2">
                          <div className="profile-div">
                            <Typography className="color-code-txt font-15">
                              <b>Smoking</b>
                            </Typography>
                          </div>

                          <div className="mt-0">
                            <Row className="p-3 pb-0">
                              <Col xs={3} className="pl-0 img-c">
                                <Image
                                  src={require("../assets/images/clinicalicon/Smoking.png")}
                                  className="icon-lable"
                                />
                              </Col>
                              <Col xs={9}>
                                <Row className="mt-2">
                                  <Col xs={4}>
                                    <Typography className="font-15">
                                      Smoking
                                    </Typography>
                                  </Col>
                                  <Col xs={8} className="text-right">
                                    <Typography className="text-right font-15">
                                      {item.Smoke}
                                    </Typography>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Box>

                      <Box
                        component={Paper}
                        elevation={2}
                        p={0}
                        mt={1}
                        className="boxes"
                      >
                        <div className="kpi-p-2 mt-2">
                          <div className="profile-div">
                            <Typography className="color-code-txt font-15">
                              <b>Alcohol</b>
                            </Typography>
                          </div>

                          <div className="mt-0">
                            <Row className="p-3  pb-0">
                              <Col xs={3} className="pl-0 img-c">
                                <Image
                                  src={require("../assets/images/clinicalicon/Alcohol.png")}
                                  className="icon-lable"
                                />
                              </Col>
                              <Col xs={9}>
                                <Row>
                                  <Col xs={9}>
                                    <Typography className="font-15 mb-2">
                                      Days Per Week
                                    </Typography>
                                    <Typography className="font-15">
                                      Drinks Per Day
                                    </Typography>
                                  </Col>
                                  <Col xs={3} className="text-right">
                                    <Typography className="font-15 mb-2">
                                      {item.Alcohol["Days Per Week"]}
                                    </Typography>
                                    <Typography className="font-15">
                                      {item.Alcohol["Drinks Per Day"]}
                                    </Typography>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Box>
                    </div>
                  </Col>
                ))}
              </Row>

              <BrowserView>
                {(localStorage.getItem("ColorCode") === "#4666AE" ||
                  localStorage.getItem("ColorCode") === "#8CBBF0") && (
                  <Row>
                    <Col md={12}>
                      <Button
                        onClick={handleUpload}
                        color="secondary"
                        disabled={downloading}
                        variant="contained"
                        style={{ margin: 10 }}
                      >
                        Generate GP Management Plan
                      </Button>
                    </Col>
                    <Col md={12}>
                      {pdferror && (
                        <Alert severity="error">
                          Something went wrong please try again.
                        </Alert>
                      )}
                      {success && (
                        <Alert severity="success">
                          GP Management Report generated successfully.
                        </Alert>
                      )}
                    </Col>
                  </Row>
                )}
              </BrowserView>
            </div>
          )}
        </Fragment>
      )}
    </>
  );
};
