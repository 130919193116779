import {
    AppBar,
    Box,
    Container,
    FormControl,
    OutlinedInput,
    Paper,
    Toolbar,
    Typography,
    Button,
    CircularProgress,
  } from "@mui/material";
  import Grid from "@mui/material/Grid";
  import { getApi } from "../services";
  import { blueGrey } from "@mui/material/colors";
  import React, { Fragment, useEffect, useState } from "react";
  import { Col, Row, Table } from "react-bootstrap";
  import { useNavigate } from "react-router-dom";
  import { PaymentState as SearchReduxState, useSelector } from "../redux";
  import { useDispatch } from "react-redux";
  import { UpdatePaymentData } from "../redux/User/actions";
  import moment from "moment";
  
  import useMediaQuery from "@mui/material/useMediaQuery";
  import { useTheme } from "@mui/material/styles";
  import "bootstrap/dist/css/bootstrap.min.css";
  
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { DatePicker } from "@mui/x-date-pickers/DatePicker";
  import dayjs, { Dayjs } from "dayjs";
  
  export const WaitingTime: React.FC = () => {
    // Redux
    const searchRedux = useSelector<SearchReduxState>(
      (state) => state.paymentReducer
    );
  
    /* new calander */
    const [fromdate, setfromdate] = React.useState(moment().format("YYYY-MM-DD"));
    const [nowDate, setnowDate] = useState(moment().format("YYYY-MM-DD"));
    const dispatch = useDispatch();
    const [data, setdata] = useState([]);
    const navigate = useNavigate();
    const getapi = getApi();
    // const [fromdate, setfromdate] = useState("");
    const [todate, settodate] = useState("");
    const [openCalendarS, setOpenCalendarS] = useState(false);
    const [openCalendarE, setOpenCalendarE] = useState(false);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const [loading, setLoading] = useState(true);
  
    const [selectedDate, setSelectedDate] = useState(moment());
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
  
    const handleDateChange = (date: any) => {
      setSelectedDate(date);
      setOpen(false); // Close the DatePicker after selecting a date
    };
  
    const toggleDatePicker = () => {
      setOpen(!open); // Toggle the DatePicker open/close state
    };
  
    const handleInputClickS = () => {
      setOpenCalendarS(true);
    };
    const handleCalendarCloseS = () => {
      setOpenCalendarS(false);
    };
  
    const handleInputClickE = () => {
      setOpenCalendarE(true);
    };
    const handleCalendarCloseE = () => {
      setOpenCalendarE(false);
    };
  
    useEffect(() => {
      getData();
    }, [searchRedux.FromDate, searchRedux.ToDate]);
  
    const getData = () => {
      setLoading(true);
      let data = {
        userlocid: searchRedux.location_id,
        codd_fdate: moment(searchRedux.FromDate.toString()).format("YYYY-MM-DD")
          ? moment(searchRedux.FromDate.toString()).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
  
        codd_tdate: moment(searchRedux.ToDate.toString()).format("YYYY-MM-DD")
          ? moment(searchRedux.ToDate.toString()).format("YYYY-MM-DD")
          : moment().add("5", "days").format("YYYY-MM-DD"),
        codd_duserid: searchRedux.userid,
      };
  
      getapi
        .post("halodoctorwaiting/", data)
        .then((response) => {
          setLoading(false);
          //console.log("response data");
          //console.log(data);
          //console.log(JSON.stringify(response.data.data));
          setdata(response.data.data);
        })
        .catch((error) => {
          setLoading(false);
          //console.log(error);
        });
    };
  
    return (
      <Fragment>
        {!matches ? (
          <div>
            <Container>
              <Row>
                <Col md={10} className="mx-auto">
                  <Box
                    className="date_box"
                    component={Paper}
                    elevation={2}
                    p={2}
                    sx={{
                      backgroundColor: "primary.main",
                    }}
                  >
                    <Row>
                      <Col md={4} className="mx-auto justify-content-center">
                        <div className="text-center align-item-center mb-2">
                          From Date
                        </div>
                        <div
                          className="text-center align-item-center"
                          //onClick={toggleDatePicker}
                        >
                          <DatePicker
                            maxDate={moment(nowDate)}
                            closeOnSelect={true}
                            format="DD/MM/YYYY"
                            //open={open}
                            // onClose={() => setOpen(false)}
                            sx={{ borderRadius: "15px", fontWeight: 700 }}
                            className="align-item-center sidebar_datebicker d-flex text-center br-20"
                            //label="Controlled picker"
                            value={
                              moment(searchRedux.FromDate.toString())
                                ? moment(searchRedux.FromDate.toString())
                                : moment(fromdate)
                            }
                            onChange={(newValue: any) => {
                              dispatch(UpdatePaymentData("FromDate", newValue));
                              handleCalendarCloseS();
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4} className="mx-auto justify-content-center">
                        <div className="text-center align-item-center mb-2">
                          To Date
                        </div>
                        <div
                          className="text-center align-item-center"
                          // onClick={()=> setOpen2(!open2)}
                        >
                          <DatePicker
                            minDate={
                              moment(searchRedux.FromDate.toString())
                                ? moment(searchRedux.FromDate.toString())
                                : moment().format("YYYY-MM-DD")
                            }
                            maxDate={moment(nowDate)}
                            closeOnSelect={true}
                            format="DD/MM/YYYY"
                            //  open={open2}
                            //  onClose={() => setOpen2(false)}
                            sx={{ borderRadius: "15px" }}
                            className="align-item-center sidebar_datebicker d-flex text-center br-20"
                            //label="Controlled picker"
                            value={
                              moment(searchRedux.ToDate.toString())
                                ? moment(searchRedux.ToDate.toString())
                                : moment().format("YYYY-MM-DD")
                            }
                            onChange={(newValue: any) => {
                              dispatch(UpdatePaymentData("ToDate", newValue));
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Box>
  
                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "75vh",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <div className="kpi-mt-4">
                      {data.map((item: any, idx: number) => (
                        <Box
                          component={Paper}
                          elevation={2}
                          p={6}
                          mt={3}
                          className="boxes"
                        >
                          <div className="p-3">
                            <Box sx={{ flexGrow: 1 }}>
                              <Row>
                                <Col></Col>
                                <Col sm={11}>
                                  <Row>
                                    <Col
                                      md={8}
                                      className="mx-auto justify-content-center followup-div"
                                    >
                                      <div className="align-item-left followup-value">
                                      Waiting Time
                                      </div>
                                    </Col>
                                    <Col
                                      md={3}
                                      className="mx-auto justify-content-center followup-div2"
                                    >
                                      <div className="text-end align-item-center followup-value">
                                        {/* {item.Booked} */}
                                         {item.WaitingTime} 
                                      </div>
                                    </Col>
                                  </Row>
                                 
                                </Col>
                                <Col></Col>
                              </Row>
                            </Box>
                          </div>
                        </Box>
                      ))}
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          <div className="follow_up_mobile coddgp_mobile_view mt-2">
            <Row>
              <Col md={10} className="mx-auto kpi-pad">
                <Box
                  className="date_box"
                  component={Paper}
                  elevation={2}
                  p={2}
                  sx={{
                    backgroundColor: "primary.main",
                  }}
                >
                  <Row>
                    <Col xs={6}>
                      <div className="text-center align-item-center mb-2">
                        From Date
                      </div>
                      <div>
                        <DatePicker
                          maxDate={moment(nowDate)}
                          format="DD/MM/YYYY"
                          closeOnSelect={true}
                          sx={{ borderRadius: "15px", fontWeight: 700 }}
                          className="align-item-center sidebar_datebicker d-flex text-center br-20"
                          //label="Controlled picker"
                          value={
                            moment(searchRedux.FromDate.toString())
                              ? moment(searchRedux.FromDate.toString())
                              : moment(fromdate)
                          }
                          onChange={(newValue: any) => {
                            dispatch(UpdatePaymentData("FromDate", newValue));
                          }}
                        />
                      </div>
                    </Col>
  
                    <Col xs={6}>
                      <div className="text-center align-item-center mb-2">
                        To Date
                      </div>
                      <div>
                        <DatePicker
                          minDate={
                            moment(searchRedux.FromDate.toString())
                              ? moment(searchRedux.FromDate.toString())
                              : moment().format("YYYY-MM-DD")
                          }
                          maxDate={moment(nowDate)}
                          format="DD/MM/YYYY"
                          closeOnSelect={true}
                          sx={{ borderRadius: "15px" }}
                          className="align-item-center sidebar_datebicker d-flex text-center br-20"
                          //label="Controlled picker"
                          value={
                            moment(searchRedux.ToDate.toString())
                              ? moment(searchRedux.ToDate.toString())
                              : moment().add("5", "days")
                          }
                          onChange={(newValue: any) => {
                            dispatch(UpdatePaymentData("ToDate", newValue));
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Box>
  
                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "75vh",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    {data.map((item: any, idx: number) => (
                      <Box
                        component={Paper}
                        elevation={2}
                        p={1}
                        mt={3}
                        className="boxes mt-3"
                      >
                        <div className="p-3">
                          <Box sx={{ flexGrow: 1 }}>
                            <Row>
                              <Col sm={11} className="mx-auto">
                                <Row>
                                  <Col
                                    xs={3}
                                    className="mx-auto  justify-content-center followup-div"
                                  >
                                    <div className="align-item-left followup-value">
                                    Waiting Time
                                    </div>
                                  </Col>
                                  <Col
                                    xs={3}
                                    className="mx-auto d-flex justify-content-center followup-div2"
                                  >
                                    <div className="text-end align-item-center followup-value">
                                      {item.WaitingTime}
                                    </div>
                                  </Col>
                                </Row>
                             
                              </Col>
                            </Row>
                          </Box>
                        </div>
                      </Box>
                    ))}
                  </>
                )}
              </Col>
            </Row>
          </div>
        )}
      </Fragment>
    );
  };
  