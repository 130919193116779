import {
  Box,
  Container,
  Paper,
  Typography,
  Button,
  Alert,
  AlertTitle,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Col, FormLabel, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getApi } from "../services";
import { PaymentState as SearchReduxState, useSelector } from "../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../redux/User/actions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";

export const OTPVerification: React.FC = () => {
  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const {name,password} = useParams()
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [nameerror, seterror1] = useState(false);
  const [passerror, setpasserror] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const getapi = getApi();

  const [responseData, setResponseData] = useState(null);

  const [otpverify, setOTPVerify] = useState("");
  const [otpsuccess, setOTPsuccess] = useState("");
  const [otpSuccessAlert, setOtpSuccessAlert] = useState(false);
  const [otpErrorAlert, setOtpErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");


    /* device checking function */

const [loginfrom,setLoginFrom] = useState('')
function isMobileDevice() {
  // Check if the user agent contains 'Mobi', 'Android', or 'iPhone'
  // and also check if it's not running in an MSI environment
  return /Mobi|Android|iPhone/i.test(navigator.userAgent) && !/msi/i.test(navigator.userAgent);
}
function isMSI() {
  // Check if the user agent contains 'msi'
  return /msi/i.test(navigator.userAgent);
}

function isDesktop() {
  // Check if the platform is not 'iPhone', 'iPad', 'iPod', 'Android', or 'Mobile'
  return !/(iPhone|iPad|iPod|Android|Mobile)/i.test(navigator.userAgent);
}



  const handleOTPVerificationCode = async () => {
    var loginfrom = "";
    if (isMobileDevice()) {
    //  console.log("This is a mobile device");
      loginfrom = "Mobile"
    }
    else if (isMSI()){
    //  console.log("This app is running in an MSI environment.");
      loginfrom = "MSI"
     }
    else if (isDesktop()){
     // console.log("This website is running on a desktop.");
      loginfrom ="Desktop"
   
    }
    else{
    //  console.log("This website is running on a other.");
    }

    try {
      let data = {
        otp: otpverify,
        login_from:loginfrom  /* device checking */
      };


      const response = await getapi.post("halootpverify/", data);
      //console.log(response);

      await dispatch(UpdatePaymentData("otp", response.data.otp));
      //console.log(response.data.otp);

      if (response.data.Success) {
        await dispatch(UpdatePaymentData("otp", response.data.otp));
        //console.log("OTP successfully verified:", response.data.otp);
        setOtpSuccessAlert(true); // Show success alert
        setOtpErrorAlert(false); // Hide error alert
        navigate("/");
      } else {
        console.error("OTP verification failed:", response.data.ErrorMessage);
        setErrorMessage(response.data.ErrorMessage);
        setOtpSuccessAlert(false); // Hide success alert
        setOtpErrorAlert(true); // Show error alert
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("The OTP entered is incorrect.");
      setOtpSuccessAlert(false); // Hide success alert
      setOtpErrorAlert(true); // Show error alert
    }
  };

  const handleTwoFactor = () => {
    navigate("/");
  };
  const handleRecoverAccount = () => {
    navigate("/recover-account");
  }; 

  return (
    <Fragment>
      {!matches ? (
        <div className="recover-web-view">
          <Container>
            <Row>
              <Col md={10} className="mx-auto">
                <Box
                  component={Paper}
                  elevation={2}
                  p={0}
                  mt={0}
                  className="boxes"
                >
                  <div className="login-div">
                    <Row>
                      <Col md={6}>
                        <img
                          src={require("../assets/images/Maskgroupweb.png")}
                          alt="Logo"
                          className="img-fluid"
                        />
                      </Col>
                      <Col md={6}>
                        <div className="p-5 mt-5">
                          <div>
                            <Typography className="recover-txt">
                              OTP Verification
                            </Typography>
                          </div>
                          <div className="mt-2">
                            {/* Success Alert */}
                            {otpSuccessAlert && (
                              <Alert severity="success">
                                <AlertTitle>Success</AlertTitle>
                                OTP successfully verified!
                              </Alert>
                            )}

                            {/* Error Alert */}
                            {otpErrorAlert && (
                              <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                {errorMessage}
                              </Alert>
                            )}
                          </div>
                          <div>
                            <Form>
                              <Form.Group
                                className="mt-4 text-center "
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  value={otpverify}
                                  onChange={(e) => setOTPVerify(e.target.value)}
                                  className="input-phone"
                                  type="number"
                                  placeholder="Enter OTP"
                                />
                              </Form.Group>
                            </Form>
                          </div>
                          <div>
                            <Button
                              className="send-btn mt-4"
                              onClick={handleOTPVerificationCode}
                            >
                              Verify
                            </Button>
                          </div>
                          <div className="text-center">
                            <Button
                              className="login-txt mt-4 text-center"
                              onClick={handleRecoverAccount}
                            >
                              Change Number
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Box>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div className="login-mobile-view loginbanner">
          <Container>
            <Row>
              <Col md={10} className="mx-auto">
                <Box
                  component={Paper}
                  elevation={2}
                  p={0}
                  mt={0}
                  className="boxes gray_bg"
                >
                  <div className="login-div">
                    <Row>
                      <Col md={6}>
                        <img
                          src={require("../assets/images/Maskgroupweb.png")}
                          alt="Logo"
                          className="img-fluid custom-banner"
                        />
                      </Col>
                      <Col md={6}>
                        <div className="p-5">
                          <div>
                            <Typography
                              variant="h5"
                              className="text-center first-text"
                            >
                              OTP Verification
                            </Typography>
                          </div>
                          <div className="mt-2">
                            {/* Success Alert */}
                            {otpSuccessAlert && (
                              <Alert severity="success">
                                <AlertTitle>Success</AlertTitle>
                                OTP successfully verified!
                              </Alert>
                            )}

                            {/* Error Alert */}
                            {otpErrorAlert && (
                              <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                {errorMessage}
                              </Alert>
                            )}
                          </div>
                          <div>
                            <Form>
                              <Form.Group
                                className="mt-4 text-center "
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  value={otpverify}
                                  onChange={(e) =>{ 
                                    setOTPVerify(e.target.value);
                                    setOtpErrorAlert(false)
                                  }}
                                  className="input-phone"
                                  type="number"
                                  placeholder="Enter OTP"
                                />
                              </Form.Group>
                            </Form>
                          </div>
                          <div>
                            <Button
                              color="secondary"
                              variant="contained"
                              fullWidth
                              className="login-login-btn mt-4"
                              onClick={handleOTPVerificationCode}
                            >
                              Verify
                            </Button>
                          </div>
                          <div className="text-center">
                            <Button
                              className="login-txt  mt-4 text-center"
                              onClick={handleRecoverAccount}
                            >
                              Change Number
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Box>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </Fragment>
  );
};
