import React, { useState, useMemo, useEffect } from 'react';
import axios from 'axios';
import 'get-random-values';
import getRandomValues from 'get-random-values';
import Cookie from 'universal-cookie';

export async function getTokenOrRefresh() {
    const cookie = new Cookie();
    const speechToken = cookie.get('speech-token');
    const SPEECH_REGION = 'eastus'

    if (speechToken === undefined) {  
        try { 
            let data = {
                'speechKey': 'ebfdc305a8954acb85283785ed9ce80e',
                'Region':'eastus'

            }
            const headers = { 
                headers: {
                    'Ocp-Apim-Subscription-Key': 'ebfdc305a8954acb85283785ed9ce80e',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            };

            
            const res = await axios.post('https://eastus.api.cognitive.microsoft.com/sts/v1.0/issuetoken', null, headers);
           // //console.log("response=>::",res)
            const token = res.data;
            const region = SPEECH_REGION;
            cookie.set('speech-token', region + ':' + token, {maxAge: 540, path: '/'}); 

           // //console.log('Token fetched from back-end: ' + token);
            return { authToken: token, region: region };
        } catch (err) {
            //console.log('err.response.data=>',err.response);
            //console.log('bala test:',err);
            return { authToken: null, error: err.response };
        }
    } else {
      //  //console.log('Token fetched from cookie: ' + speechToken);
        const idx = speechToken.indexOf(':');
        return { authToken: speechToken.slice(idx + 1), region: speechToken.slice(0, idx) };
    }
}  