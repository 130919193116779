import {
    Box,
    Container,
    Paper,
    Typography,
    CircularProgress,
  } from "@mui/material";
  import React, { Fragment, useEffect, useRef, useState } from "react";
  import { Card, Col, Row, Table,Image } from "react-bootstrap";
  import { useNavigate } from "react-router-dom";
  import { getApi } from "../services";
  import moment from "moment";
  import { PaymentState as SearchReduxState, useSelector } from "../redux";
  import { useDispatch } from "react-redux";
  import { UpdatePaymentData } from "../redux/User/actions";
  import useMediaQuery from "@mui/material/useMediaQuery";
  import { useTheme } from "@mui/material/styles";
  import "bootstrap/dist/css/bootstrap.min.css";
  
  import { DatePicker } from "@mui/x-date-pickers/DatePicker";
  
  export const PaymentsClone: React.FC = () => {
    const initialized = useRef(false);
    
    // Redux
    const searchRedux = useSelector<SearchReduxState>(
      (state) => state.paymentReducer
    );
  
    /* new calander */
    const [fromdate, setfromdate] = React.useState(moment().format("YYYY-MM-DD"));
    const [nowDate, setnowDate] = useState(moment().format("YYYY-MM-DD"));
    const dispatch = useDispatch();
    const [data, setdata] = useState([]);
    const navigate = useNavigate();
    const getapi = getApi();
    // const [fromdate, setfromdate] = useState("");
    const [todate, settodate] = useState("");
    const [openCalendarS, setOpenCalendarS] = useState(false);
    const [openCalendarE, setOpenCalendarE] = useState(false);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
  
    const [loading, setLoading] = useState(true);
  
    const [selectedDate, setSelectedDate] = useState(moment());
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const handleDateChange = (date:any) => {
      setSelectedDate(date);
      setOpen(false); // Close the DatePicker after selecting a date
    };
  
    const toggleDatePicker = () => {
      setOpen(!open); // Toggle the DatePicker open/close state
    };
  
    // useEffect(() => {
    //   getData();
    // }, []);
  
    const getData = () => {
      setLoading(true);
      let data = {
        userlocid: searchRedux.location_id,
        codd_fdate: moment(searchRedux.FromDate.toString()).format("YYYY-MM-DD")
          ? moment(searchRedux.FromDate.toString()).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
  
        codd_tdate: moment(searchRedux.ToDate.toString()).format("YYYY-MM-DD")
          ? moment(searchRedux.ToDate.toString()).format("YYYY-MM-DD")
          : moment().add("5", "days").format("YYYY-MM-DD"),
        codd_duserid: searchRedux.userid,
        get_type: 1
      };
  
      getapi
        .post("halopayment/", data)
        .then((response) => {
          setLoading(false)
          //console.log("response data");
          //console.log(JSON.stringify(response.data.data));
          setdata(response.data.data);
          //console.log("datadata", data);
        })
        .catch((error) => {
          setLoading(false)
          //console.log(error);
        });
    };
    
    const handleInputClickS = () => {
      setOpenCalendarS(true);
    };
    const handleCalendarCloseS = () => {
      setOpenCalendarS(false);
    };
  
    const handleInputClickE = () => {
      setOpenCalendarE(true);
    };
    const handleCalendarCloseE = () => {
      setOpenCalendarE(false);
    };
  
    useEffect(() => {
     
      getData();
     
    }, [searchRedux.FromDate, searchRedux.ToDate]);
  
    
    return (
      <Fragment>
        {!matches ? (
          <div>
            <Container>
              <Row>
                <Col md={10} className="mx-auto ">
                  <Box
                    className="date_box"
                    component={Paper}
                    elevation={2}
                    p={2}
                    sx={{
                      backgroundColor: "primary.main",
                    }}
                  >
                    <Row>
                      <Col md={4} className="mx-auto justify-content-center">
                        <div className="text-center align-item-center mb-2">
                          From Date
                        </div>
                        <div className="text-center align-item-center" 
                        //onClick={toggleDatePicker}
                        >
                          <DatePicker
                            maxDate={
                              moment(nowDate)
                            }
                            format="DD/MM/YYYY"
                            
                            closeOnSelect={true}
                        
                          //   open={open}
                          //  onClose={() => setOpen(false)} 
                            sx={{ borderRadius: "15px", fontWeight: 700 }}
                            className="align-item-center sidebar_datebicker d-flex text-center br-20"
                            //label="Controlled picker"
                            value={
                              moment(searchRedux.FromDate.toString())
                                ? moment(searchRedux.FromDate.toString())
                                : moment(fromdate)
                            }
                            onChange={(newValue: any) => {
                              dispatch(UpdatePaymentData("FromDate", newValue));
                            }}
                          /> 
                        </div>
                      </Col>
                      <Col md={4} className="mx-auto justify-content-center">
                        <div className="text-center align-item-center mb-2" onClick={()=> setOpen2(!open2)}>
                          To Date
                        </div>
                        <div className="text-center align-item-center" 
                       // onClick={()=> setOpen2(!open2)}
                        >
                          <DatePicker
                            minDate={
                              moment(searchRedux.FromDate.toString())
                                ? moment(searchRedux.FromDate.toString())
                                : moment().format("YYYY-MM-DD")
                            }
                            maxDate={moment(nowDate)
                            }
                            closeOnSelect={true}
                            format="DD/MM/YYYY"
                          //  open={open2}
                          //  onClose={() => setOpen2(false)}
                            sx={{ borderRadius: "15px" }}
                            className="align-item-center sidebar_datebicker d-flex text-center br-20"
                            //label="Controlled picker"
                            value={
                              moment(searchRedux.ToDate.toString())
                                ? moment(searchRedux.ToDate.toString())
                                : moment().format("YYYY-MM-DD")
                            }
                            onChange={(newValue: any) => {
                              dispatch(UpdatePaymentData("ToDate", newValue));
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Box>
                  {loading ?
    <Box  sx={{ display: 'flex',justifyContent:'center',alignItems:'center',height:'75vh' }}>
    <CircularProgress />
  </Box>
  :
  
  
                  <div className="kpi-mt-4">
        {data.length ?
         <Fragment>
                  <Box
                    component={Paper}
                    elevation={2}
                    p={0}
                    mt={3}
                    className="boxes"
                  >
              
                   
                    {data.map((item: any, idx: number) => (
                      <div className="p-4">
                        <Table
                          id="payment_table"
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          <tr>
                            <td>
                              <Typography>
                                <b>Private</b>
                              </Typography>
                            </td>
                            <td>
                              <Typography className="text-end">
                                <b>{item.Private}</b>
                              </Typography>
                            </td>
                          </tr>
  
                          <tr>
                            <td>
                              <Typography className="mt-3">
                                <b>Medicare</b>
                              </Typography>
                            </td>
                            <td>
                              <Typography className="text-end mt-3">
                                <b>{item.Medicare}</b>
                              </Typography>
                            </td>
                          </tr>
  
                          <tr>
                            <td>
                              <Typography className="mt-3">
                                <b>DVA</b>
                              </Typography>
                            </td>
                            <td>
                              <Typography className="text-end mt-3">
                                <b>{item.DVA}</b>
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography className="mt-3">
                                <b>Others</b>
                              </Typography>
                            </td>
                            <td>
                              <Typography className="text-end mt-3">
                                <b>{item.Others}</b>
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography className="mt-3">
                                <b>GST</b>
                              </Typography>
                            </td>
                            <td>
                              <Typography className="text-end mt-3">
                                <b>{item.GST}</b>
                              </Typography>
                            </td>
                          </tr>
                        </Table>
                        <div className="total_view">
                          <Table className="total">
                            <tr>
                              <td>
                                <Typography>
                                  <b>Total</b>
                                </Typography>
                              </td>
                              <td>
                                <Typography className="text-end">
                                  <b>{item.Total}</b>
                                </Typography>
                              </td>
                            </tr>
                          </Table>
                        </div>
                      </div>
                    ))}
                       </Box>
  </Fragment>
  :(
    <Fragment>
    <div className="m-4-web">
      <Box
        className="date_box mt-4 p-5 justify-content-center text-center align-item-center mt-0"
        component={Paper}
        elevation={2}
        p={2}
        sx={{
          borderRadius: "10px",
        }}
      >
        <Image
          style={{
            margin: 10,
          }}
          src={require("../assets/images/Datanotfound.png")}
          width="216"
          height="194"
        />
        <Typography className="not_fond">
          Data Not Found
        </Typography>
      </Box>
    </div>
  </Fragment>
  ) }
  
               
  
                  </div>
  }
  
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          <div className="payment-mobile-view mt-2 m-3 coddgp_mobile_view">
            <Row>
              <Col md={10} className="mx-auto kpi-pad">
                <Box
                  className="date_box mt-0"
                  component={Paper}
                  elevation={2}
                  p={2}
                  sx={{
                    backgroundColor: "primary.main",
                    borderRadius: "10px",
                  }}
                >
                  <Row>
                    <Col xs={6}>
                      <div className="text-center date_name align-item-center mb-2">
                        From Date
                      </div>
                      <div>
                        <DatePicker
                           maxDate={
                            moment(nowDate)
                          }
                          format="DD/MM/YYYY"
                          closeOnSelect={true}
                          sx={{ borderRadius: "15px", fontWeight: 700 }}
                          className="align-item-center sidebar_datebicker d-flex text-center br-20"
                          //label="Controlled picker"
                          value={
                            moment(searchRedux.FromDate.toString())
                              ? moment(searchRedux.FromDate.toString())
                              : moment(fromdate)
                          }
                          onChange={(newValue: any) => {
                            dispatch(UpdatePaymentData("FromDate", newValue));
                          }}
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="text-center date_name align-item-center mb-2">
                        To Date
                      </div>
                      <div>
                        <DatePicker
                          minDate={
                            moment(searchRedux.FromDate.toString())
                              ? moment(searchRedux.FromDate.toString())
                              : moment().format("YYYY-MM-DD")
                          }
                          maxDate={moment(nowDate)}
                          format="DD/MM/YYYY"
                          closeOnSelect={true}
                          sx={{ borderRadius: "15px" }}
                          className="align-item-center sidebar_datebicker d-flex text-center br-20"
                          //label="Controlled picker"
                          value={
                            moment(searchRedux.ToDate.toString())
                              ? moment(searchRedux.ToDate.toString())
                              : moment().add("5", "days")
                          }
                          onChange={(newValue: any) => {
                            dispatch(UpdatePaymentData("ToDate", newValue));
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Box>
                {loading ?
    <Box  sx={{ display: 'flex',justifyContent:'center',alignItems:'center',height:'75vh' }}>
    <CircularProgress />
  </Box>
  :
       
                   <>
                     {data.length ? (
                    <>
                            <Box
                  component={Paper}
                  elevation={2}
                  p={0}
                  mt={3}
                  className="boxes"
                >
                 
                  {data.map((item: any, idx: number) => (
                    <div className="mt--10">
                      <Card className="custom-card-payment">
                        <Card.Body>
                          <Table
                            id="payment_table"
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            <tr>
                              <td>
                                <Typography>
                                  <b>Private</b>
                                </Typography>
                              </td>
                              <td>
                                <Typography className="text-end">
                                  <b>{item.Private}</b>
                                </Typography>
                              </td>
                            </tr>
  
                            <tr>
                              <td>
                                <Typography className="mt-3">
                                  <b>Medicare</b>
                                </Typography>
                              </td>
                              <td>
                                <Typography className="text-end mt-3">
                                  <b>{item.Medicare}</b>
                                </Typography>
                              </td>
                            </tr>
  
                            <tr>
                              <td>
                                <Typography className="mt-3">
                                  <b>DVA</b>
                                </Typography>
                              </td>
                              <td>
                                <Typography className="text-end mt-3">
                                  <b>{item.DVA}</b>
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Typography className="mt-3">
                                  <b>Others</b>
                                </Typography>
                              </td>
                              <td>
                                <Typography className="text-end mt-3">
                                  <b>{item.Others}</b>
                                </Typography>
                              </td>
                            </tr>
                            <tr style={{ borderBottom: "unset" }}>
                              <td>
                                <Typography className="mt-3">
                                  <b>GST</b>
                                </Typography>
                              </td>
                              <td>
                                <Typography className="text-end mt-3">
                                  <b>{item.GST}</b>
                                </Typography>
                              </td>
                            </tr>
                          </Table>
                          <div className="total_view">
                          <Table className="total">
                            <tr>
                              <td>
                                <Typography>
                                  <b>Total</b>
                                </Typography>
                              </td>
                              <td>
                                <Typography className="text-end">
                                  <b>{item.Total}</b>
                                </Typography>
                              </td>
                            </tr>
                          </Table>
                        </div>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                  </Box>
                  </>
        )
                  :(
                    <Fragment>
                    <div className="m-4-web">
                      <Box
                        className="date_box mt-4 p-5 justify-content-center text-center align-item-center mt-0"
                        component={Paper}
                        elevation={2}
                        p={2}
                        sx={{
                          borderRadius: "10px",
                        }}
                      >
                        <Image
                          style={{
                            margin: 10,
                          }}
                          src={require("../assets/images/Datanotfound.png")}
                          width="216"
                          height="194"
                        />
                        <Typography className="not_fond">
                          Data Not Found
                        </Typography>
                      </Box>
                    </div>
                  </Fragment>
                  ) }
             </>
  }
              </Col>
            </Row>
          </div>
        )}
      </Fragment>
    );
  };
  