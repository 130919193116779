import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Payments } from "./pages/Payments";
import { PaymentsClone } from "./pages/payment_clone";
import { ItemNumber } from "./pages/Itemnumber";
import { MBSReview } from "./pages/Mbsreview";
import { DictateNotes } from "./pages/DictateNotes";
import { DictateNotessV1 } from "./pages/DictateNotes_V1";
import { Sms } from "./pages/Sms";
import { Profile } from "./pages/Profile";
import { Followup } from "./pages/Followup";
import { PatientDetails } from "./pages/Details";
import { Login } from "./pages/Login";
import { Sidebar } from "./pages/sidebar";
import { DirectLogin } from "./pages/Direct_Login";
// New Drawer design imports
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import HomeIcon from "@mui/icons-material/Home";
import { SidebarList } from "./components/Drawer";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Image, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import axios from "axios";
import ScrollToTop from "./components/ScrolltoTop";
import { PaymentState as SearchReduxState, useSelector } from "./redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "./redux/User/actions";
import { getApi } from "./services";
import { RecoverAccount } from "./pages/RecoverAccount";
import { OTPVerification } from "./pages/OTPVerification";
import { Kpi } from "./pages/Kpi";
import { Predictor } from "./pages/Predictor";
import { TwoFactor } from "./pages/TwoFactor";
import { Sendsms } from "./pages/Sendsms";
// import { ApiLogin } from "./pages/ApiLogin";
import { SpeechToText } from "./pages/speechtext";
import { AuthProvider } from "./auth/AuthProvider";
import ProtectedRoute from "./auth/ProtectedRoute";
import { useAuth } from "./auth/AuthProvider";
import { Logout } from "./pages/Logout";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ColorCodes } from "./pages/ColorCodes";
import { AppointmentReason } from "./pages/Appointment_reason";
import { Settings } from "./pages/Settings/settings";
import { BlockedSession } from "./pages/Settings/blocked_session";
import { BlockedSessionForm } from "./pages/Settings/session-form";
import { WaitingTime } from "./pages/Waitingtime";
import SettingsIcon from "@mui/icons-material/Settings";
import { NotFound } from "./pages/NotFound";
import { BlockedPatient } from "./pages/Settings/blocked_patient.";
import { CDM } from "./pages/CDM/index";
import { Diabetes } from "./pages/CDM/Diabetes";
import { Cholesterol } from "./pages/CDM/Cholesterol";

import { Healthasstover } from "./pages/CDM/Healthasstover";
import { Healthoversevenfive } from "./pages/CDM/Healthoversevenfive";
import { HealthAssBtwFivetoNine } from "./pages/CDM/HealthAssBtwFivetoNine";
import { LoginUser } from "./pages/Login_user";
const drawerWidth = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  elevation: 0,
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  elevation: 0,
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "center",
}));

const App: React.FC = () => {
  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state: any) => state.paymentReducer
  );
  const [applyCustomStyle, setApplyCustomStyle] = useState(false);
  const { authenticated, logout } = useAuth();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [NameofPage, setNameofPage] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(!matches ? true : false);
  const navigate = useNavigate();
  const location = useLocation();

  const pathnamearray = location.pathname.split("/");
  const isHome =
    pathnamearray[1] === "sidebar" || pathnamearray[1] === "patient-details";

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [apiData, setApiData] = useState("");
  useEffect(() => {
    console.log("location.pathname", location.pathname);
    const pathname = location.pathname;

    const match = pathname.match(/\/patient-details\/(\d+)/);

    if (match) {
      const patientId = match[1];
      // console.log(`Pathname contains "patient-details" and the patient ID is ${patientId}`);
      setPageTitle("Sidebar");
    } else {
      // console.log('Pathname does not contain "patient-details"');
    }
  }, [location]);
  React.useEffect(() => {
    if (location.pathname === "/sidebar") {
      setPageTitle("Sidebar");
    } else if (location.pathname === "/payments") {
      setPageTitle("Payments");
    } else if (location.pathname === "/kpi") {
      setPageTitle("KPI");
    } else if (location.pathname === "/itemnumber") {
      setPageTitle("Item Numbers");
    } else if (location.pathname === "/mbs-review") {
      setPageTitle("MBS Review");
    } else if (location.pathname === "/followup") {
      setPageTitle("Follow Up");
    } else if (location.pathname === "/predictor") {
      setPageTitle("Predictor");
    } else if (location.pathname === "/sendsms") {
      setPageTitle("SMS");
    } else if (location.pathname === "/profile") {
      setPageTitle("Profile");
    } else if (location.pathname === "/settings") {
      setPageTitle("Settings");
    } else if (location.pathname === "/blockedpatient") {
      setPageTitle("Blockedpatient");
    } else if (location.pathname === "/cdm") {
      setPageTitle("CDM");
    } else if (location.pathname === "/cdm/diabetes") {
      setPageTitle("CDM");
    } else if (location.pathname === "/cdm/ischemic-heart-disease") {
      setPageTitle("CDM");
    }
    // else if (location.pathname === "/aptreason") {
    // setPageTitle("Appointments Reason");
    // }
    else if (location.pathname === "/blockedsession") {
      setPageTitle("Blocked Session");
    } else if (location.pathname === "/blockedsessionform") {
      setPageTitle("Block Session");
    } else if (location.pathname === "/waitingtime") {
      setPageTitle("Waiting Time");
    } else if (location.pathname === "/loginuser") {
      setPageTitle("Login User");
    }

    if (location.pathname === "/aptreason" && matches) {
      setPageTitle("Appointments Reason");
    } else if (location.pathname === "/aptreason") {
      setPageTitle("Appointments Reason");
    }
  }, [location]);
  const [path, setpath] = useState(false);
  useEffect(() => {
    let loc = location.pathname.split("/");
    if (loc[1] === "patient-details") {
      setpath(true);
    } else {
      setpath(false);
    }
    return () => {
      setpath(false);
    };
    // console.log('location',loc[1])
  }, [location]);
  useEffect(() => {
    // if (!searchRedux.userid) {
    //   navigate("/");
    // }
    if (!searchRedux.FromDate) {
      const todat = moment().add().format("YYYY-MM-DD");
      dispatch(UpdatePaymentData("FromDate", todat));
    }
    if (!searchRedux.ToDate) {
      const todat = moment().add().format("YYYY-MM-DD");
      dispatch(UpdatePaymentData("ToDate", todat));
    }
  }, [searchRedux]);

  const [device, setDevice] = useState("");
  function isMobileDevice() {
    return /Mobi|Android|iPhone/i.test(navigator.userAgent);
  }
  function isTabletDevice() {
    // return /iPad|Tablet|PlayBook|Silk|Kindle|Nexus 7|Nexus 10|KFAPWI/i.test(navigator.userAgent) && !/Mobi|iPhone/i.test(navigator.userAgent);
    return /iPad|Tablet|PlayBook|Silk|Kindle|Nexus 7|Nexus 10|KFAPWI|SM-T|GT-P|SCH-I800|Xoom|Transformer|TF101|Slate|Acer|Iconia|A500|A501|BNTV250|SHIELD|K00F/i.test(
      navigator.userAgent
    );
  }
  useEffect(() => {
    if (isTabletDevice()) {
      // console.log("This is a tablet device");
      setDevice("tablet_view");
    } else if (isMobileDevice()) {
      setDevice("");
    } else {
      //  console.log("This is not a mobile device");
      setDevice("msi_view");
    }
  }, [searchRedux]);

  useEffect(() => {
    // ////console.log("authenticated", authenticated);
    const authToken = localStorage.setItem("authToken", authenticated);
    //  ////console.log('authToken',authToken)
  }, [authenticated]);

  useEffect(() => {
    if (location.pathname == "/") {
      setApplyCustomStyle(true);
      console.log("+called");
    }
    return () => {
      setApplyCustomStyle(false);
    };
  }, [searchRedux]);

  const refreshData = () => {
    if (location.pathname === "/sidebar") {
      dispatch(UpdatePaymentData("sidebardata", "Refresh"));
    } else {
      dispatch(UpdatePaymentData("detaildata", "Refresh"));
    }
  };

  const getapi = getApi();
  const logout2 = () => {
    // Check if we are in an iOS WebView environment
    const isIOSWebView =
      /iPhone|iPad|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

    if (isIOSWebView) {
      // Check if 'webkit' and 'messageHandlers' are available
      if ((window as any).webkit && (window as any).webkit.messageHandlers) {
        // Call the 'postMessage' method
        (window as any).webkit.messageHandlers.logoutAction.postMessage(
          "logout"
        );
      } else {
        // Handle the case where 'webkit' or 'messageHandlers' is not available
        console.error("WebView environment does not support postMessage.");
      }
    } else {
      // Handle the case where we are not in an iOS WebView
      console.error(
        "This code should be executed in an iOS WebView environment."
      );
    }

    let data = {
      user_id: searchRedux.userid,
      user_name: searchRedux.username,
      role: searchRedux.role,
    };
    getapi
      .post("halologout/", data)
      .then((res) => {
        ////console.log("logout response:->", res);

        if (res.data.Status) {
          dispatch(UpdatePaymentData("userid", ""));
          dispatch(UpdatePaymentData("Loginfrom", ""));
          document.cookie =
            "uniquevalue=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          logout();
        }
      })
      .catch((err) => {
        ////console.log("error", err);
      });
  };

  const mobilesidebar = () => {
    return (
      <>
        <DrawerHeader>
          <Link to={"/sidebar"}>
            <Image
              style={{
                margin: 10,
              }}
              src={require("./assets/images/logo.png")}
              width="240"
              height="60"
            />
          </Link>
        </DrawerHeader>
        <Divider />

        <List
          onClick={() => setOpen(false)}
          onKeyDown={() => setOpen(false)}
          component="nav"
          className="side_menu"
        >
          <ListItem key={"Sidebar"} disablePadding>
            <ListItemButton
              onClick={() => navigate("/sidebar")}
              selected={location.pathname === "/sidebar"}
            >
              <ListItemIcon>
                <Image src={require("./assets/images/Sidebar.png")} />
              </ListItemIcon>
              <ListItemText className="drawer-txt" primary={"Sidebar"} />
            </ListItemButton>
          </ListItem>

          <ListItem key={"kpi"} disablePadding>
            <ListItemButton
              onClick={() => navigate("/kpi")}
              selected={location.pathname === "/kpi"}
            >
              <ListItemIcon>
                <Image src={require("./assets/images/kpi.png")} />
              </ListItemIcon>
              <ListItemText primary={"KPI"} />
            </ListItemButton>
          </ListItem>

          <ListItem key={"Payments"} disablePadding>
            <ListItemButton
              onClick={() => navigate("/payments")}
              selected={location.pathname === "/payments"}
            >
              <ListItemIcon>
                <Image src={require("./assets/images/payment.png")} />
              </ListItemIcon>
              <ListItemText primary={"Payments"} />
            </ListItemButton>
          </ListItem>

          <ListItem key={"Item Numbers"} disablePadding>
            <ListItemButton
              onClick={() => navigate("/itemnumber")}
              selected={
                location.pathname === "/itemnumber" ||
                location.pathname === "/mbs-review"
              }
            >
              <ListItemIcon>
                <Image src={require("./assets/images/item.png")} />
              </ListItemIcon>
              <ListItemText primary={"Item Numbers"} />
            </ListItemButton>
          </ListItem>

          <ListItem key={"Followup"} disablePadding>
            <ListItemButton
              onClick={() => navigate("/followup")}
              selected={location.pathname === "/followup"}
            >
              <ListItemIcon>
                <Image src={require("./assets/images/follow.png")} />
              </ListItemIcon>
              <ListItemText primary={"Follow Up"} />
            </ListItemButton>
          </ListItem>

          <ListItem key={"Predictor"} disablePadding>
            <ListItemButton
              onClick={() => navigate("/predictor")}
              selected={location.pathname === "/predictor"}
            >
              <ListItemIcon>
                <Image
                  width={24}
                  src={require("./assets/images/Predictor.png")}
                />
              </ListItemIcon>
              <ListItemText primary={"Predictor"} />
            </ListItemButton>
          </ListItem>

          <ListItem key={"SMS"} disablePadding>
            <ListItemButton
              onClick={() => navigate("/sendsms")}
              selected={location.pathname === "/sendsms"}
            >
              <ListItemIcon>
                <Image width={24} src={require("./assets/images/SMS1.png")} />
              </ListItemIcon>
              <ListItemText primary={"SMS"} />
            </ListItemButton>
          </ListItem>

          <ListItem key={"cdm"} disablePadding>
            <ListItemButton
              onClick={() => navigate("/cdm")}
              selected={
                location.pathname === "/cdm" ||
                location.pathname === "/cdm/diabetes" ||
                location.pathname === "/cdm/ischemic-heart-disease" ||
                location.pathname === "/cdm/HealthAssBtwFivetoNine" ||
                location.pathname === "cdm/Healthoversevenfive"
              }
            >
              <ListItemIcon>
                <Image
                  width={24}
                  src={require("./assets/images/clinicalicon/wCMD.png")}
                />
              </ListItemIcon>
              <ListItemText primary={"CDM"} />
            </ListItemButton>
          </ListItem>

          <ListItem key={"settings"} disablePadding>
            <ListItemButton
              onClick={() => navigate("/settings")}
              selected={
                location.pathname === "/settings" ||
                location.pathname === "/blockedpatient" ||
                location.pathname === "/aptreason" ||
                location.pathname === "/blockedsession" ||
                location.pathname === "/blockedsessionform"
              }
            >
              <ListItemIcon>
                <SettingsIcon
                  className="settings_icon"
                  style={{ color: "#fff", fontSize: 24 }}
                />
              </ListItemIcon>
              <ListItemText primary={"Settings"} />
            </ListItemButton>
          </ListItem>

          {searchRedux.role === "superadmin" && (
            <ListItem key={"loginuser"} disablePadding>
              <ListItemButton
                onClick={() => navigate("/loginuser")}
                selected={location.pathname === "/loginuser"}
              >
                <ListItemIcon>
                  <Image src={require("./assets/images/Drawer/Profile.png")} />
                </ListItemIcon>
                <ListItemText primary={"Login User"} />
              </ListItemButton>
            </ListItem>
          )}

          {/* <ListItem key={"Profile"} disablePadding>
            <ListItemButton
              onClick={() => navigate("/profile")}
              selected={location.pathname === "/profile"}
            >
              <ListItemIcon>
                <Image src={require("./assets/images/profile.png")} />
              </ListItemIcon>
              <ListItemText primary={"Profile"} />
            </ListItemButton>
          </ListItem> */}

          <ListItem key={"Log Out"} disablePadding>
            <ListItemButton onClick={() => logout2()}>
              <ListItemIcon>
                <Image src={require("./assets/images/Logout.png")} />
              </ListItemIcon>
              <ListItemText primary={"Log Out"} />
            </ListItemButton>
          </ListItem>
        </List>
      </>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {location.pathname !== "/" &&
        location.pathname !== "/recover-account" &&
        location.pathname !== "/otp-verify" &&
        location.pathname !== "/two-factor" && (
          <div>
            {!matches ? (
              <AppBar
                position="fixed"
                elevation={0}
                open={open}
                id="coddappbar"
                className="header_bg"
              >
                <Row className="header_tool">
                  <div className="col-md-4">
                    <IconButton
                      className="text-white MenuIcon"
                      aria-label="open drawer"
                      onClick={() => setOpen((current) => !current)}
                      edge="start"
                      sx={{ mr: 2, fontSize: 20 }}
                    >
                      <MenuIcon />
                    </IconButton>

                    <a href="#">
                      <b>{pageTitle}</b>
                    </a>
                  </div>

                  <div className="col-md-8 text-right">
                    {isHome && (
                      <Button
                        onClick={() => refreshData()}
                        startIcon={
                          <Image
                            width={20}
                            style={{ marginLeft: "8px" }}
                            src={require("./assets/images/loading_icon.png")}
                          />
                        }
                        sx={{ mr: 1, height: "36px" }}
                        color="secondary"
                        variant="contained"
                      ></Button>
                    )}
                    <Button
                      onClick={() => navigate("/kpi")}
                      variant="contained"
                      color="secondary"
                      startIcon={
                        <HomeIcon
                          style={{
                            fontSize: 22,
                          }}
                        />
                      }
                    >
                      KPI
                    </Button>
                    {location.pathname !== "/sidebar" ? (
                      <Button
                        onClick={() => navigate("/sidebar")}
                        startIcon={
                          <Image
                            width={18}
                            src={require("./assets/images/Sidebar.png")}
                          />
                        }
                        sx={{ ml: 1, mr: 1 }}
                        color="secondary"
                        variant="contained"
                      >
                        Sidebar
                      </Button>
                    ) : (
                      <Button
                        onClick={() => navigate("/clinical-color-code")}
                        startIcon={
                          <InfoOutlinedIcon
                            style={{
                              fontSize: 26,
                            }}
                          />
                        }
                        sx={{ height: "36px", ml: 1, pl: 3 }}
                        color="secondary"
                        variant="contained"
                      ></Button>
                    )}
                  </div>
                </Row>
              </AppBar>
            ) : (
              <AppBar
                position="fixed"
                elevation={0}
                open={open}
                id="coddappbar"
                className="header_bg"
              >
                <Row className="header_tool">
                  <div className={path ? "col-4 pd-0" : "col-8 pd-0"}>
                    <IconButton
                      className="text-white"
                      aria-label="open drawer"
                      onClick={() => setOpen((current) => !current)}
                      edge="start"
                      sx={{ pl: 2, mr: 0 }}
                    >
                      <MenuIcon />
                    </IconButton>
                    <a className={open && matches ? "ptitle" : ""} href="#">
                      <b>{pageTitle}</b>
                    </a>
                  </div>
                  <div
                    className={
                      path
                        ? "col-8 pd-0 text-right"
                        : "col-4 pd-0 tyu text-right"
                    }
                  >
                    {isHome && (
                      <Button
                        id="refresh_btn"
                        className={open && matches ? "ptitle" : ""}
                        onClick={() => refreshData()}
                        startIcon={
                          <Image
                            width={20}
                            style={{ marginLeft: "8px" }}
                            src={require("./assets/images/loading_icon.png")}
                          />
                        }
                        sx={{
                          ml: 0,
                          mr: 1,
                          height: "36px",
                          p: 0,
                          minWidth: 40,
                        }}
                        color="secondary"
                        variant="contained"
                      ></Button>
                    )}
                    {location.pathname !== "/sidebar" ? (
                      <Button
                        className={`side_btn ${location.pathname
                          .split("/")
                          .join("")}`}
                        onClick={() => navigate("/sidebar")}
                        startIcon={
                          <Image
                            width={18}
                            src={require("./assets/images/Sidebar.png")}
                          />
                        }
                        sx={{ ml: 1, mr: 3 }}
                        color="secondary"
                        variant="contained"
                      >
                        Sidebar
                      </Button>
                    ) : (
                      <Button
                        onClick={() => navigate("/clinical-color-code")}
                        className="color_info"
                        startIcon={
                          <Image
                            width={26}
                            style={{ marginLeft: "10px" }}
                            src={require("./assets/images/Info.png")}
                          />
                        }
                        sx={{
                          ml: 0,
                          mr: 1,
                          height: "36px",
                          p: 0,
                          minWidth: 40,
                        }}
                        color="secondary"
                        variant="contained"
                      ></Button>
                    )}
                  </div>
                </Row>
              </AppBar>
            )}
          </div>
        )}
      {searchRedux.userid &&
        location.pathname !== "/recover-account" &&
        location.pathname !== "/otp-verify" &&
        location.pathname !== "/two-factor" && (
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            ModalProps={{
              keepMounted: true, 
            }}
            open={open}
            // onClose={handleDrawerToggle}
            id="sidebardrawer"
          >
            {!matches ? <SidebarList /> : mobilesidebar()}
           
          </Drawer>
        )}

      <Main
        id={open && matches ? "menuopened" : device ? device : ""}
        className={
          device === "msi_view" && location.pathname == "/" ? "logincss" : ""
        }
        open={open}
      >
        {location.pathname !== "/" &&
          location.pathname !== "/recover-account" &&
          location.pathname !== "/otp-verify" &&
          location.pathname !== "/two-factor" && <DrawerHeader />}
        {/* {location.pathname !== "/" &&
      
             <DrawerHeader />} */}
        {location.pathname !== "/sidebar" && <ScrollToTop />}

        <Routes>
          <Route
            path="/login/:name/:password"
            id="direct-login"
            element={<DirectLogin />}
          />
          <Route path="/" element={<Login />} />
          <Route path="/recover-account" element={<RecoverAccount />} />
          <Route path="/otp-verify" element={<OTPVerification />} />
          <Route path="/two-factor" element={<TwoFactor />} />
          <Route path="/test" element={<SpeechToText />} />
          <Route path="/logout" element={<Logout />} />
          {/* <Route path="/kpi" element={<Kpi />} /> */}

          <Route path="/cdm" element={<ProtectedRoute component={CDM} />} />

          <Route
            path="/loginuser"
            element={<ProtectedRoute component={LoginUser} />}
          />
          <Route
            path="/cdm/diabetes"
            element={<ProtectedRoute component={Diabetes} />}
          />
          <Route
            path="/cdm/ischemic-heart-disease"
            element={<ProtectedRoute component={Cholesterol} />}
          />
          <Route
            path="/cdm/healthasstover"
            element={<ProtectedRoute component={Healthasstover} />}
          />
          <Route
            path="/cdm/Healthoversevenfive"
            element={<ProtectedRoute component={Healthoversevenfive} />}
          />
          <Route
            path="/cdm/HealthAssBtwFivetoNine"
            element={<ProtectedRoute component={HealthAssBtwFivetoNine} />}
          />
          <Route
            path="/blockedpatient"
            element={<ProtectedRoute component={BlockedPatient} />}
          />

          <Route
            path="/waitingtime"
            element={<ProtectedRoute component={WaitingTime} />}
          />

          <Route
            path="/blockedsessionform"
            element={<ProtectedRoute component={BlockedSessionForm} />}
          />
          <Route
            path="/blockedsession"
            element={<ProtectedRoute component={BlockedSession} />}
          />

          <Route
            path="/settings"
            element={<ProtectedRoute component={Settings} />}
          />
          <Route
            path="/aptreason"
            element={<ProtectedRoute component={AppointmentReason} />}
          />

          <Route
            path="/predictor"
            element={<ProtectedRoute component={Predictor} />}
          />

          <Route
            path="/payments"
            element={<ProtectedRoute component={Payments} />}
          />
          <Route
            path="/paymentsclone"
            element={<ProtectedRoute component={PaymentsClone} />}
          />

          <Route
            path="/itemnumber"
            element={<ProtectedRoute component={ItemNumber} />}
          />

          <Route
            path="/mbs-review"
            element={<ProtectedRoute component={MBSReview} />}
          />
          <Route
            path="/followup"
            element={<ProtectedRoute component={Followup} />}
          />

          <Route path="/sms" element={<ProtectedRoute component={Sms} />} />

          <Route
            path="/sendsms"
            element={<ProtectedRoute component={Sendsms} />}
          />

          <Route
            path="/dictatenotes-test/:id"
            element={<ProtectedRoute component={DictateNotes} />}
          />

          <Route
            path="/dictatenotes/:id"
            element={<ProtectedRoute component={DictateNotessV1} />}
          />

          <Route
            path="/patient-details/:id"
            element={<ProtectedRoute component={PatientDetails} />}
          />

          <Route
            path="/sidebar"
            element={<ProtectedRoute component={Sidebar} />}
          />
          <Route path="/kpi" element={<ProtectedRoute component={Kpi} />} />
          <Route
            path="/profile"
            element={<ProtectedRoute component={Profile} />}
          />
          <Route
            path="/clinical-color-code"
            element={<ProtectedRoute component={ColorCodes} />}
          />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Main>
    </Box>
  );
};

export default App;
