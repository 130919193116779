import { Box, Paper, Typography, CircularProgress } from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import axios from "axios";
import { PaymentState as SearchReduxState, useSelector } from "../redux";
import { UpdatePaymentData, ResetData } from "../redux/User/actions";
import { getApi } from "../services";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

interface SidebarProps {
  apiData: string; // Change the type to match your API response data type
}

export const Sidebar: React.FC = () => {
  const shouldLog = useRef(true);

  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const getapi = getApi();
  const [nowDatePP, setnowDatePP] = useState(
    moment().add("-3", "days").format("YYYY-MM-DD")
  );
  const [nowDatePF, setnowDatePF] = useState(
    moment().add("3", "days").format("YYYY-MM-DD")
  );
  const dispatch = useDispatch();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [RunHistory, setRunHistory] = useState(false);
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleDateChange1 = (date: any) => {
    setSelectedDate(date);
    setOpen(false); // Close the DatePicker after selecting a date
  };

  const toggleDatePicker = () => {
    setOpen(!open); // Toggle the DatePicker open/close state
  };

  // const [selectedDate, setSelectedDate] = React.useState();

  //Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );

  // console.log("Redux Value", searchRedux);

  const [selectedDate, setSelectedDate] = useState(
    moment(searchRedux.selecteddate).format("YYYY-MM-DD")
  );

  const handleInputClick = () => {
    setOpenCalendar(true);
  };

  const handleCalendarClose = () => {
    setOpenCalendar(false);
  };

  const getData2 = () => {
    setLoading(true);

    var isDemo = 0;

    if (searchRedux.username == "codd.demo") {
      isDemo = 1;
    } else if (searchRedux.username == "codd.demo2") {
      isDemo = 1;
    } else if (searchRedux.username == "codd.demo3") {
      isDemo = 1;
    } else {
      isDemo = 0;
    }

    if (
      searchRedux.sidebardata == "" ||
      searchRedux.sidebardata == "null" ||
      searchRedux.sidebardata == null
    ) {
      var get_typeValue = "Refresh";
    } else {
      var get_typeValue = searchRedux.sidebardata;
    }

    let data = {
      userlocid: searchRedux.location_id,
      codd_sidebardate: dayjs(selectedDate).format("YYYY-MM-DD"),
      codd_duserid: searchRedux.userid,
      get_type: get_typeValue,
      isDemo: isDemo,
      getfrom: 1,
    };

    getapi
      .post("halosidebarnew/", data)
      .then((response) => {
        dispatch(UpdatePaymentData("sidebardata", "Onload"));
        setLoading(false);

        const responseData = response.data.data;
        // console.log("Response data", response);

        if (responseData.length) {
          setdata(response.data.data);
        } else {
          setdata(response.data.data);
          ////console.log("***********no data");
        }
      })
      .catch((error) => {
        setLoading(false);
        ////console.log("***********no data");
      });
  };

  useEffect(() => {
    getData2();
  }, [selectedDate, searchRedux.sidebardata]);

  // useEffect(() => {
  //  console.log('searchRedux=>',searchRedux)
  //  console.log('Today date',new Date())
  // }, [searchRedux]);

  useEffect(() => {
    ////console.log("valueselectedDate", moment(selectedDate).format("YYYY-MM-DD"));
    dispatch(UpdatePaymentData("selecteddate", selectedDate));
  }, [selectedDate]);

  const handleDateChange = (e: any) => {
    const newDate = e;

    setSelectedDate(newDate);
    dispatch(UpdatePaymentData("selecteddate", newDate));
  };

  const divRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [top, settop] = useState("");
  // Helper function to calculate the difference in minutes between two time strings
  const getTimeDifferenceInMinutes = (time1: string, time2: string) => {
    const moment1 = moment(time1, "hh:mm A");
    const moment2 = moment(time2, "hh:mm A");
    return moment2.diff(moment1, "minutes");
  };

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10);
    if (
      moment(searchRedux.selecteddate).format("YYYY-MM-DD") === formattedDate
    ) {
      const currentTime = moment().format("hh:mm A"); // Get current time in 12-hour format
      let closestTimeDiff = Infinity;
      let closestIdx = -1;

      // Find the closest matching time
      // data.forEach((item: any, idx) => {
      //   const timeDiff = getTimeDifferenceInMinutes(currentTime, item.ATime);
      //   if (timeDiff >= 0 && timeDiff < closestTimeDiff) {
      //     closestTimeDiff = timeDiff;
      //     closestIdx = idx;
      //   }
      // });
      data.forEach((item: any, idx) => {
        const timeDiff = getTimeDifferenceInMinutes(item.ATime, currentTime);
        if (timeDiff >= 0 && timeDiff <= closestTimeDiff) {
          closestTimeDiff = timeDiff;
          closestIdx = idx;
        }
      });

      // Scroll to the closest matching time
      if (closestIdx !== -1 && divRefs.current[closestIdx]) {
        const matchedDiv = divRefs.current[closestIdx]!;

        matchedDiv.classList.add("scroll-target"); // Add a class to the matched div

        matchedDiv.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
        // Ensure the div is scrolled to the top of the container
        const parentDiv = matchedDiv.parentElement;
        if (parentDiv) {
          parentDiv.scrollTop = matchedDiv.offsetTop;
        }
      }
    }
  }, [data, searchRedux, divRefs.current]); // Trigger scroll effect whenever data changes

  return (
    <>
      {loading ? (
        <Fragment>
          <Container className="coddgp_mobile_view">
            <Row className="row-gutter-x">
              <Col md={10} className="mx-auto">
                <Box
                  className="date_box mt-2"
                  component={Paper}
                  elevation={2}
                  p={2}
                  sx={{
                    backgroundColor: "primary.main",
                    borderRadius: "10px",
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div
                        className="font-15"
                        //onClick={toggleDatePicker}
                      >
                        <DatePicker
                          // open={open}
                          // onClose={() => setOpen(false)}
                          format="ddd DD MMM 'YY"
                          closeOnSelect={true}
                          sx={{ borderRadius: "15px" }}
                          className="align-item-center sidebar_datebicker desc_date st d-flex text-center br-20"
                          maxDate={moment(nowDatePF)}
                          minDate={moment(nowDatePP)}
                          value={moment(selectedDate)}
                          onChange={(newValue: any) => {
                            setSelectedDate(newValue);
                            dispatch(UpdatePaymentData("sidebardata", ""));
                            ////console.log(dayjs(newValue).format("YYYY-MM-DD"));
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Box>
              </Col>
            </Row>
          </Container>
          <Box
            className="loader_container"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "75vh",
            }}
          >
            <CircularProgress />
          </Box>
        </Fragment>
      ) : (
        <Fragment>
          <Container className="coddgp_mobile_view">
            <Row className="row-gutter-x">
              <Col md={10} className="mx-auto">
                <Box
                  className="date_box mt-2"
                  component={Paper}
                  elevation={2}
                  p={2}
                  sx={{
                    backgroundColor: "primary.main",
                    borderRadius: "10px",
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div
                        className="font-15"
                        //onClick={toggleDatePicker}
                      >
                        <DatePicker
                          // open={open}
                          // onClose={() => setOpen(false)}
                          format="ddd DD MMM 'YY"
                          closeOnSelect={true}
                          sx={{ borderRadius: "15px" }}
                          className="align-item-center sidebar_datebicker desc_date st d-flex text-center br-20"
                          maxDate={moment(nowDatePF)}
                          minDate={moment(nowDatePP)}
                          value={moment(selectedDate)}
                          onChange={(newValue: any) => {
                            setSelectedDate(newValue);
                            dispatch(UpdatePaymentData("sidebardata", ""));
                            ////console.log(dayjs(newValue).format("YYYY-MM-DD"));
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Box>

                {data.length ? (
                  <Fragment>
                    {data?.map((item: any, idx: number) => (
                      <div
                        key={idx}
                        ref={(el) => (divRefs.current[idx] = el)}
                        id={top}
                        onClick={() => {
                          dispatch(
                            UpdatePaymentData("uniqueid", item.SidebarId)
                          );
                          dispatch(UpdatePaymentData("user_name", item.Name));
                          dispatch(UpdatePaymentData("dob", item.PntDob));
                          dispatch(UpdatePaymentData("PntId", item.PntId));
                          navigate("/patient-details/" + item.PntId + "/");
                          localStorage.setItem("ColorCode", item.ColorCode);
                          // dispatch(UpdatePaymentData("uniqueid", item.SIDEBARID));
                          // dispatch(UpdatePaymentData("user_name", item.NAME));
                          // dispatch(UpdatePaymentData("dob", item.PNTDOB));
                          // navigate("/patient-details/" + item.PNTID);
                        }}
                        className="sidebar_list mt-3 p-2"
                        style={{
                          backgroundColor: item.ColorCode,
                        }}
                      >
                        <Row className="sbp" style={{ color: item.FontColor }}>
                          <Col md={2} xs={3} className="t_ime">
                            <Typography className="align-middle fw-bold font-12 pd-r-0">
                              {item.ATime}

                              <span
                                style={{
                                  backgroundColor: item.SubColor,
                                  color: item.SubColor,
                                  float: "right",
                                  borderRadius: "5rem",
                                  width: "6px",
                                  marginTop: "-4px",
                                }}
                                className="font-bar"
                              >
                                |
                              </span>
                            </Typography>
                            {/* <Typography className="align-middle">08.12 AM</Typography> */}
                          </Col>

                          <Col className="name">
                            <Typography className="fw-bold">
                              {item.Name}
                            </Typography>
                            {/* <Typography>JAMIE SAMPSON-SMITH</Typography> */}
                          </Col>
                          <Col
                            className="d-flex text-right justify-content-end"
                            xs={2}
                            md={2}
                          >
                            {item.RecallCode == 1 && (
                              <Image
                                width={40}
                                style={{
                                  border: "1px solid #aaa",
                                  borderRadius: "50%",
                                }}
                                src={require("../assets/images/py.png")}
                                className="show-web"
                              />
                            )}
                            {item.RecallCode == 1 && (
                              <Image
                                width={30}
                                height={30}
                                style={{
                                  border: "1px solid #aaa",
                                  borderRadius: "50%",
                                }}
                                src={require("../assets/images/py.png")}
                                className="show-mob"
                              />
                            )}
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="m-4-web">
                      <Box
                        className="date_box mt-4 p-5 justify-content-center text-center align-item-center mt-0"
                        component={Paper}
                        elevation={2}
                        p={2}
                        sx={{
                          borderRadius: "10px",
                        }}
                      >
                        <Image
                          style={{
                            margin: 10,
                          }}
                          src={require("../assets/images/Datanotfound.png")}
                          width="216"
                          height="194"
                        />
                        <Typography className="not_fond">
                          Data Not Found
                        </Typography>
                      </Box>
                    </div>
                  </Fragment>
                )}
              </Col>
            </Row>
          </Container>
        </Fragment>
      )}
    </>
  );
};
