import {
    AppBar,
    Box,
    Container,
    FormControl,
    OutlinedInput,
    Paper,
    Toolbar,
    Typography,
    Button,
    FormLabel,
    Stack,
    FormHelperText,
    Link,
    Card,
    CardContent,
    CircularProgress,
    Tooltip,
  } from "@mui/material";
  import React, { Fragment, useEffect, useState } from "react";
  import { Col, Row, Table,Image } from "react-bootstrap";
  import { useNavigate } from "react-router-dom";
  import Form from "react-bootstrap/Form";
  import InputGroup from "react-bootstrap/InputGroup";
  import CircleIcon from "@mui/icons-material/Circle";
  import IconButton from "@mui/material/IconButton";
  import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
  import LockOpenIcon from "@mui/icons-material/LockOpen";
  import useMediaQuery from "@mui/material/useMediaQuery";
  import { styled, useTheme } from "@mui/material/styles";
  import "bootstrap/dist/css/bootstrap.min.css";
  import { getApi } from "../../services";
  import Snackbar from "@mui/material/Snackbar";
  import moment from "moment";
  import MuiAlert, { AlertProps } from "@mui/material/Alert";
  // Reducer Redux
  import { PaymentState as SearchReduxState, useSelector } from "../../redux";
  import { useDispatch } from "react-redux";
  import { UpdatePaymentData } from "../../redux/User/actions";
  import SendIcon from '@mui/icons-material/Send';
  import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
  import DeleteIcon from '@mui/icons-material/Delete';
  import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
  import Modal from '@mui/material/Modal';
import { Moment } from 'moment';
import UndoIcon from '@mui/icons-material/Undo';
  export const BlockedSession: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const dispatch = useDispatch();
    const searchRedux = useSelector<SearchReduxState>(
      (state) => state.paymentReducer
    );
    const [username, setusename] = useState(searchRedux.LoginuserName);
    const [password, setPassword] = useState(searchRedux.password);
    const [data,setData] = useState([])
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    const getapi = getApi();
  
    const [open, setOpen] = React.useState(false);
    const [msg, setMsg] = useState("");
    const [Mopen, setMOpen] = React.useState(false);

    const handleMOpen = () => setMOpen(true);
    const handleMClose = () => setMOpen(false);

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
      props,
      ref
    ) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
  
    const handleClose = (
      event?: React.SyntheticEvent | Event,
      reason?: string
    ) => {
      if (reason === "clickaway") {
        return;
      }
  
      setOpen(false);
    };
  
    const reset = () => {
      setusename(searchRedux.username);
      setPassword(searchRedux.password);
    };
  

   

    const [selectedFile, setSelectedFile] = useState<File | null>(null); // Provide a type annotation for selectedFile

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files ? event.target.files[0] : null; // Safely access files
      setSelectedFile(file);
    };
    useEffect(()=>{
      // console.log('searchredux results',searchRedux)
   },[searchRedux])


   const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24, 
    p: 3,
  };

 
  const getData =() =>{
    setLoading(true);
    let data ={
        "location_id": searchRedux.location_id,
        "user_id": searchRedux.userid,      
    }
  //  // console.log('submitted data',data )

  getapi.post("getsessionblock/", data)
    .then((res) => {
     // // console.log("update response:->", res);
      setLoading(false);
      if (res.data.Success) {
        setData(res.data.data)
      }
    })
    .catch((err) => {
      // console.log("error", err);
      setLoading(false);
      setData([])
    });


}

useEffect (()=>{
  getData()
},[])



const [device,setDevice] = useState('')
function isMobileDevice() {
  return /Mobi|Android|iPhone/i.test(navigator.userAgent);
}

useEffect(() =>{
  if (isMobileDevice()) {
   // console.log("This is a mobile device");
  } else {
   // console.log("This is not a mobile device");
    setDevice('msi_view')
  }
},[])



const [dataReason, setdataReason] = useState<any>([]);
const [ReasonName, setReasonName] = useState<any>([]);
    /* Appointment type api call */

    const UserReasonData = (id:any): React.ReactNode => {
      // setloadingReason(true);
       // console.log("welcome API 123",id);
       let data = {
        location_id: searchRedux.location_id,
        user_id: searchRedux.userid
      };
   let res2:any=[]
   let name:any=''
      // console.log("send data", data);
      getapi
        .post("aptreasonbyuser/", data)
         .then((res) => {
         //  setloadingReason(false);
           // console.log("reason api data:->", res.data.data);
        //   setReasonName(res.data)
        
         res2 = res.data.data?.filter((item:any )=> item.ReasonId === Number(id))
         // console.log('test bala bala',res2)
        if (res2.length) {
            name = res2[0].MainReason
            // console.log('reason name', id)       
        }

         })
         .catch((err) => {
           // console.log("error", err);
         });
         return name
     };
     
const getReason = (reasons: string): React.ReactNode =>{
// console.log('bala test',reasons)
const reasonsArray = reasons.split(',');



const reasonElements = reasonsArray.map((reason, index) => (
  <div key={index}>{UserReasonData(reason)}</div>
));

return reasonElements;

}

const [did,setdeleteid] = useState()
const deletepop = (id:any) =>{
  setdeleteid(id)
  handleMOpen()
  
}
const delete_session = () =>{
  handleMClose()
  let data ={
    primary_id:did 
}
// console.log('submitted data',data )

getapi.post("sessionblockremove/", data)
.then((res) => {
  // console.log("update response:->", res);
  getData()
  if (res.data.Success) {
    setMsg(res.data.Message);
    setOpen(true);
    getData()
  }
})
.catch((err) => {
  // console.log("error", err);
});
}
    return (
      <>
      {loading ?
  <Box  sx={{ display: 'flex',justifyContent:'center',alignItems:'center',height:'75vh' }}>
  <CircularProgress />
</Box>
:
      <Fragment>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
            {msg}
          </Alert>
        </Snackbar>
  
        <Modal
        className="confirm_msg"
        open={Mopen}
        onClose={handleMClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id='confirm_msg' sx={style}>
        <div className="delet_btn text-end d-flex justify-content-end">
          <CancelOutlinedIcon onClick={() => handleMClose() }/>
        </div>
        <div className='align-middle mt-2 no_app justify-content-center text-center'>
        <p className='pt-1 pb-1 no-text'>
           Are you sure you want to delete?
          </p>
          <Row>
              <Col>

       <Button
        sx={{
          
          textTransform: 'capitalize',
          fontWeight: 600,
          fontSize: 16
        }}
        fullWidth
        variant='contained'
        color='primary'
        className='bbtn'
        onClick={() => delete_session()}
       >
        Yes
       </Button>
       </Col>
       <Col>
       <Button
        variant='contained'
        color='secondary'
        className='cbtn'
        fullWidth
        onClick={handleMClose}
        sx={{

          textTransform: 'capitalize',
          fontWeight: 600,
          fontSize: 16
        }}
       >
        No
       </Button>
       </Col>
       </Row>
       </div>
        </Box>
      </Modal>

        {!matches ? (
          <div className="web-profile-div settings_page">
            <Container>
              <Row>
                <Col md={10} className="mx-auto">
                <div className="d-flex justify-content-end">
            <Button
                      className="text-white"
                      aria-label="open drawer"
                      onClick={() => navigate('/settings')}
                     sx={{ height: "36px",minWidth:32 }}
                     color="secondary"
                     variant="contained"
                    >
                      <UndoIcon />
                    </Button>
                    </div>

                  <Box
                    component={Paper}
                    elevation={2}
                    p={2}
                    mt={3}
                    className="boxes bradius"
                  >
                    <Row>
                      <Col md={9}>
                      <Typography className='tlte_b'>Blocked Session</Typography>
                      </Col>
                     <Col className="text-right" md={3}>
                      <Button 
                        onClick={()=>navigate('/blockedsessionform')}
                      variant='contained'>Block Session</Button>
                     </Col>
                   
                    </Row>
                    
                    
               
                  </Box>
                  {data.length ? 
                  <>
  {data?.map((item:any,idx:any)=>
 
                  <Card className="mt-4 bbo" sx={{ minWidth: 275 }}>
                    <CardContent className="block_content">
                      <Row>
                        <Col md={11}>
                       
                         {/* { getReason(item.Reasons) } */}
                         <Typography className="usernam">{item.Reasons}</Typography>
                      <Typography className="mt-3 mb-3">{item.Days}</Typography> 
                      <div className="d-flex flex-direction-row">
                      <Typography style={{
                          marginRight:20
                        }}> { moment(item.FromDate).format('DD-MM-YYYY') } - {moment(item.ToDate).format('DD-MM-YYYY')}</Typography>
                        <Typography style={{
                          fontSize:15,
                          marginTop:1
                        }}>{moment(item.FromTime,'HH:mm').format('hh:mm A')} - {moment(item.ToTime,'HH:mm').format('hh:mm A')}</Typography>
                        
                      </div>
                        </Col>
                        <Col md={1} className="align-middle">
                        <Tooltip title="Delete">
                        <Button 
                        onClick={()=> deletepop(item.Id)}
                        className="mt-5" 
                        size="small" 
                        sx={{minWidth:32,background:'#D7EAFF',color:'#4666AE'}}>
                      <DeleteIcon />
                    </Button>
                    </Tooltip>
                        </Col>
                      </Row>                  
                  </CardContent>
                  </Card>
 )}
 </>
 :
 <Fragment>
 <div className="m-4-web">
   <Box
     className="date_box mt-4 p-5 justify-content-center text-center align-item-center mt-0"
     component={Paper}
     elevation={2}
     p={2}
     sx={{
       borderRadius: "10px",
     }}
   >
     <Image
       style={{
         margin: 10,
       }}
       src={require("../../assets/images/Datanotfound.png")}
       width="216"
       height="194"
     />
     <Typography className="not_fond">
       Data Not Found
     </Typography>
   </Box>
 </div>
</Fragment>
  }
              
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          <div className="profile-mobile-view  m-2 coddgp_mobile_view" id={device}>
          
              <Row>
                <Col md={10} className="mx-auto">
                <div className="d-flex justify-content-end">
            <Button
                      className="text-white bb_b back_button"
                      aria-label="open drawer"
                      onClick={() => navigate('/settings')}
                     sx={{ height: "36px",minWidth:32 }}
                     color="secondary"
                     variant="contained"
                    >
                      <UndoIcon />
                    </Button>
                    </div>

                  <Box
                    component={Paper}
                    elevation={2}
                    p={2}
                    mt={2}
                    className="boxes bradius"
                  >
                    <Row>
                      <Col md={9} xs={7}>
                      <Typography className='tlte_b'>Blocked Session</Typography>
                      </Col>
                     <Col className="text-right" md={3} xs={5}>
                      <Button 
                      className="block_btn"
                        onClick={()=>navigate('/blockedsessionform')}
                      variant='contained'>Block Session</Button>
                     </Col>
                   
                    </Row>
                    
                    
               
                  </Box>
                  {data.length ?
                  <>
                  {data?.map((item:any,idx:any)=>
                  <Card className="mt-2 bbo" sx={{ minWidth: 275 }}>
                    <CardContent className="block_content">
                      <Row>
                        <Col md={11} xs={10}>
                        <Typography className="usernam">{item.Reasons}</Typography>
                      <Typography className="mt-3 mb-3 txt2">{item.Days}</Typography>
                      <Typography className='txt3'> { moment(item.FromDate).format('DD-MM-YYYY') } - {moment(item.ToDate).format('DD-MM-YYYY')}</Typography>
                      
                      <div className="d-flex txt3 flex-direction-row">
                        
                      <Typography className='txt3'>{moment(item.FromTime,'HH:mm').format('hh:mm A')} - {moment(item.ToTime,'HH:mm').format('hh:mm A')}</Typography>
                      </div>
                        </Col>
                        <Col md={1} xs={2} className="align-middle">
                        <Button 
                     // onClick={()=> delete_session(item.Id)}
                     onClick={()=> deletepop(item.Id)}
                        className="mt-5" 
                        size="small" 
                        sx={{minWidth:32,background:'#D7EAFF',color:'#4666AE'}}>
                      <DeleteIcon />
                    </Button>
                   
                        </Col>
                      </Row>                  
                  </CardContent>
                  </Card>

                  )}
                </>
                :
                <Fragment>
                <div className="m-4-web">
                  <Box
                    className="date_box mt-4 p-5 justify-content-center text-center align-item-center mt-0"
                    component={Paper}
                    elevation={2}
                    p={2}
                    sx={{
                      borderRadius: "10px",
                    }}
                  >
                    <Image
                      style={{
                        margin: 10,
                      }}
                      src={require("../../assets/images/Datanotfound.png")}
                      width="216"
                      height="194"
                    />
                    <Typography className="not_fond">
                      Data Not Found
                    </Typography>
                  </Box>
                </div>
               </Fragment>
  }

                </Col>
              </Row>
           
          </div>
        )}
      </Fragment>
  }
      </>
    );
  };
  