import {
  Box,
  Container,
  FormControl,
  OutlinedInput,
  Paper,
  Typography,
  Button,
  FormLabel, 
  FormHelperText,
  IconButton,
  InputAdornment,
 
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Table, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import { ImageUrl, getApi,getFormApi } from "../../services";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
// Reducer Redux
import { PaymentState as SearchReduxState, useSelector } from "../../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../../redux/User/actions";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
export const Settings: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );
  const [fullname, setfullname] = useState(searchRedux.LoginuserName);
  const [username, setusename] = useState(searchRedux.username);
  const [password, setPassword] = useState(searchRedux.password);
  const [mobileno, setmobileno] = useState<string | undefined>(undefined);
  const [email, setemail] = useState(searchRedux.Loginuseremail);
  const CheckMobile = () => {
    const originalNumber = searchRedux.LoginuserMobileno;
  
    var modifiedNumber = originalNumber;
  
    if (originalNumber.startsWith("61")) {
      modifiedNumber = originalNumber.slice(2);
      setmobileno(modifiedNumber)
    }
    else{
      setmobileno(searchRedux.LoginuserMobileno)
    }
    
  }
  
useEffect(() =>{
  CheckMobile()
 // console.log('mobile no',mobileno)
},[searchRedux.LoginuserMobileno])


const formatPhoneNumber = (phoneNumber: any): any => {
  if (phoneNumber.startsWith('0')) {
    // Phone number already starts with zero, return as is
    setmobileno(phoneNumber) ;
  } else {
    // Add zero prefix to the phone number
    setmobileno('0' + phoneNumber);
  }
};



  const [usernameerror, setusenameerror] = useState(false);
  const [mobilenoerror, setmobileerror] = useState(false);
  const [emailerror, setemailerror] = useState(false);
  const [fullnameerror,setfullnameerror] = useState(false)
  const [passworderror, setPassworderror] = useState(false);
  const [photoerror, setphotoerror] = useState(false);

  const navigate = useNavigate()
  const getapi = getApi();
  const getformapi = getFormApi();
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = useState("");

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const reset = () => {
    setusename(searchRedux.username);
    setPassword(searchRedux.password);
  };

  useEffect(()=>{
//console.log('searchredex res',searchRedux)
  },[searchRedux])
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const [selectedFile, setSelectedFile] = useState<File|null>(null); // Provide a type annotation for selectedFile
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null; // Safely access files
    setSelectedFile(file);
    Changephoto(file)
   // console.log(file)
 // Preview the selected image
 if (file) {
  const reader = new FileReader();
  reader.onloadend = () => {
    setPreviewUrl(reader.result as string);
  };
  reader.readAsDataURL(file);
} else {
  setPreviewUrl(null);
}

  };
  const [mnumbererror, setnumbererror] = useState(false);
  const handleMobileNumberChange = (event: any) => {
   
    setmobileerror(false)
    const inputValue = event.target.value;
    // Regex to match only 11-digit numbers
    const regex = /^\d{0,10}$/; // Allows only digits, up to 9 characters
    if (regex.test(inputValue)) {
     // setmobileno(inputValue);
     formatPhoneNumber(inputValue)
    }
};
const [device,setDevice] = useState('')
function isMobileDevice() {
  return /Mobi|Android|iPhone/i.test(navigator.userAgent);
}

useEffect(() =>{
  if (isMobileDevice()) {
   // console.log("This is a mobile device");
  } else {
   // console.log("This is not a mobile device");
    setDevice('msi_view')
  }
},[])

  const submit = () => {
  
        
    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
    // console.log("Image size should be 4MB or less");
     setphotoerror(true)
     setTimeout(() => {
      setphotoerror(false)
     }, 3000);
   return;
}

    if (username !== undefined && username !== null && username.trim() === '') {
      setusenameerror(true)
      return
    }

    if (fullname !== undefined && fullname !== null && fullname.trim() === '') {
      setfullnameerror(true)
      return
    }

    if (password !== undefined && password !== null && password.trim() === '') {
      setPassworderror(true)
      return
    }

    if (mobileno !== undefined && mobileno !== null && /^\d+$/.test(mobileno) ) {
      if (mobileno.trim() === '' || mobileno.length !== 10) {
        setmobileerror(true)
        return
      }
    }


    if (email !== undefined && email !== null && email?.trim() === '') {
      setemailerror(true)
      return
    }

    const formData = new FormData();
     formData.append("image_path", selectedFile ? selectedFile : '');
     formData.append("id", searchRedux.id);
     formData.append("username", fullname);
     formData.append("mail_id", email);
     formData.append("mobile_no",mobileno? +61+mobileno :'');
     formData.append( "field_username",username);
     formData.append("field_password",password );

  //  console.log("submited data", formData);

    getformapi
      .post("haloprofileupdate/", formData)
      .then((res) => {
       // console.log("profile response:->", res);

        if (res.data.Success) {
          setMsg(res.data.Message);
          dispatch(UpdatePaymentData("Loginuseremail", email));
          dispatch(UpdatePaymentData("LoginuserName", username));
          dispatch(UpdatePaymentData("LoginuserMobileno", mobileno));
          dispatch(UpdatePaymentData("username", username));
          dispatch(UpdatePaymentData("password", password));
          dispatch(UpdatePaymentData("ProfPhotoN", res.data.data[0].ImagePath.split('/').pop()));
          setOpen(true);
        }
      })
      .catch((err) => {
        //console.log("error", err);
      });
  };

const Changephoto = (file:any) =>{

  const formData = new FormData();
  formData.append("image_path", file ? file : '');
  formData.append("id", searchRedux.id);
  formData.append("username", fullname);


  getformapi.post("haloprofileimgupdate/", formData)
  .then((res) => {
  // console.log("profile photo response:->", res);

    if (res.data.Success) {
      setMsg(res.data.Message);
      dispatch(UpdatePaymentData("ProfPhotoN", res.data.data[0].ImagePath.split('/').pop()));
      setOpen(true);
    }
  })
  .catch((err) => {
    console.log("photo error", err);
  });
}

const Removephoto = () =>{

  const formData = new FormData();
  formData.append("image_path",'');
  formData.append("id", searchRedux.id);
  formData.append("username", fullname);


  getformapi.post("haloprofileimgupdate/", formData)
  .then((res) => {
 //  console.log("profile photo response:->", res);

    if (res.data.Success) {
      setMsg(res.data.Message);
      dispatch(UpdatePaymentData("ProfPhotoN",''));
      setPreviewUrl(null);
      setOpen(true);
    }
  })
  .catch((err) => {
    console.log("photo error", err);
  });
}

// useEffect(() =>{
//   Changephoto()
// },[selectedFile])


  useEffect(() => {
   // console.log('searchredux selectedFile', selectedFile?.name  )
   // dispatch(UpdatePaymentData("ProfPhotoN",selectedFile?.name))
  }, [selectedFile])

  return (
    <Fragment>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {msg}
        </Alert>
      </Snackbar>
      

      {!matches ? (
        <div className="web-profile-div settings_page">
          <Container>
            <Row>
              <Col md={10} className="mx-auto">
                <Box
                  component={Paper}
                  elevation={2}
                  p={2}
                  mt={3}
                  className="boxes"
                >



                  <div
                    style={{
                      marginLeft: 20,
                      textAlign: 'center'
                    }}
                    className="user_photodiv d-flex flex-direction-row align-items-center">
                   {previewUrl ?
                        <Image
                        rounded
                        width={90}
                        height={90}
                        className="d-inline-flex align-left username object-fit-cover align-middle profile-img"
                        src={
                          previewUrl
                        }
                        //  src={require('../../assets/images/user1.png')}
                        roundedCircle
                      />
                     :
                   <Image
                      rounded
                      width={90}
                      height={90}
                      className="d-inline-flex align-left username object-fit-cover align-middle profile-img"
                      src={
                        searchRedux.ProfPhotoN
                          ? ImageUrl+searchRedux.ProfPhotoN
                          : require("../../assets/images/new_photo.png")
                      }
                      //  src={require('../../assets/images/user1.png')}
                      roundedCircle
                    />
}
                    <div className="align-items-center user_right">
                      {/* <Typography className="align-center user_name">{searchRedux.LoginuserName}</Typography> */}
                      {searchRedux.ProfPhotoN ?
                      
                      <Button
                        onClick ={Removephoto}
                        className="changephoto"
                        component="label"
                        variant='outlined'
                        size='small'
                        style={{
                          textTransform: 'capitalize'
                        }}
                      >Remove Photo
                        
                      </Button>
                      :
                      <Button
                      className="changephoto"
                      component="label"
                      variant='outlined'
                      size='small'
                      style={{
                        textTransform: 'capitalize'
                      }}
                    >Add Photo
                      <VisuallyHiddenInput
                        accept=".jpg, .png" // Specify accepted file types
                        onChange={handleFileInputChange}
                        type="file" />
                    </Button>
}
                    </div>
                    {/* {selectedFile && <p>Selected File: {selectedFile.name}</p>} */}
                  </div>
                  {photoerror &&
<p className="error_msg">Image size must be under 5 Mb</p>
}
                  <div className="p-3">
                    <form>
                      <div>
                        <FormControl fullWidth error={usernameerror}>
                          <FormLabel className="form-label">Full Name</FormLabel>

                          <OutlinedInput
                            error={fullnameerror}
                            value={fullname}
                            fullWidth
                            onChange={(e) => {
                              setfullname(e.target.value);
                              setfullnameerror(false)
                            }}
                            className="mt-1 sinput-border"
                          //   endAdornment={
                          //     <IconButton>
                          //       <PersonOutlineIcon />
                          //     </IconButton>
                          //   }
                          />
                          {fullnameerror && <FormHelperText>This field is required</FormHelperText>}
                        </FormControl>
                      </div>

                      <div>
                        {/* <FormControl fullWidth error={mobilenoerror}>
                          <FormLabel className="form-label password-input mt-3">
                            Mobile
                          </FormLabel>
                          <OutlinedInput
                            error={mobilenoerror}
                            type='number'
                            value={mobileno}
                            defaultValue={searchRedux.Location_mobile}
                            onChange={(e) => {
                              setmobileno(e.target.value);
                              setmobileerror(false)
                            }}
                            className="mt-1 sinput-border"
                          //   endAdornment={
                          //     <IconButton>
                          //       <LockOpenIcon />
                          //     </IconButton>
                          //   }
                          />
                          {mobilenoerror && <FormHelperText>This field is required</FormHelperText>}
                        </FormControl> */}


                        <FormControl error={mobilenoerror} fullWidth className="mt-4" sx={{  width: '100%' }} variant="outlined">
                        <FormLabel className="form-label">Mobile</FormLabel>
                              <OutlinedInput
                              error={mobilenoerror}
                              fullWidth
                              className="mt-1 sinput-border"
                                id="outlined-adornment-phone"
                                startAdornment={<InputAdornment position="start"><b>+61</b></InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  'aria-label': 'weight',
                                  'maxLength': 10,
                                }}
                               // type="number"
                                value={mobileno}
                                onChange={(e) =>
                               {   
                               //  setmobileno(e.target.value);
                                // setmobileerror(false)
                                handleMobileNumberChange(e)
                                }}
                              />
                            {mobilenoerror &&  
                            <FormHelperText id="outlined-weight-helper-text">
                             Invalid Mobile Number 
                             </FormHelperText>
                             } 

                              </FormControl>

                              
                      </div>

                      <div>
                        <FormControl fullWidth error={emailerror}>
                          <FormLabel className="form-label sinput-border password-input mt-3">
                            Email
                          </FormLabel>
                          <OutlinedInput
                            error={emailerror}
                            value={email}
                            defaultValue={searchRedux.Loginuseremail}
                            onChange={(e) => {
                              setemail(e.target.value);
                              setemailerror(false)
                            }}
                            className="mt-1 sinput-border"
                          //   endAdornment={
                          //     <IconButton>
                          //       <LockOpenIcon />
                          //     </IconButton>
                          //   }
                          />
                          {emailerror && <FormHelperText>This field is required</FormHelperText>}
                        </FormControl>
                      </div>

<div>
<FormControl fullWidth error={usernameerror}>
                          <FormLabel className="form-label mt-3">Username</FormLabel>

                          <OutlinedInput
                            error={usernameerror}
                            value={username}
                            onChange={(e) =>{ 
                              setusename(e.target.value);
                              setusenameerror(false)
                            }}
                            className="mt-1 sinput-border"
                            endAdornment={
                              <IconButton>
                                <PersonOutlineIcon />
                              </IconButton>
                            }
                          />
                        {usernameerror &&  <FormHelperText>This field is required</FormHelperText> }
                        </FormControl>
</div>
<div>
<FormControl fullWidth className="profile-input sinput-border mt-3">
                    <FormLabel className="form-label">Password</FormLabel>
                    <OutlinedInput
                      className="sinput-border"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyDown={(event) => {
                        console.log(event.ctrlKey)
                        if (event.key === 'Enter')
                        submit()
                      }}
                      sx={{
                        input: {
                          color: "#000000",
                          fontSize: "18px",
                          fontWeight: "900",
                          lineHeight: "normal",
                          "&::placeholder": {
                            opacity: 1,
                          },
                        },
                        label: { color: "blue" },
                      }}
                      endAdornment={
                        <IconButton>
                          <LockOpenIcon />
                        </IconButton>
                      }
                    />
                  </FormControl>
</div>
                    </form>
                  </div>
                  <div className="p-3">
                    <Button
                      onClick={() => submit()}
                      // color="secondary"
                      className="save-button"
                      variant="contained"
                    >
                      Update
                    </Button>

                  </div>
                </Box>

                <Box
                  component={Paper}
                  elevation={2}
                  p={0}
                  mt={1}
                  className="boxes"
                >
                  <div className="p-3 mt-3">
                    <Button
                      variant='contained'
                      fullWidth
                      className="p-3 app_set"
                      style={{
                        fontSize:18,
                        cursor:'auto'
                      }}
                    >
                      Appointments Settings
                    </Button>
                    <div className="mt-3">
                      <Button
                        onClick={() => navigate('/aptreason')}
                        variant='outlined'
                        fullWidth
                        className="p-3 link_button"
                        endIcon={<ArrowForwardIosIcon />}
                      >
                        <span>Appointments Reason</span>
                      </Button>
                    </div>
                    <div className="mt-3">
                      <Button
                        onClick={() => navigate('/blockedsession')}

                        variant='outlined'
                        fullWidth
                        className="p-3 link_button"
                        endIcon={<ArrowForwardIosIcon />}
                      >
                        <span>Appointments Session Block</span>
                      </Button>
                    </div>

                    <div className="mt-3">
                      <Button
                        onClick={() => navigate('/blockedpatient')}

                        variant='outlined'
                        fullWidth
                        className="p-3 link_button"
                        endIcon={<ArrowForwardIosIcon />}
                      >
                        <span> Block Patient</span>
                      </Button>
                    </div>



                  </div>
                </Box>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div className="settings_page coddgp_mobile_view" id={device}>
  <Container>
            <Row>
              <Col md={10} className="mx-auto">
                <Box
                  component={Paper}
                  elevation={2}
                  p={2}
                  mt={3}
                  className="boxes"
                >
                  <div
                    style={{
                      marginLeft: 20,
                      textAlign: 'center'
                    }}
                    className="user_photodiv d-flex flex-direction-row align-items-center">
                      {previewUrl ?
                        <Image
                        rounded
                        width={90}
                        height={90}
                        className="d-inline-flex align-left username object-fit-cover align-middle profile-img"
                        src={
                          previewUrl
                        }
                        //  src={require('../../assets/images/user1.png')}
                        roundedCircle
                      />
                     :
                   <Image
                      rounded
                      width={90}
                      height={90}
                      className="d-inline-flex align-left username object-fit-cover align-middle profile-img"
                      src={
                        searchRedux.ProfPhotoN
                          ? ImageUrl+searchRedux.ProfPhotoN
                          : require("../../assets/images/new_photo.png")
                      }
                      //  src={require('../../assets/images/user1.png')}
                      roundedCircle
                    />
}
                    <div className="align-items-center user_right">
                      {/* <Typography className="align-center user_name">{searchRedux.LoginuserName}</Typography> */}
                      {searchRedux.ProfPhotoN ?
                      
                      <Button
                        onClick ={Removephoto}
                        className="changephoto"
                        component="label"
                        variant='outlined'
                        size='small'
                        style={{
                          textTransform: 'capitalize'
                        }}
                      >Remove Photo
                        
                      </Button>
                      :
                      <Button
                      className="changephoto"
                      component="label"
                      variant='outlined'
                      size='small'
                      style={{
                        textTransform: 'capitalize'
                      }}
                    >Add Photo
                      <VisuallyHiddenInput
                        accept=".jpg, .png" // Specify accepted file types
                        onChange={handleFileInputChange}
                        type="file" />
                    </Button>
}
                    </div>
                    {/* {selectedFile && <p>Selected File: {selectedFile.name}</p>} */}
                  </div>

                  <div className="p-3 small_m">
                    <form>
                      <div>
                        <FormControl fullWidth error={usernameerror}>
                          <FormLabel className="form-label">Full Name</FormLabel>

                          <OutlinedInput
                          fullWidth
                            error={usernameerror}
                            value={username}
                            onChange={(e) => {
                              setusename(e.target.value);
                              setusenameerror(false)
                            }}
                            className="mt-1 sinput-border"
                          //   endAdornment={
                          //     <IconButton>
                          //       <PersonOutlineIcon />
                          //     </IconButton>
                          //   }
                          />
                          {usernameerror && <FormHelperText>This field is required</FormHelperText>}
                        </FormControl>
                      </div>

                      <div>
                      <FormControl error={mobilenoerror} fullWidth className="mt-4" sx={{ width: '100%' }} variant="outlined">
                      <FormLabel className="form-label">Mobile</FormLabel>
                              <OutlinedInput
                              error={mobilenoerror}
                              fullWidth
                              className="mt-1 sinput-border"
                                id="outlined-adornment-phone"
                                startAdornment={<InputAdornment position="start"><b>+61</b></InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  'aria-label': 'weight',
                                  'maxLength': 10,
                                }}
                               // type="number"
                                value={mobileno}
                                onChange={(e) =>
                               {   
                                // setmobileno(e.target.value);
                                // setmobileerror(false)
                                handleMobileNumberChange(e)
                                }}
                              />
                            {mobilenoerror &&  
                            <FormHelperText id="outlined-weight-helper-text">
                             Invalid Mobile Number 
                             </FormHelperText>
                             } 

                              </FormControl>
                      </div>

                      <div>
                        <FormControl fullWidth error={emailerror}>
                          <FormLabel className="form-label mt-3">
                            Email
                          </FormLabel>
                          <OutlinedInput
                            error={emailerror}
                            value={email}
                            defaultValue={searchRedux.Loginuseremail}
                            onChange={(e) => {
                              setemail(e.target.value);
                              setemailerror(false)
                            }}
                            className="mt-1 sinput-border"
                          //   endAdornment={
                          //     <IconButton>
                          //       <LockOpenIcon />
                          //     </IconButton>
                          //   }
                          />
                          {emailerror && <FormHelperText>This field is required</FormHelperText>}
                        </FormControl>
                      </div>
                      <div>
<FormControl fullWidth error={usernameerror}>
                          <FormLabel className="form-label mt-3">Username</FormLabel>

                          <OutlinedInput
                            error={usernameerror}
                            value={username}
                            onChange={(e) =>{ 
                              setusename(e.target.value);
                              setusenameerror(false)
                            }}
                            className="mt-1 sinput-border"
                            endAdornment={
                              <IconButton>
                                <PersonOutlineIcon />
                              </IconButton>
                            }
                          />
                        {usernameerror &&  <FormHelperText>This field is required</FormHelperText> }
                        </FormControl>
</div>
<div>
<FormControl fullWidth className="profile-input sinput-border mt-3">
                    <FormLabel className="form-label">Password</FormLabel>
                    <OutlinedInput
                      className="sinput-border"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      sx={{
                        input: {
                          color: "#000000",
                          fontSize: "18px",
                          fontWeight: "900",
                          lineHeight: "normal",
                          "&::placeholder": {
                            opacity: 1,
                          },
                        },
                        label: { color: "blue" },
                      }}
                      endAdornment={
                        <IconButton>
                          <LockOpenIcon />
                        </IconButton>
                      }
                    />
                  </FormControl>
</div>

                    </form>
                  </div>
                  <div className="p-3 small_up">
                    <Button
                      onClick={() => submit()}
                      // color="secondary"
                      className="save-button2"
                      variant="contained"
                    >
                      Update
                    </Button>

                  </div>
                </Box>

                <Box
                  component={Paper}
                  elevation={2}
                  p={0}
                  mt={1}
                  className="boxes"
                >
                  <div className="p-3 mt-3">
                    <Button
                      variant='contained'
                      fullWidth
                      className="p-3 app_set"
                      style={{
                        fontSize:18,
                        cursor:'auto'
                      }}
                    >
                      Appointments Settings
                    </Button>
                    <div className="mt-3" style={{ marginLeft: "0px" }}>
                      <Button
                        onClick={() => navigate('/aptreason')}
                        variant='outlined'
                        fullWidth
                        className="p-3 link_button"
                        endIcon={<ArrowForwardIosIcon />}
                      >
                        <span>Appointments Reason</span>
                      </Button>
                    </div>
                    <div className="mt-3" style={{ marginLeft: "0px" }}>
                      <Button
                        onClick={() => navigate('/blockedsession')}

                        variant='outlined'
                        fullWidth
                        className="p-3 link_button"
                        endIcon={<ArrowForwardIosIcon />}
                      >
                        <span>Appointments Session Block</span>
                      </Button>
                    </div>
                    <div className="mt-3" style={{ marginLeft: "0px" }}>
                      <Button
                        onClick={() => navigate('/blockedpatient')}

                        variant='outlined'
                        fullWidth
                        className="p-3 link_button"
                        endIcon={<ArrowForwardIosIcon />}
                      >
                        <span> Block Patient</span>
                      </Button>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </Fragment>
  );
};
