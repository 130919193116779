// ProtectedRoute.js
import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { PaymentState as SearchReduxState, useSelector } from "../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../redux/User/actions";
interface Props {
    component: React.ComponentType
    path?: string
    
  }

 export const ProtectedRoute: React.FC<Props> = ({ component: RouteComponent }) => {
  const { authenticated } = useAuth();
  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );

  if (searchRedux.userid) {
    return <RouteComponent />
  }
  else{
return  <Navigate to="/" replace />
  }
   
  
}

export default ProtectedRoute;