import {
    AppBar,
    Box,
    FormControl,
    OutlinedInput,
    Paper,
    Toolbar,
    Typography,
    Button,
    FormLabel,
    Stack,
    FormHelperText,
    useTheme,
    useMediaQuery,
    InputLabel,
    MenuItem,
    Select,
    CircularProgress,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Container, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getApi } from "../services";
import Checkbox from '@mui/material/Checkbox';
import UndoIcon from '@mui/icons-material/Undo';
import axios from "axios";
// Reducer Redux
import { PaymentState as SearchReduxState, useSelector } from "../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../redux/User/actions";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export const AppointmentReason: React.FC = () => {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const theme = useTheme();
    const navigate = useNavigate();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const dispatch = useDispatch();
    const searchRedux = useSelector<SearchReduxState>(
        (state) => state.paymentReducer
    );

    const [orderby,setOrderby] = React.useState('')
    const [seasonlth,setSeasonlth] = React.useState('')
    const [aptdata,setaptdata]= React.useState<any>([])
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = React.useState('');
    const [device,setDevice] = useState('')
    function isMobileDevice() {
      return /Mobi|Android|iPhone/i.test(navigator.userAgent);
    }
    
    useEffect(() =>{
      if (isMobileDevice()) {
       // console.log("This is a mobile device");
      } else {
       // console.log("This is not a mobile device");
        setDevice('msi_view')
      }
    },[])


const [sourceWidth, setSourceWidth] = useState<number>(0);
  const targetRef = useRef<HTMLDivElement>(null);

const handleResize = () => {
  if (targetRef.current) {
    const width = targetRef.current.offsetWidth;
    setSourceWidth(width);
   // console.log('width',width)
  }
};

useEffect(() => {
  handleResize()
  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, [aptdata]);


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>,idx:any) => {
      //  console.log(event.target.checked)
      setChecked(event.target.value);
      let data = [...aptdata]
      data[idx]={
        ...data[idx],
        Status: event.target.checked ? "1" : "0"
      }
      setaptdata(data)
      let data2 = {
        "list_id": data[idx].ListId,
        "status": event.target.checked ? "1" : "0"   
      }
      getapi.post("aptstatusupdate/", data2)
      .then((res) => {
     
    // console.log("response data",res);
    
      })
      .catch((error) => {
//console.log('status api error')
        console.log(error);
      });

    };

    const handleOrderbyChange = (event: React.ChangeEvent<HTMLInputElement>,idx:any) => {
        setOrderby(event.target.value);
       // console.log('name',event.target.name)
        let data = [...aptdata]
        data[idx]={
          ...data[idx],
          OrderList: event.target.value
        }
        setaptdata(data)
        let data2 = {
            "list_id": data[idx].ListId,
            "orderlist_id": event.target.value   
          }
          getapi.post("aptorderlistupdate/", data2)
          .then((res) => {
         
        // console.log("response data",res);
        
          })
          .catch((error) => {
            console.log('status api error')
            console.log(error);
          });


      };

    const handleSeasonChange = (event: React.ChangeEvent<HTMLInputElement>,idx:any) => {
        setSeasonlth(event.target.value);
        let data = [...aptdata]
        data[idx]={
          ...data[idx],
          SlotCount: event.target.value
        }
        setaptdata(data)

        let data2 = {
            "list_id": data[idx].ListId,
            "slot_count": event.target.value   
          }
          getapi.post("aptslotcountupdate/", data2)
          .then((res) => {
         
       //  console.log("response data",res);
        
          })
          .catch((error) => {
            console.log('status api error')
            console.log(error);
          });



      };

      


const getapi = getApi();

 const getData =()=>{
  setLoading(true)
    let data1 ={
        "location_id": searchRedux.location_id,
        "user_id": searchRedux.userid   
    }
    getapi.post("aptreasonlistbyuser/", data1)
      .then((res) => {
        setLoading(false)
   //  console.log("response data",res);
     setaptdata(res.data.data)
      })
      .catch((error) => {
        setLoading(false)
        console.log('api error')
        console.log(error);
      });
 



 }

 useEffect(()=>{
    getData()
 },[])


    return (
        <Fragment>

 
    
    {loading ? (
      <Row>
      <Col className="mx-auto" md={10}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "75vh",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                  </Col>
      </Row>
     ) :
     <Fragment>
      {!matches ?
    
    <Row>
    <Col className="mx-auto" md={10}>
   
    <div className="d-flex justify-content-end">
   
            <Button
                      className="text-white"
                      aria-label="open drawer"
                      onClick={() => navigate(-1)}
                     sx={{ height: "36px",minWidth:32,marginBottom:3 }}
                     color="secondary"
                     variant="contained"
                    >
                      <UndoIcon />
                    </Button>
                    </div>
            <Table className="apt_reason border-top-0" responsive striped bordered hover>
                <thead>
                    <tr>
                       
                        <th className="b-start"> <div className="appt_re" style={{width:150}}>Appointment Type</div></th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Order By</th>
                         <th className="b-end">Slot Count</th> 
                    </tr>
                </thead>
                <tbody>

                {aptdata.map((item:any,idx:any) =>
           
          

                    <tr>
                       
                        <td className="pl-2" style={{
                            paddingLeft:15,
                            width:150
                        }}>{item.ReasonName}</td>


                        <td className="text-center">
                        <Checkbox {...label}
                        value={item.Status}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                        color="secondary"
                        checked={item.Status === "1" ? true : false} 
                        onChange={(e) =>handleChange(e,idx)} name={item.Status}
                         />
                        </td>


                        <td className="text-center">
                            <form noValidate autoComplete="off">
                                <FormControl className="ftext-center" sx={{ width: '15ch',textAlign:'center' }}>
                                    <OutlinedInput 
                                    className="text-center"
                                    value={item.OrderList !== 100 ? item.OrderList :'' }
                                  // value={orderby}
                                    onChange={(e:any)=> handleOrderbyChange(e,idx)}
                                    name={item.ListId}
                                    />
                                </FormControl>
                            </form>

                        </td>
                         <td>

                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                 
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={item.SlotCount}
                                    label=""
                                    onChange={(e: any) => handleSeasonChange(e, idx)}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                </Select>
                            </FormControl>

    
                            {/* <form noValidate autoComplete="off">
                                <FormControl sx={{ width: '15ch' }}>
                                <OutlinedInput 
                                    value={item.SlotCount}
                                    onChange={(e:any) => handleSeasonChange(e,idx)}
                                    />
                                </FormControl>
                            </form> */}

                        </td> 
                    </tr>
                    )}
                   

                </tbody>
            </Table>
            </Col>
    </Row>
            :
            <Container>
              <Row>
                <Col className="m-3 mx-auto">
            <div className="coddgp_mobile_view">
              <div className="d-flex justify-content-end"  ref={targetRef}>
            <Button
                      className="text-white"
                      aria-label="open drawer"
                      onClick={() => navigate(-1)}
                     sx={{ height: "36px",minWidth:32 }}
                     color="secondary"
                     variant="contained"
                    >
                      <UndoIcon className="back_button"/>
                    </Button>
                    </div>
            <Box
                component={Paper}
                elevation={2}
                p={0}
                mt={1}
              
                sx={{backgroundColor:'transparent', width:!device ?sourceWidth : 'auto' }}
                className="boxes"
              >    
            <Table className="apt_reason border-top-0" responsive striped bordered hover>
                <thead>
                    <tr>
                       
                        <th className="b-start"> <div className="appt_re">Appointment Type</div></th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Order By</th>
                         <th className="b-end">Slot Count</th> 
                    </tr>
                </thead>
                <tbody>

                {aptdata.map((item:any,idx:any) =>
           
          

                    <tr>
                       
                        <td className="pl-2" style={{
                            paddingLeft:15,
                            
                        }}>{item.ReasonName}</td>


                        <td className="text-center">
                        <Checkbox {...label}
                        value={item.Status}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                        color="secondary"
                        checked={item.Status === "1" ? true : false} 
                        onChange={(e) =>handleChange(e,idx)} name={item.Status}
                         />
                        </td>


                        <td className="text-center">
                            <form noValidate autoComplete="off">
                                <FormControl className="ftext-center" sx={{textAlign:'center' }}>
                                    <OutlinedInput 
                                    className="text-center"
                                    value={item.OrderList !== 100 ? item.OrderList :'' }
                                  // value={orderby}
                                    onChange={(e:any)=> handleOrderbyChange(e,idx)}
                                    name={item.ListId}
                                    />
                                </FormControl>
                            </form>

                        </td>
                         <td>

                            <FormControl sx={{ m: 1, minWidth: 20 }} size="small">
                 
                                <Select
                                    labelId="demo-select-small-label"
                                    id="scount"
                                    value={item.SlotCount}
                                    label=""
                                    onChange={(e: any) => handleSeasonChange(e, idx)}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                </Select>
                            </FormControl>

    
                        
                        </td> 
                    </tr>
                    )}
                   

                </tbody>
            </Table>
            </Box>

            </div>
            </Col>
            </Row>
              </Container>
}
            </Fragment>
}
 



        </Fragment>
    )
}
