import { 
  Box,
  Container,
  FormControl,
  OutlinedInput,
  Paper,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getApi } from "../services";
import moment from "moment";
import { PaymentState as SearchReduxState, useSelector } from "../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../redux/User/actions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";


export const Predictor: React.FC = () => {
  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );

  /* new calander */
  const [fromdate, setfromdate] = React.useState(moment().format("YYYY-MM-DD"));

  const dispatch = useDispatch();
  const [data, setdata] = useState([]);
  const navigate = useNavigate();
  const getapi = getApi();
  // const [fromdate, setfromdate] = useState("");
  const [todate, settodate] = useState("");
  const [ppa, setppa] = useState("1");
  const [booking, setbooking] = useState("90");
  const [bookingerror, setbookingerror] = useState(false);
  const [ppaerror, setppaerror] = useState(false);

  const [openCalendarS, setOpenCalendarS] = useState(false);
  const [openCalendarE, setOpenCalendarE] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [nowDate, setnowDate] = useState(moment().format("YYYY-MM-DD"));

  const [selectedDate, setSelectedDate] = useState(moment());
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    setOpen(false); // Close the DatePicker after selecting a date
  };

  const toggleDatePicker = () => {
    setOpen(!open); // Toggle the DatePicker open/close state
  };
  useEffect(() => {
    const FromDate = moment().format("YYYY-MM-DD");
    const ToDate = moment().add("5", "days").format("YYYY-MM-DD");
    dispatch(UpdatePaymentData("FromDate", FromDate));
    dispatch(UpdatePaymentData("ToDate", ToDate));
  }, []);

  const handleInputClickS = () => {
    setOpenCalendarS(true);
  };
  const handleCalendarCloseS = () => {
    setOpenCalendarS(false);
  };

  const handleInputClickE = () => {
    setOpenCalendarE(true);
  };
  const handleCalendarCloseE = () => {
    setOpenCalendarE(false);
  };

  useEffect(() => {
    getData();
  }, [searchRedux.FromDate, searchRedux.ToDate]);

  const handleFocus = (e:any) => {
    e.preventDefault();
    e.target.focus({ preventScroll: true });
  };

  const getData = () => {
    // if (booking.trim() !== "" || ppa.trim() !== "") {
    //   setLoading(true);
    // }

    // if (booking.trim() === "" || ppa.trim() === "") {
    //   setbookingerror(booking.trim() === "");
    //   setppaerror(ppa.trim() === "");

    //   return;
    // }

    setbookingerror(false);
    setppaerror(false);

    let data = {
      apt_pre: booking,
      ppavg: ppa,
      userlocid: searchRedux.location_id,
      codd_fdate: moment(searchRedux.FromDate.toString()).format("YYYY-MM-DD")
        ? moment(searchRedux.FromDate.toString()).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),

      codd_tdate: moment(searchRedux.ToDate.toString()).format("YYYY-MM-DD")
        ? moment(searchRedux.ToDate.toString()).format("YYYY-MM-DD")
        : moment().add("5", "days").format("YYYY-MM-DD"),
      codd_duserid: searchRedux.userid,
    };

    getapi
      .post("halopredictornew/", data)
      .then((response) => {
        setLoading(false);
        //console.log("response data");
        //console.log(JSON.stringify(response.data.data));
        setdata(response.data.data);
        //console.log(data);
      })
      .catch((error) => {
        setLoading(false);
        //console.log(error);
        setbookingerror(false);
        setppaerror(false);
      });
  };
  return (
    <Fragment>
      {!matches ? (
        <div>
          <Container>
            <Row>
              <Col md={10} className="mx-auto">
                <Box
                  className="date_box"
                  component={Paper}
                  elevation={2}
                  p={2}
                  sx={{
                    backgroundColor: "primary.main",
                  }}
                >
                  <Row>
                    <Col md={4} className="mx-auto justify-content-center">
                      <div className="text-center align-item-center mb-2">
                        From Date
                      </div>
                      <div
                        className="text-center align-item-center"
                        // onClick={toggleDatePicker}
                      >
                        <DatePicker
                          minDate={moment(nowDate)}
                          closeOnSelect={true}
                          format="DD/MM/YYYY"
                          // open={open}
                          // onClose={() => setOpen(false)}
                          sx={{ borderRadius: "15px", fontWeight: 700 }}
                          className="align-item-center sidebar_datebicker d-flex text-center br-20"
                          //label="Controlled picker"
                          value={
                            moment(searchRedux.FromDate.toString())
                              ? moment(searchRedux.FromDate.toString())
                              : moment(fromdate)
                          }
                          onChange={(newValue: any) => {
                            dispatch(UpdatePaymentData("FromDate", newValue));
                            handleCalendarCloseS();
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={4} className="mx-auto justify-content-center">
                      <div className="text-center align-item-center mb-2">
                        To Date
                      </div>
                      <div
                        className="text-center align-item-center"
                        // onClick={()=> setOpen2(!open2)}
                      >
                        <DatePicker
                          minDate={
                            moment(searchRedux.FromDate.toString())
                              ? moment(searchRedux.FromDate.toString())
                              : moment().format("YYYY-MM-DD")
                          }
                          // // maxDate={moment(nowDate)
                          // }
                          closeOnSelect={true}
                          format="DD/MM/YYYY"
                          // open={open2}
                          // onClose={() => setOpen2(false)}
                          sx={{ borderRadius: "15px" }}
                          className="align-item-center sidebar_datebicker d-flex text-center br-20"
                          //label="Controlled picker"
                          value={
                            moment(searchRedux.ToDate.toString())
                              ? moment(searchRedux.ToDate.toString())
                              : moment().format("YYYY-MM-DD")
                          }
                          onChange={(newValue: any) => {
                            dispatch(UpdatePaymentData("ToDate", newValue));
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Box>

                {loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "75vh",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    {data.map((item: any, idx: number) => (
                      <div className="kpi-mt-4">
                        <Box
                          component={Paper}
                          elevation={2}
                          p={0}
                          mt={3}
                          className="boxes"
                        >
                          <div className="p-3">
                            <Table
                              style={{
                                marginBottom: 0,
                              }}
                            >
                              <tr>
                                <td>
                                  <Typography>
                                    <b>Appointments</b>
                                  </Typography>
                                </td>
                                <td>
                                  <Typography className="text-end">
                                    <b>{item.Appointments}</b>
                                  </Typography>
                                </td>
                              </tr>
                            </Table>
                          </div>
                        </Box>

                        <Box
                          component={Paper}
                          elevation={2}
                          p={0}
                          mt={1}
                          className="boxes"
                        >
                          <div className="p-3">
                            <Table
                              style={{
                                marginBottom: 0,
                              }}
                            >
                              <tr>
                                <td>
                                  <Typography>
                                    <b>Per Patient Average</b>
                                  </Typography>
                                </td>
                                <td>
                                  <Typography className="text-end">
                                    <b>{item.PerPatientAverage} </b>
                                  </Typography>
                                </td>
                              </tr>
                            </Table>
                          </div>
                        </Box>

                        <Box
                          component={Paper}
                          elevation={2}
                          p={0}
                          mt={1}
                          sx={{
                            backgroundColor: "primary.main",
                          }}
                          className="boxes"
                        >
                          <div className="p-3 blue_bg">
                            <Table
                              style={{
                                marginBottom: 0,
                              }}
                            >
                              <tr>
                                <td>
                                  <Typography>
                                    <b>Total Earnings</b>
                                  </Typography>
                                </td>
                                <td>
                                  <Typography className="text-end">
                                    <b>{item.TotalEarnings}</b>
                                  </Typography>
                                </td>
                              </tr>
                            </Table>
                          </div>
                        </Box>

                        <Box
                          component={Paper}
                          elevation={2}
                          p={0}
                          mt={1}
                          className="boxes"
                        >
                          <div className="p-3 d-flex justify-content-start flex-direction-column ">
                            <div>
                              <div className="text-left align-item-left pd-booking-search">
                                <Typography className="text-bold text-white mb-2">
                                  <b className="black-clr">Booking %</b>
                                </Typography>

                                <FormControl
                                  error={bookingerror}
                                  className="align-item-left d-flex text-left"
                                  fullWidth
                                >
                                  <OutlinedInput
                                    value={booking}
                                    onChange={(e) => {
                                      // setbooking(e.target.value);
                                      // setbookingerror(false);
                                      const inputValue = e.target.value;
                                      // Check if the input is empty
                                      if (inputValue === "") {
                                        setbooking(""); // Update state to empty string
                                        setbookingerror(false); // Reset error state
                                      } else if (/^\d*$/.test(inputValue)) {
                                        // Check if the input is a valid integer
                                        setbooking(inputValue); // Update state with valid integer value
                                        setbookingerror(false); // Reset error state
                                      } else {
                                        // Handle non-integer input, like showing an error
                                        setbookingerror(true); // Set error state to true
                                      }
                                    }}
                                    id="cnt_txt"
                                    size="small"
                                    fullWidth
                                    type="text"
                                    className="no-br-radis"
                                  />
                                </FormControl>
                                {bookingerror && (
                                  <div
                                    className="text-center m-2"
                                    style={{ color: "red" }}
                                  >
                                    <p>Please Enter Booking</p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                marginLeft: 10,
                                marginRight: 10,
                              }}
                            >
                              <div className="text-left align-item-left pl-5 pd-booking-search">
                                <Typography className="text-bold text-white mb-2">
                                  <b className="black-clr">PPA</b>
                                </Typography>

                                <FormControl
                                  error={ppaerror}
                                  className="align-item-left d-flex text-left"
                                  fullWidth
                                >
                                  <OutlinedInput
                                    value={ppa}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      // Check if the input is empty
                                      if (inputValue === "") {
                                        setppa(""); // Update state to empty string
                                        setppaerror(false); // Reset error state
                                      } else if (/^\d*$/.test(inputValue)) {
                                        // Check if the input is a valid integer
                                        setppa(inputValue); // Update state with valid integer value
                                        setppaerror(false); // Reset error state
                                      } else {
                                        // Handle non-integer input, like showing an error
                                        setppaerror(true); // Set error state to true
                                      }
                                    }}
                                    id="cnt_txt"
                                    size="small"
                                    fullWidth
                                    type='text'
                                  />
                                </FormControl>
                                {ppaerror && (
                                  <div
                                    className="text-center error m-2"
                                    style={{ color: "red" }}
                                  >
                                    <p>Please Enter PPA</p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div>
                              <div className="text-left align-item-left pt-4 mt-3">
                                <Button
                                  onClick={() => getData()}
                                  color="secondary"
                                  variant="contained"
                                >
                                  Change
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Box>

                        <Box
                          component={Paper}
                          elevation={2}
                          p={0}
                          mt={1}
                          className="boxes"
                        >
                          <div className="p-3">
                            <Table
                              style={{
                                marginBottom: 0,
                              }}
                            >
                              <tr>
                                <td>
                                  <Typography>
                                    <b>Appointments</b>
                                  </Typography>
                                </td>
                                <td>
                                  <Typography className="text-end">
                                    <b>{item.AppointmentsPerc}</b>
                                  </Typography>
                                </td>
                              </tr>
                            </Table>
                          </div>
                        </Box>

                        <Box
                          component={Paper}
                          elevation={2}
                          p={0}
                          mt={1}
                          className="boxes"
                        >
                          <div className="p-3">
                            <Table
                              style={{
                                marginBottom: 0,
                              }}
                            >
                              <tr>
                                <td>
                                  <Typography>
                                    <b>Per Patient Average</b>
                                  </Typography>
                                </td>
                                <td>
                                  <Typography className="text-end">
                                    <b>{item.PerPatientAveragePerc}</b>
                                  </Typography>
                                </td>
                              </tr>
                            </Table>
                          </div>
                        </Box>

                        <Box
                          component={Paper}
                          elevation={2}
                          p={0}
                          mt={1}
                          sx={{
                            backgroundColor: "primary.main",
                          }}
                          className="boxes"
                        >
                          <div className="p-3 blue_bg">
                            <Table
                              style={{
                                marginBottom: 0,
                              }}
                            >
                              <tr>
                                <td>
                                  <Typography>
                                    <b>Total Earnings</b>
                                  </Typography>
                                </td>
                                <td>
                                  <Typography className="text-end">
                                    <b>{item.TotalEarningsPerc}</b>
                                  </Typography>
                                </td>
                              </tr>
                            </Table>
                          </div>
                        </Box>
                      </div>
                    ))}
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div className="predictor-mobile-view m-3 coddgp_mobile_view mt-2">
          <Row>
            <Col md={12} className="mx-auto kpi-pad">
              <Box
                className="date_box"
                component={Paper}
                elevation={2}
                p={2}
                sx={{
                  backgroundColor: "primary.main",
                }}
              >
                <Row>
                  <Col xs={6}>
                    <div className="text-center date_name align-item-center mb-2">
                      From Date
                    </div>
                    <div>
                      <DatePicker
                        minDate={moment(nowDate)}
                        format="DD/MM/YYYY"
                        closeOnSelect={true}
                        sx={{ borderRadius: "15px", fontWeight: 700 }}
                        className="align-item-center sidebar_datebicker d-flex text-center br-20"
                        //label="Controlled picker"
                        value={
                          moment(searchRedux.FromDate.toString())
                            ? moment(searchRedux.FromDate.toString())
                            : moment(fromdate)
                        }
                        onChange={(newValue: any) => {
                          dispatch(UpdatePaymentData("FromDate", newValue));
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="text-center date_name align-item-center mb-2">
                      To Date
                    </div>
                    <div>
                      <DatePicker
                        minDate={
                          moment(searchRedux.FromDate.toString())
                            ? moment(searchRedux.FromDate.toString())
                            : moment().format("YYYY-MM-DD")
                        }
                        format="DD/MM/YYYY"
                        closeOnSelect={true}
                        sx={{ borderRadius: "15px" }}
                        className="align-item-center sidebar_datebicker d-flex text-center br-20"
                        //label="Controlled picker"
                        value={
                          moment(searchRedux.ToDate.toString())
                            ? moment(searchRedux.ToDate.toString())
                            : moment().add("5", "days")
                        }
                        onChange={(newValue: any) => {
                          dispatch(UpdatePaymentData("ToDate", newValue));
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Box>
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "75vh",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {data.map((item: any, idx: number) => (
                    <div>
                      <Box
                        component={Paper}
                        elevation={2}
                        p={0}
                        mt={3}
                        className="boxes custom-pd-pr"
                      >
                        <div className="p-2">
                          <Table
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            <tr>
                              <td>
                                <Typography>
                                  <b>Appointments</b>
                                </Typography>
                              </td>
                              <td>
                                <Typography className="text-end">
                                  <b>{item.Appointments}</b>
                                </Typography>
                              </td>
                            </tr>
                          </Table>
                        </div>
                      </Box>

                      <Box
                        component={Paper}
                        elevation={2}
                        p={0}
                        mt={1}
                        className="boxes custom-pd-pr"
                      >
                        <div className="p-2">
                          <Table
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            <tr>
                              <td>
                                <Typography>
                                  <b>Per Patient Average</b>
                                </Typography>
                              </td>
                              <td>
                                <Typography className="text-end">
                                  <b>{item.PerPatientAverage} </b>
                                </Typography>
                              </td>
                            </tr>
                          </Table>
                        </div>
                      </Box>

                      <Box
                        component={Paper}
                        elevation={2}
                        p={0}
                        mt={1}
                        sx={{
                          backgroundColor: "primary.main",
                        }}
                        className="boxes total-earn-pd"
                      >
                        <div className="p-2">
                          <Table
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            <tr>
                              <td>
                                <Typography className="text-white">
                                  <b>Total Earnings</b>
                                </Typography>
                              </td>
                              <td>
                                <Typography className="text-end text-white">
                                  <b>{item.TotalEarnings}</b>
                                </Typography>
                              </td>
                            </tr>
                          </Table>
                        </div>
                      </Box>

                      <Box
                        component={Paper}
                        elevation={2}
                        p={0}
                        mt={1}
                        // sx={{
                        //   backgroundColor: "primary.main",
                        // }}
                        className="boxes custom-pd-pr"
                      >
                        <div className="p-3 d-flex justify-content-start flex-direction-column ">
                          <form>
                          <Row className="book_txt">
                            <div className="col-4 col-book  pr-0">
                              <div className="text-left align-item-left">
                                <Typography className="text-bold text-black">
                                  <b>Booking %</b>
                                </Typography>
                                <FormControl
                                  className="align-item-left d-flex text-leftt mt-2"
                                  fullWidth
                                >
                                  <input
                                    type="text"
                                    value={booking}
                                    className="input-number-predictor"
                                    onFocus={handleFocus}
                                    onChange={(e) => {
                                      // setbooking(e.target.value);
                                      // setbookingerror(false);
                                      const inputValue = e.target.value;
                                      // Check if the input is empty
                                      if (inputValue === "") {
                                        setbooking(""); // Update state to empty string
                                        setbookingerror(false); // Reset error state
                                      } else if (/^\d*$/.test(inputValue)) {
                                        // Check if the input is a valid integer
                                        setbooking(inputValue); // Update state with valid integer value
                                        setbookingerror(false); // Reset error state
                                      } else {
                                        // Handle non-integer input, like showing an error
                                        setbookingerror(true); // Set error state to true
                                      }
                                    }}
                                    id="cnt_txt_booking"
                                  />
                                </FormControl>
                                {bookingerror && (
                                  <div
                                    className="text-center m-2"
                                    style={{ color: "red" }}
                                  >
                                    <p>Please Enter Booking</p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-4 pr-0">
                              <div className="text-left align-item-left pl-5">
                                <Typography className="text-bold text-black">
                                  <b>PPA</b>
                                </Typography>

                                <FormControl
                                  className="align-item-left d-flex text-left mt-2"
                                  fullWidth
                                >
                                  <input
                                    type="text"
                                    onFocus={handleFocus}
                                    value={ppa}
                                    className="input-number-predictor"
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      // Check if the input is empty
                                      if (inputValue === "") {
                                        setppa(""); // Update state to empty string
                                        setppaerror(false); // Reset error state
                                      } else if (/^\d*$/.test(inputValue)) {
                                        // Check if the input is a valid integer
                                        setppa(inputValue); // Update state with valid integer value
                                        setppaerror(false); // Reset error state
                                      } else {
                                        // Handle non-integer input, like showing an error
                                        setppaerror(true); // Set error state to true
                                      }
                                    }}
                                    id="cnt_txt_ppa"
                                  />
                                </FormControl>
                                {ppaerror && (
                                  <div
                                    className="text-center error m-2"
                                    style={{ color: "red" }}
                                  >
                                    <p>Please Enter PPA</p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-3 mt-2">
                              <div className="text-left ppabt align-item-left pt-4">
                                <Button
                                  onClick={() => getData()}
                                  color="secondary"
                                  variant="contained"
                                  className="pad-7"
                                >
                                  Change
                                </Button>
                              </div>
                            </div>
                          </Row>
                          </form>
                        </div>
                      </Box>

                      <Box
                        component={Paper}
                        elevation={2}
                        p={0}
                        mt={1}
                        className="boxes custom-pd-pr"
                      >
                        <div className="p-2">
                          <Table
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            <tr>
                              <td>
                                <Typography>
                                  <b>Appointments</b>
                                </Typography>
                              </td>
                              <td>
                                <Typography className="text-end">
                                  <b>{item.AppointmentsPerc}</b>
                                </Typography>
                              </td>
                            </tr>
                          </Table>
                        </div>
                      </Box>

                      <Box
                        component={Paper}
                        elevation={2}
                        p={0}
                        mt={1}
                        className="boxes custom-pd-pr"
                      >
                        <div className="p-2">
                          <Table
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            <tr>
                              <td>
                                <Typography>
                                  <b>Per Patient Average</b>
                                </Typography>
                              </td>
                              <td>
                                <Typography className="text-end">
                                  <b>{item.PerPatientAveragePerc}</b>
                                </Typography>
                              </td>
                            </tr>
                          </Table>
                        </div>
                      </Box>

                      <Box
                        component={Paper}
                        elevation={2}
                        p={0}
                        mt={1}
                        sx={{
                          backgroundColor: "primary.main",
                        }}
                        className="boxes"
                      >
                        <div className="p-2 total-earn-pd">
                          <Table
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            <tr>
                              <td>
                                <Typography className="text-white">
                                  <b>Total Earnings</b>
                                </Typography>
                              </td>
                              <td>
                                <Typography className="text-end text-white">
                                  <b>{item.TotalEarningsPerc}</b>
                                </Typography>
                              </td>
                            </tr>
                          </Table>
                        </div>
                      </Box>
                    </div>
                  ))}
                </>
              )}
            </Col>
          </Row>
        </div>
      )}
    </Fragment>
  );
};
