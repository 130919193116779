import {
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { getApi } from "../../services";
import moment from "moment";
import { PaymentState as SearchReduxState, useSelector } from "../../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../../redux/User/actions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import theme from "../../theme";
import { Col, Row, TabContainer, Table, Image } from "react-bootstrap";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import axios from "axios";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import UndoIcon from "@mui/icons-material/Undo";
const dstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  p: 3,
};

export const Healthoversevenfive: React.FC = () => {
  const doc = new jsPDF();

  const [loading, setLoading] = useState(false);

  const [mopen, setmOpen] = React.useState(false);
  const [msg, setMsg] = useState("");

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [data, setdata] = useState([]);
  const navigate = useNavigate();
  const getapi = getApi();
  const [mnumber, setmnumber] = useState("");
  const [dopen, setdOpen] = React.useState(false);
  const handledOpen = (phoneno: any) => {
    setmnumber(phoneno);
    setdOpen(true);
  };
  const handledClose = () => setdOpen(false);

  {
    /* get the diabetes data api */
  }

  const getData = async () => {
    var isDemo = 0;

    if (searchRedux.username == "codd.demo") {
      isDemo = 1;
    } else if (searchRedux.username == "codd.demo2") {
      isDemo = 1;
    } else if (searchRedux.username == "codd.demo3") {
      isDemo = 1;
    } else {
      isDemo = 0;
    }

    try {
      let data = {
        userlocid: searchRedux.location_id,
        codd_duserid: searchRedux.userid,
        isDemo: isDemo,
      };
      getapi
        .post("gethealthassoversfive/", data)
        .then((res) => {
          //console.log("response:->", res.data.data);
          setdata(res.data.data);
        })
        .catch((err) => {
          console.log("error", err);
        });
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  };

  const getLocationData = async () => {
    try {
      let data = {
        location_id: searchRedux.location_id,
      };
      getapi
        .post("practicedetails/", data)
        .then((res) => {
          //  console.log("location response:->", res.data.data);
          dispatch(
            UpdatePaymentData("Patients_phoneno", res.data.data[0].Phone_Number)
          );
        })
        .catch((err) => {
          console.log("error", err);
        });
    } catch (error) {
      // Handle errors
      // console.error('Error fetching data:', error);
    }
  };

  {
    /*send sms */
  }

  const sendsms = () => {
    handledClose();

    const trimmedNumber = mnumber.replace(/\s/g, "");

    let data = {
      ref_id: "201822315061111",
      loctn_id: searchRedux.location_id,
      usr_id: searchRedux.userid,
      // mobile_no: mnumber,
      mobile_no: "0451043429",
      text:
        "This is a kind reminder that you are due for your Cholesterol Review with your doctor. Please contact the reception to schedule your appointment.  Ph:" +
        searchRedux.Patients_phoneno,
      crt_by: "b.gibson",
    };
    // console.log(data);

    getapi
      .post("halosentsms/", data)
      .then((res) => {
        //console.log("logout response:->", res);

        if (res.data.Success) {
          setMsg(res.data.Message);
          // mnumberSet('61')
          // setText('')
          setmOpen(true);
        }
      })
      .catch((err) => {
        //console.log("error", err);
      });
  };

  useEffect(() => {
    getData();
    getLocationData();
    downloadAsExcelAPI();
  }, []);

  // useEffect(() =>{

  // console.log('searchRedux',searchRedux)
  // },[searchRedux])

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setmOpen(false);
  };

  {
    /* excel download */
  }

  const downloadAsExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const columnWidths = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }]; // Adjust the width as needed for each column
    worksheet["!cols"] = columnWidths;
    const workbook = XLSX.utils.book_new();
    // let Heading = [['Coddgp']];
    // XLSX.utils.sheet_add_aoa(worksheet, Heading, { origin: 'A1' });

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    const fileName =
      "Clinical_audit_ischemic_heart_disease-" +
      moment().format("YYYY-MM-DD") +
      ".xlsx";

    const downloadLink = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadLink;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(downloadLink);
  };

  {
    /*
  //using binary base64 
const downloadAsExcel = () => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const columnWidths = [{ wch: 20 },{ wch: 20 },{ wch: 20 },{ wch: 20 }]; // Adjust the width as needed for each column
  worksheet['!cols'] = columnWidths;
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  // Check if the browser is Internet Explorer
  if ((navigator as any).msSaveOrOpenBlob) {
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      (navigator as any).msSaveOrOpenBlob(blob, 'Clinical_audit_ischemic_heart_disease-' + moment().format('YYYY-MM-DD') + '.xlsx');
  } else {
      // Convert Excel buffer to Base64
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      const reader = new FileReader();
      reader.onload = () => {
        const excelDataUri = reader.result as string;
        const fileName = 'Clinical_audit_ischemic_heart_disease-' + moment().format('YYYY-MM-DD') + '.xlsx';
        const link = document.createElement('a');
        link.href = excelDataUri;
        console.log('excelDataUri',excelDataUri)
        link.download = fileName;
        link.click();
    };
      reader.readAsDataURL(blob);
  }
};
*/
  }
  const pdfdownload = () => {
    autoTable(doc, { html: "#diabetes_table" });
    doc.save("table.pdf");
  };

  const [excelfile, setexcelfile] = useState("");
  const [excelfilename, setexcelfilename] = useState("");
  const downloadAsExcelAPI = async () => {
    var isDemo = 0;

    if (searchRedux.username == "codd.demo") {
      isDemo = 1;
    } else if (searchRedux.username == "codd.demo2") {
      isDemo = 1;
    } else if (searchRedux.username == "codd.demo3") {
      isDemo = 1;
    } else {
      isDemo = 0;
    }

    try {
      let data = {
        userlocid: searchRedux.location_id,
        codd_duserid: searchRedux.userid,
        isDemo: isDemo,
      };
      getapi
        .post("haloexceloversevenfive/", data)
        .then((res) => {
          //  console.log("excel response:->", res.data.data);
          setexcelfile(res.data.data);
          getFileNameFromURL(res.data.data);
        })
        .catch((err) => {
          console.log("error", err);
        });
    } catch (error) {
      // Handle errors
      //  console.error('Error fetching data:', error);
    }
  };

  function getFileNameFromURL(excelfile: any) {
    // Split the URL by "/"
    const parts = excelfile.split("/");
    // Get the last part which contains the filename
    const filename = parts[parts.length - 1];
    setexcelfilename(filename.split("."));
    return filename;
  }

  const downloadAsExcelfile = () => {
    const url = excelfile;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", excelfilename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Fragment>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Fragment>
          {/* delete conformation modal */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={dopen}
            onClose={handledClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={dopen}>
              <Box sx={dstyle}>
                <div className="delet_btn text-end d-flex justify-content-end">
                  <CancelOutlinedIcon onClick={() => handledClose()} />
                </div>
                <div className="align-middle mt-2 no_app justify-content-center text-center">
                  <p className="pt-1 pb-1 no-text">
                    Are you sure you want to sent SMS?
                  </p>
                  <Row>
                    <Col>
                      <Button
                        onClick={() => sendsms()}
                        type="submit"
                        className="bbtn"
                        fullWidth
                        variant="contained"
                        sx={{
                          textTransform: "capitalize",
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        Yes
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className="cbtn"
                        fullWidth
                        variant="contained"
                        onClick={handledClose}
                        color="secondary"
                        sx={{
                          textTransform: "capitalize",
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        No
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Box>
            </Fade>
          </Modal>

          <Snackbar open={mopen} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              {msg}
            </Alert>
          </Snackbar>
          {!matches ? (
            <div className="web-profile-div settings_page">
              <Container>
                <Row>
                  <Col md={10} className="mx-auto">
                    {/*  <Row>
               <Col className='d-flex flex-row'>
                <Button
                      className="text-white"
                      aria-label="open drawer"
                      onClick={() => navigate(-1)}
                     sx={{ mr: 1,mt:1, height: "36px",minWidth:32 }}
                     color="secondary"
                     variant="contained"
                    >
                      <UndoIcon />
                    </Button>
                      <p className='d_title'>Ischemic heart disease</p>                        
                   
                </Col>
               
                <Col className='d-flex justify-content-end'>
                <Button
                onClick={downloadAsExcel}
                variant='contained'
                color='secondary'
                sx={{ width:100,height:40  }}
                >
                    Excel
                </Button>
        

                

                </Col>
               </Row> */}

                    <Row>
                      <Col className="d-flex mt-3 flex-row">
                        <p className="d_title heart">
                          Health Assessment over 75
                        </p>
                      </Col>

                      <Col className="d-flex mt-3 justify-content-end">
                        <Button
                          className="text-white  mt-3"
                          aria-label="open drawer"
                          onClick={() => navigate(-1)}
                          sx={{ mr: 1, mt: 1, height: "36px", minWidth: 32 }}
                          color="secondary"
                          variant="contained"
                        >
                          <UndoIcon />
                        </Button>
                        <Button
                          className="text-white  mt-3"
                          aria-label="open drawer"
                          onClick={downloadAsExcelfile}
                          sx={{ mt: 1, height: "36px", minWidth: 32 }}
                          color="secondary"
                          variant="contained"
                        >
                          <Image
                            src={require("../../assets/images/icons8-xls-32.png")}
                          />
                        </Button>
                      </Col>
                    </Row>

                    <Box
                      component={Paper}
                      elevation={2}
                      p={0}
                      mt={3}
                      className="boxes"
                    >
                      <Table
                        responsive
                        id="diabetes_table"
                        className="custom_table"
                      >
                        <thead
                          style={{
                            background: theme.palette.primary.main,
                          }}
                        >
                          <tr>
                            <th>Name</th>
                            <th>Mobile</th>
                            <th>DOB</th>

                            <th align="center" className="text-center">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((item: any, idx: any) => (
                            <tr>
                              <td>{item.NAME}</td>
                              <td>{item.MOBILEPHONE}</td>
                              <td>{item.DOB}</td>

                              <td align="center">
                                <Button
                                  onClick={() => handledOpen(item.MOBILEPHONE)}
                                  variant="contained"
                                  color="primary"
                                >
                                  Send SMS
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Box>
                  </Col>
                </Row>
              </Container>
            </div>
          ) : (
            <div className="coddgp_mobile_view settings_page">
              <Container>
                <Row>
                  <Col md={10} className="mx-auto">
                    <Row>
                      <Col xs={8} className="d-flex mt-3 flex-row">
                        <p className="d_title heart">
                          Health Assessment over 75
                        </p>
                      </Col>

                      <Col className="d-flex mt-3 justify-content-end">
                        <Button
                          className="text-white i_back mt-3"
                          aria-label="open drawer"
                          onClick={() => navigate(-1)}
                          sx={{ mt: 1, mr: 1, height: "36px", minWidth: 32 }}
                          color="secondary"
                          variant="contained"
                        >
                          <UndoIcon className="back_button" />
                        </Button>
                        <Button
                          className="text-white i_back mt-3"
                          aria-label="open drawer"
                          onClick={downloadAsExcelfile}
                          sx={{ mt: 1, height: "36px", minWidth: 32 }}
                          color="secondary"
                          variant="contained"
                        >
                          <Image
                            src={require("../../assets/images/icons8-xls-32.png")}
                          />
                        </Button>
                      </Col>
                    </Row>

                    <Box
                      component={Paper}
                      elevation={2}
                      p={0}
                      mt={3}
                      className="boxes"
                    >
                      <Table
                        responsive
                        id="diabetes_table"
                        className="custom_table"
                      >
                        <thead
                          style={{
                            background: theme.palette.primary.main,
                          }}
                        >
                          <tr>
                            <th>Name</th>
                            <th>Mobile</th>
                            {/* <th>
                DOB
            </th> */}

                            <th align="center" className="text-center">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((item: any, idx: any) => (
                            <tr>
                              <td>{item.NAME}</td>
                              <td>{item.MOBILEPHONE}</td>
                              {/* <td>
                {item.DOB}
            </td> */}

                              <td align="center">
                                <Button
                                  onClick={() => handledOpen(item.MOBILEPHONE)}
                                  variant="contained"
                                  color="primary"
                                >
                                  SMS
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Box>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
