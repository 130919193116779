import {
    Box,
    Container,
    FormControl,
    OutlinedInput,
    Paper,
    Button,
    FormLabel,
    FormHelperText,
    TextField,
    Autocomplete,
  } from "@mui/material";
  import React, { Fragment, useEffect, useState } from "react";
  import { Col, Row, Table } from "react-bootstrap";
  import IconButton from "@mui/material/IconButton";
  import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
  import LockOpenIcon from "@mui/icons-material/LockOpen";
  import useMediaQuery from "@mui/material/useMediaQuery";
  import { useTheme } from "@mui/material/styles";
  import "bootstrap/dist/css/bootstrap.min.css";
  import { getApi } from "../services";
  import Snackbar from "@mui/material/Snackbar";
  import MuiAlert, { AlertProps } from "@mui/material/Alert";
  // Reducer Redux
  import { PaymentState as SearchReduxState, useSelector } from "../redux";
  import { useDispatch } from "react-redux";
  import { UpdatePaymentData } from "../redux/User/actions";
  
  export const LoginUser: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const dispatch = useDispatch();
    const searchRedux = useSelector<SearchReduxState>(
      (state) => state.paymentReducer
    );
    const [username, setusename] = useState(searchRedux.username);
    const [password, setPassword] = useState(searchRedux.password);
  
    const [usernameerror, setusenameerror] = useState(false);
    const [passworderror, setPassworderror] = useState(false);
  
    const [listdata,setlistdata] = useState([])
    const [userdata,setuserdata] = useState([])
    const [selectedLocation, setSelectedLocation] = React.useState(null);
    const [selectedUser, setSelectedUser] = React.useState<{ UserId: string; LocationId: string } | null>(null);
    

    const getapi = getApi();
  
    const [open, setOpen] = React.useState(false);
    const [msg, setMsg] = useState("");
  
    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
      props,
      ref
    ) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
  
    const handleClose = (
      event?: React.SyntheticEvent | Event,
      reason?: string
    ) => {
      if (reason === "clickaway") {
        return;
      }
  
      setOpen(false);
    };
  
    const getuserdata = async (userlocid:any) => {
        setSelectedUser(null)
        try {
            let data ={
                userlocid :userlocid
            }
          const res = await getapi.post("loginuserlist/",data);
          console.log("loginuserlist response:->", res.data.data);
          setuserdata(res.data.data)
        } catch (err) {
          // Handle the error here
          console.error("Error:", err);
        }
      };
  
  
    const loginpracticelist = async () => {
        try {
          const res = await getapi.post("loginpracticelist/");
          console.log("loginpracticelist response:->", res);
          setlistdata(res.data.data)
        } catch (err) {
          // Handle the error here
          console.error("Error:", err);
        }
      };
  
useEffect(() =>{
    loginpracticelist()
},[])

// useEffect(()=>{
//     console.log('selectedUser',selectedUser?.UserId)
// },[selectedUser])

// useEffect(()=>{
// console.log('searchRedux',searchRedux)
// },[searchRedux])

const submit = () => {
    try {
      if (selectedUser) {
        dispatch(UpdatePaymentData("userid", selectedUser.UserId));
        dispatch(UpdatePaymentData("location_id", selectedUser.LocationId));
      }
      setOpen(true);
    } catch (error) {
      console.error('Error occurred while updating data:', error);
      // Handle the error here, if needed
    }
  };
  



    return (
      <Fragment>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
            Updated Successfully
          </Alert>
        </Snackbar>
  
        {!matches ? (
          <div className="web-profile-div">
            <Container>
              <Row>
                <Col md={10} className="mx-auto">
                  <Box
                    component={Paper}
                    elevation={2}
                    p={2}
                    mt={3}
                    className="boxes"
                  >
                    <div className="p-3">
                      <form>
                        <div style={{
                            marginBottom:15
                        }}>
                        <Autocomplete
      value={selectedLocation}
      onChange={(event, newValue:any) => {
        setSelectedLocation(newValue);
        console.log(newValue.LocationId)
        getuserdata(newValue.LocationId)
      }}
      options={listdata}
      getOptionLabel={(option:any) => option.LocationName}
      renderInput={(params) => <TextField {...params} label="Location Name" />}
    />
                        </div>
  
                        <div>
                        
                        <Autocomplete
      value={selectedUser}
      onChange={(event, newValue) => {
        setSelectedUser(newValue);
      }}
      options={userdata}
      getOptionLabel={(option:any) => option.Name || ""}
      renderInput={(params) => <TextField {...params} label="User" />}
    />
                        </div>
                      </form>
                    </div>
                    <div className="p-3">
                      <Button
                        onClick={() => submit()}
                        color="secondary"
                        className="save-button"
                        variant="contained"
                      >
                        Submit
                      </Button>
                     
                    </div>
                  </Box>
   
                
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          <div className="profile-mobile-view coddgp_mobile_view kpi-pad">
            <Row>
              <Col md={10}>
                <div className="mt-3">
                  
                  <form>
                        <div style={{
                            marginBottom:15
                        }}>
                       <Autocomplete
      value={selectedLocation}
      onChange={(event, newValue:any) => {
        setSelectedLocation(newValue);
        console.log(newValue.LocationId)
        getuserdata(newValue.LocationId)
      }}
      options={listdata}
      getOptionLabel={(option:any) => option.LocationName}
      renderInput={(params) => <TextField {...params} label="Location Name" />}
    />
                        </div>
  
                        <div>
                        <Autocomplete
      value={selectedUser}
      onChange={(event, newValue) => {
        setSelectedUser(newValue);
      }}
      options={userdata}
      getOptionLabel={(option:any) => option.Name || ""}
      renderInput={(params) => <TextField {...params} label="User" />}
    />
                        </div>
                      </form>
                  
                </div>
                <div className="mt-3">
                  <Button
                    onClick={() => submit()}
                    color="secondary"
                    className="save-button"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </div>
               
  
            
              </Col>
            </Row>
          </div>
        )}
      </Fragment>
    );
  };
  