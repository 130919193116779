import {
    Box,
    Container,
    Paper,
    Typography,
    Button,
    Stack,
  } from "@mui/material";
  import React, { Fragment, useState } from "react";
  import { Col, Row, Table } from "react-bootstrap";
  import { useNavigate } from "react-router-dom";
  import CircleIcon from "@mui/icons-material/Circle";
  import useMediaQuery from "@mui/material/useMediaQuery";
  import { useTheme } from "@mui/material/styles";
  import "bootstrap/dist/css/bootstrap.min.css";
  import { getApi } from "../services";
  import Snackbar from "@mui/material/Snackbar";
  import MuiAlert, { AlertProps } from "@mui/material/Alert";
  // Reducer Redux
  import { PaymentState as SearchReduxState, useSelector } from "../redux";
  import { useDispatch } from "react-redux";
  import { UpdatePaymentData } from "../redux/User/actions";
  
  export const ColorCodes: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const dispatch = useDispatch();
    const searchRedux = useSelector<SearchReduxState>(
      (state) => state.paymentReducer
    );

  
  
    const getapi = getApi();
  
    const [open, setOpen] = React.useState(false);
    const [msg, setMsg] = useState("");
  
    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
      props,
      ref
    ) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
  
    const handleClose = (
      event?: React.SyntheticEvent | Event,
      reason?: string
    ) => {
      if (reason === "clickaway") {
        return;
      }
  
      setOpen(false);
    };
  

   
  
    return (
      <Fragment>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
            {msg}
          </Alert>
        </Snackbar>
  
        {!matches ? (
          <div className="web-profile-div" style={{
            height:'90vh'
          }}>
            <Container>
              <Row>
                <Col md={10} className="mx-auto">
           
                  <Box
                    component={Paper}
                    elevation={2}
                    p={0}
                    mt={1}
                    className="boxes"
                  >
                    <div className="p-3 mt-3">
                        <div className="text-right align-item-right">
                      
                      <Button
                        color="primary"
                        variant='contained'
                        onClick={()=>navigate('/sidebar')}
                        >
                            Close
                        </Button>
                        </div>

                      <div className="profile-div mt-3">
                        <Typography>Clinical Color Code</Typography>
                      </div>
  
                      <div className="mt-3" style={{ marginLeft: "7px" }}>
                        <Stack
                          className="mt-3"
                          direction="row"
                          alignItems="center"
                          gap={3}
                        >
                          <CircleIcon style={{ color: "#4666ae" }} />
                          <Typography variant="body1">
                            Due for Management Plan
                          </Typography>
                        </Stack>
                        <Stack
                          className="mt-3"
                          direction="row"
                          alignItems="center"
                          gap={3}
                        >
                          <CircleIcon style={{ color: "#89BCE5" }} />
                          <Typography variant="body1">
                            Due for Management Plan Review
                          </Typography>
                        </Stack>
                        <Stack
                          className="mt-3 mb-3"
                          direction="row"
                          alignItems="center"
                          gap={3}
                        >
                          <CircleIcon style={{ color: "#58C18E" }} />
                          <Typography variant="body1">
                            Due for Health Assessment
                          </Typography>
                        </Stack>
                        <Stack
                          className="mt-3 mb-2"
                          direction="row"
                          alignItems="center"
                          gap={3}
                        >
                          <CircleIcon style={{ color: "#EFFB7B" }} />
                          <Typography variant="body1">Due for 10997</Typography>
                        </Stack>
                      </div>
                    </div>
                  </Box>
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          <div className="profile-mobile-view coddgp_mobile_view p-3">
            <Row>
              <Col md={10}>
             
                <Box
                  component={Paper}
                  elevation={2}
                  p={0}
                  mt={1}
                  className="boxes"
                >
                  <div className="p-3 mt-1">
                  <div className="text-right align-item-right mb-3">
                      
                      <Button
                        color="primary"
                        variant='contained'
                        onClick={()=>navigate('/sidebar')}
                        >
                            Close
                        </Button>
                        </div>


                    <div className="profile-div">
                      <Typography className="text-center color-code-txt">
                        Clinical Color Code
                      </Typography>
                    </div>
  
                    <div className="mt-3" style={{ marginLeft: "7px" }}>
                      <Stack
                        className="mt-3"
                        direction="row"
                        alignItems="center"
                        gap={3}
                      >
                        <CircleIcon style={{ color: "#4666ae" }} />
                        <Typography variant="body1">
                          Due for Management Plan
                        </Typography>
                      </Stack>
                      <Stack
                        className="mt-3"
                        direction="row"
                        alignItems="center"
                        gap={3}
                      >
                        <CircleIcon style={{ color: "#89BCE5" }} />
                        <Typography variant="body1">
                          Due for Management Plan Review
                        </Typography>
                      </Stack>
                      <Stack
                        className="mt-3"
                        direction="row"
                        alignItems="center"
                        gap={3}
                      >
                        <CircleIcon style={{ color: "#58C18E" }} />
                        <Typography variant="body1">
                          Due for Health Assessment
                        </Typography>
                      </Stack>
                      <Stack
                        className="mt-3"
                        direction="row"
                        alignItems="center"
                        gap={3}
                      >
                        <CircleIcon style={{ color: "#EFFB7B" }} />
                        <Typography variant="body1">Due for 10997</Typography>
                      </Stack>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </div>
        )}
      </Fragment>
    );
  };
  