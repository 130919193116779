import {
  AppBar,
  Box,
  Container,
  FormControl,
  OutlinedInput,
  Paper,
  Toolbar,
  Typography,
  Button,
  TextField,
  InputAdornment,
  InputLabel,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Col, FormLabel, Row, Table, Image } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Datepicker } from "../components/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import Search from "@mui/icons-material/Search";
import { getApi } from "../services";
import theme from "./../theme";
import moment from "moment";
import { PaymentState as SearchReduxState, useSelector } from "../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../redux/User/actions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import { BiSolidUpArrow } from "react-icons/bi";
import { BiSolidDownArrow } from "react-icons/bi";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
export const ItemNumber: React.FC = () => {
  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );

  const [selectedDate, setSelectedDate] = useState(moment());
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    setOpen(false); // Close the DatePicker after selecting a date
  };

  const toggleDatePicker = () => {
    setOpen(!open); // Toggle the DatePicker open/close state
  };

  const datePickerRef = useRef(null);

  const dispatch = useDispatch();
  const [data, setdata] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const getapi = getApi();
  const [searchTerm, setSearchTerm] = useState("");
  // const [fromdate, setfromdate] = useState("");
  const [todate, settodate] = useState("");
  const [openCalendarS, setOpenCalendarS] = useState(false);
  const [openCalendarE, setOpenCalendarE] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(true);
  const [iorder, setIorder] = useState<"init" | "ASC" | "DESC">("ASC");
  const [corder, setCorder] = useState<"init" | "ASC" | "DESC">("ASC");
  const [border, setBorder] = useState<"init" | "ASC" | "DESC">("ASC");

  /* new calander */
  const [fromdate, setfromdate] = React.useState(moment().format("YYYY-MM-DD"));
  const [nowDate, setnowDate] = useState(moment().format("YYYY-MM-DD"));
  // search bax
  const [searchQuery, setSearchQuery] = React.useState("");
  const [arrayholder, setarrayholder] = useState([]);
  const [filterValue, setFilterValue] = useState("");

  const [filteredData, setFilteredData] = useState([]); // Store filtered data

  const handleChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleInputClickS = () => {
    setOpenCalendarS(true);
  };
  const handleCalendarCloseS = () => {
    setOpenCalendarS(false);
  };

  const handleInputClickE = () => {
    setOpenCalendarE(true);
  };
  const handleCalendarCloseE = () => {
    setOpenCalendarE(false);
  };

  const inputasc = (key: any) => {
    //console.log("asc called");

    if (key === "count") {
      setCorder("DESC");
      const res1 = arrayholder?.sort((a: any, b: any) => {
        return parseInt(a?.AnaCount) - parseInt(b?.AnaCount);
      });
      setdata([...res1]);
      //console.log("count ASC:", res1);
    }
    if (key === "item") {
      setIorder("DESC");
      const res1 = arrayholder?.sort((a: any, b: any) => {
        return parseInt(a?.AnaItemNumber) - parseInt(b?.AnaItemNumber);
      });
      setdata([...res1]);
      //console.log("item ASC:", res1);
    }
    if (key === "billing") {
      setBorder("DESC");
      const res1 = arrayholder?.sort((a: any, b: any) => {
        return (
          parseInt(a?.AnaBillings.replace(/\$/g, "")) -
          parseInt(b?.AnaBillings.replace(/\$/g, ""))
        );
      });
      setdata([...res1]);
      //console.log("billing ASC:", res1);
    }
  };

  const inputdesc = (key: any) => {
    //console.log("des called");
    if (key === "count") {
      setCorder("ASC");
      const res1 = arrayholder?.sort((a: any, b: any) => {
        return parseInt(b?.AnaCount) - parseInt(a?.AnaCount);
      });
      setdata([...res1]);
      //console.log("count ASC:", res1);
    }
    if (key === "item") {
      setIorder("ASC");
      const res1 = arrayholder?.sort((a: any, b: any) => {
        return parseInt(b?.AnaItemNumber) - parseInt(a?.AnaItemNumber);
      });
      setdata([...res1]);
      //console.log("item ASC:", res1);
    }
    if (key === "billing") {
      setBorder("ASC");
      const res1 = arrayholder?.sort((a: any, b: any) => {
        return (
          parseInt(b?.AnaBillings.replace(/\$/g, "")) -
          parseInt(a?.AnaBillings.replace(/\$/g, ""))
        );
      });
      setdata([...res1]);
      //console.log("billing desending:", res1);
    }
  };

  const SearchData = (value: any) => {
    //console.log("search key", value);
    if (value.trim() === "") {
      //console.log("value is empty");
      getdata();
    } else {
      const res = arrayholder?.filter((item: any) => {
        return item.AnaItemNumber == value;
      });

      //console.log("search results", res);
      if (res.length > 0) {
        setdata(res);
      } else {
        // Reset data when no search results are found
        //console.log("search else part called");
        // alert('data empty')
        setdata([]);
      }
    }
  };

  useEffect(() => {
    getdata();
  }, [searchRedux.FromDate, searchRedux.ToDate]);

  const getdata = () => {
    setLoading(true);
    let data = JSON.stringify({
      userlocid: searchRedux.location_id,
      codd_fdate: moment(searchRedux.FromDate.toString()).format("YYYY-MM-DD")
        ? moment(searchRedux.FromDate.toString()).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),

      codd_tdate: moment(searchRedux.ToDate.toString()).format("YYYY-MM-DD")
        ? moment(searchRedux.ToDate.toString()).format("YYYY-MM-DD")
        : moment().add("5", "days").format("YYYY-MM-DD"),
      codd_duserid: searchRedux.userid,
    });
    //console.log("send data", data);
    getapi
      .post("haloitemnumber/", data)
      .then((response) => {
        setLoading(false);
        // //console.log("response data");
        //console.log(JSON.stringify(response.data.data));
        setdata(response.data.data);
        //console.log(data);
        setarrayholder(response.data.data);
        setFilteredData(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        //console.log(error);
      });
  };

  // const SearchData = (value: any) => {

  //   const res = arrayholder?.filter((item: any) => {
  //     if (item.AnaItemNumber.includes(value)) {
  //       //console.log("item", item);
  //       return item;
  //     }
  //   });

  //   //console.log("search key", JSON.stringify(value));
  //   //console.log("search results", JSON.stringify(res));

  //   setdata(res);
  // };

  const [Aopen, setAOpen] = useState(false);


  /* device checking function */

const [loginfrom,setLoginFrom] = useState('')
function isMobileDevice() {
  // Check if the user agent contains 'Mobi', 'Android', or 'iPhone'
  // and also check if it's not running in an MSI environment
  return /Mobi|Android|iPhone/i.test(navigator.userAgent) && !/msi/i.test(navigator.userAgent);
}
function isMSI() {
  // Check if the user agent contains 'msi'
  return /msi/i.test(navigator.userAgent);
}

function isDesktop() {
  // Check if the platform is not 'iPhone', 'iPad', 'iPod', 'Android', or 'Mobile'
  return !/(iPhone|iPad|iPod|Android|Mobile)/i.test(navigator.userAgent);
}
useEffect(() =>{
  if (isMobileDevice()) {
   // console.log("This is a mobile device");
    setLoginFrom('mobile_view')
  }
  else if (isMSI()){
   // console.log("This app is running in an MSI environment.");
    setLoginFrom('msi_view')
  }
  else if (isDesktop()){
   // console.log("This website is running on a desktop.");
    setLoginFrom('msi_view')
  }
  else{
   // console.log("This website is running on a other.");
  }
},[])

useEffect(()=>{
  console.log('loginfrom',loginfrom)
})

  return (
    <Fragment>
      {!matches ? (
        <div className="item-web">
          <Container>
            <Row>
              <Col md={10} className="mx-auto">
                <Box
                  className="date_box"
                  component={Paper}
                  elevation={2}
                  p={2}
                  sx={{
                    backgroundColor: "primary.main",
                  }}
                >
                  <Row>
                    <Col md={4} className="mx-auto justify-content-center">
                      <div className="text-center align-item-center mb-2">
                        From Date
                      </div>
                      <div
                        className="text-center align-item-center"
                        // onClick={ () => setOpen(!open)}
                      >
                        <DatePicker
                          ref={datePickerRef}
                          // slotProps={{
                          // previousIconButton: {
                          //     onClick:(()=>{
                          //       setAOpen(true);
                          //       //console.log('arrow clicked')
                          //       const previousMonthDate =   moment(searchRedux.FromDate.toString())
                          //       ? moment(searchRedux.FromDate.toString()).subtract(1, 'month')
                          //       : moment(fromdate).subtract(1, 'month');

                          //       dispatch(UpdatePaymentData("FromDate", previousMonthDate));
                          //     }), // Add your custom click handler
                          //    },
                          // }}
                          maxDate={moment(nowDate)}
                          //  closeOnSelect={true}
                          format="DD/MM/YYYY"
                          //  open={Aopen ? Aopen : open}
                          //  onClose={() => setAOpen(false)}
                          sx={{ borderRadius: "15px", fontWeight: 700 }}
                          className="align-item-center sidebar_datebicker d-flex text-center br-20"
                          //label="Controlled picker"
                          value={
                            moment(searchRedux.FromDate.toString())
                              ? moment(searchRedux.FromDate.toString())
                              : moment(fromdate)
                          }
                          onChange={(newValue: any) => {
                            dispatch(UpdatePaymentData("FromDate", newValue));
                            handleCalendarCloseS();
                            setAOpen(false);
                          }}

                          // onSelectedSectionsChange={()=> //console.log('test test')}
                        />
                      </div>
                    </Col>
                    <Col md={4} className="mx-auto justify-content-center">
                      <div className="text-center align-item-center mb-2">
                        To Date
                      </div>
                      <div
                        className="text-center align-item-center"
                        // onClick={()=> setOpen2(!open2)}
                      >
                        <DatePicker
                          minDate={
                            moment(searchRedux.FromDate.toString())
                              ? moment(searchRedux.FromDate.toString())
                              : moment().format("YYYY-MM-DD")
                          }
                          maxDate={moment(nowDate)}
                          // closeOnSelect={true}
                          format="DD/MM/YYYY"
                          // open={open2}
                          // onClose={() => setOpen2(false)}
                          sx={{ borderRadius: "15px" }}
                          className="align-item-center sidebar_datebicker d-flex text-center br-20"
                          //label="Controlled picker"
                          value={
                            moment(searchRedux.ToDate.toString())
                              ? moment(searchRedux.ToDate.toString())
                              : moment().format("YYYY-MM-DD")
                          }
                          onChange={(newValue: any) => {
                            dispatch(UpdatePaymentData("ToDate", newValue));
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Box>

                <div className="kpi-mt-4">
                  <Row className="mt-4">
                    <Col md={4}>
                      <Button
                        onClick={() => navigate("/mbs-review")}
                        sx={{ p: 1.5 }}
                        fullWidth
                        variant="contained"
                        color="secondary"
                      >
                        MBS REVIEW
                      </Button>
                    </Col>
                    <Col>
                      <FormControl
                        fullWidth
                        style={{
                          background: "#fff",
                        }}
                        variant="outlined"
                      >
                        <OutlinedInput
                          // value={filterValue}
                          onChange={(e) => {
                            setSearchQuery(e.target.value);

                            SearchData(e.target.value);
                          }}
                          value={searchQuery}
                          fullWidth
                          placeholder="Search"
                          id="outlined-adornment-search"
                          sx={{
                            input: {
                              color: "#4666AE",
                              fontSize: "15px",
                              fontWeight: "400",
                              opacity: "0.6",
                              marginLeft: "5px",
                              "&::placeholder": {
                                // <----- Add this.
                                opacity: 1,
                              },
                            },
                          }}
                          startAdornment={
                            <InputAdornment position="end">
                              <Search
                                style={{ color: "#4666AE", width: "33px" }}
                              />
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Col>
                  </Row>
                  <Box
                    component={Paper}
                    elevation={2}
                    p={0}
                    mt={3}
                    className="boxes"
                  >
                    <div className="item_container p-0 web-item-contain">
                      {searchQuery && data.length === 0 ? (
                        <Box
                          className="date_box  p-5 justify-content-center text-center align-item-center mt-0"
                          component={Paper}
                          elevation={2}
                          p={2}
                          sx={{
                            borderRadius: "10px",
                          }}
                        >
                          <Image
                            style={{
                              margin: 10,
                            }}
                            src={require("../assets/images/Datanotfound.png")}
                            width="216"
                            height="194"
                          />
                          <Typography className="not_fond">
                            Data Not Found
                          </Typography>
                        </Box>
                      ) : (
                        <>
                          {loading ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "75vh",
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          ) : (
                            <Table
                              responsive
                              bordered
                              className="item_numnbertable"
                              style={{
                                marginBottom: 0,
                                width: "100%",
                              }}
                            >
                              <thead
                                style={{
                                  background: theme.palette.primary.main,
                                }}
                              >
                                <tr
                                  style={{
                                    background: theme.palette.primary.main,
                                  }}
                                >
                                  <th
                                    className="item"
                                    scope="col"
                                    style={{
                                      background: theme.palette.primary.main,
                                    }}
                                  >
                                    <Row>
                                      <Col md={10} className="text-center">
                                        <a
                                          href="#"
                                          className="item_number text-center"
                                        >
                                          Item Number
                                        </a>
                                      </Col>
                                      <Col md={2}>
                                        {iorder === "init" && (
                                          <Row className="text-right arrow">
                                            <Col>
                                              <ArrowDropUpIcon
                                                onClick={() => inputasc("item")}
                                              />
                                              <ArrowDropDownIcon
                                                onClick={() =>
                                                  inputdesc("item")
                                                }
                                              />
                                            </Col>
                                          </Row>
                                        )}
                                        {iorder === "DESC" && (
                                          <div className="text-right arrow">
                                            <ArrowDropDownIcon
                                              onClick={() => inputdesc("item")}
                                            />
                                          </div>
                                        )}
                                        {iorder === "ASC" && (
                                          <div className="text-right arrow">
                                            <ArrowDropUpIcon
                                              onClick={() => inputasc("item")}
                                            />
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      background: theme.palette.primary.main,
                                    }}
                                  >
                                    <Row>
                                      <Col md={10} className="text-center">
                                        <a
                                          href="#"
                                          className="item_number text-center"
                                        >
                                          Count
                                        </a>
                                      </Col>
                                      <Col md={2}>
                                        {corder === "init" && (
                                          <Row className="text-right arrow">
                                            <Col>
                                              <ArrowDropUpIcon
                                                onClick={() =>
                                                  inputasc("count")
                                                }
                                              />
                                              <ArrowDropDownIcon
                                                onClick={() =>
                                                  inputdesc("count")
                                                }
                                              />
                                            </Col>
                                          </Row>
                                        )}
                                        {corder === "DESC" && (
                                          <div className="text-right arrow">
                                            <ArrowDropDownIcon
                                              onClick={() => inputdesc("count")}
                                            />
                                          </div>
                                        )}
                                        {corder === "ASC" && (
                                          <div className="text-right arrow">
                                            <ArrowDropUpIcon
                                              onClick={() => inputasc("count")}
                                            />
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </th>
                                  <th
                                    data-sort="asc"
                                    scope="col"
                                    style={{
                                      background: theme.palette.primary.main,
                                    }}
                                  >
                                    <Row>
                                      <Col md={10} className="text-center">
                                        <a
                                          href="#"
                                          className="item_number text-center"
                                        >
                                          Billings
                                        </a>
                                      </Col>
                                      <Col md={2}>
                                        {border === "init" && (
                                          <Row className="text-right arrow">
                                            <Col>
                                              <ArrowDropUpIcon
                                                onClick={() =>
                                                  inputasc("billing")
                                                }
                                              />
                                              <ArrowDropDownIcon
                                                onClick={() =>
                                                  inputdesc("billing")
                                                }
                                              />
                                            </Col>
                                          </Row>
                                        )}
                                        {border === "DESC" && (
                                          <div className="text-right arrow">
                                            <ArrowDropDownIcon
                                              onClick={() =>
                                                inputdesc("billing")
                                              }
                                            />
                                          </div>
                                        )}
                                        {border === "ASC" && (
                                          <div className="text-right arrow">
                                            <ArrowDropUpIcon
                                              onClick={() =>
                                                inputasc("billing")
                                              }
                                            />
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.map((item: any, idx: number) => (
                                  <tr>
                                    <td scope="col">{item.AnaItemNumber}</td>
                                    <td scope="col">{item.AnaCount}</td>
                                    <td
                                      scope="col"
                                      style={{
                                        textAlign: "right",
                                        paddingRight: "20px",
                                      }}
                                    >
                                      {item.AnaBillings}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          )}
                        </>
                      )}
                    </div>
                  </Box>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div id={loginfrom} className="itemnum-mobile-view mt-2 m-3 coddgp_mobile_view">
          <Row>
            <Col md={12} className="mx-auto kpi-pad">
              <Box
                className="date_box"
                component={Paper}
                elevation={2}
                p={2}
                sx={{
                  backgroundColor: "primary.main",
                }}
              >
                <Row>
                  <Col xs={6}>
                    <div className="text-center date_name align-item-center mb-2">
                      From Date
                    </div>
                    <div>
                      <DatePicker
                        maxDate={moment(nowDate)}
                        format="DD/MM/YYYY"
                        closeOnSelect={true}
                        sx={{ borderRadius: "15px", fontWeight: 700 }}
                        className="align-item-center sidebar_datebicker d-flex text-center br-20"
                        //label="Controlled picker"
                        value={
                          moment(searchRedux.FromDate.toString())
                            ? moment(searchRedux.FromDate.toString())
                            : moment(fromdate)
                        }
                        onChange={(newValue: any) => {
                          dispatch(UpdatePaymentData("FromDate", newValue));
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="text-center date_name align-item-center mb-2">
                      To Date
                    </div>
                    <div>
                      <DatePicker
                        minDate={
                          moment(searchRedux.FromDate.toString())
                            ? moment(searchRedux.FromDate.toString())
                            : moment().format("YYYY-MM-DD")
                        }
                        maxDate={moment(nowDate)}
                        format="DD/MM/YYYY"
                        closeOnSelect={true}
                        sx={{ borderRadius: "15px" }}
                        className="align-item-center sidebar_datebicker d-flex text-center br-20"
                        //label="Controlled picker"
                        value={
                          moment(searchRedux.ToDate.toString())
                            ? moment(searchRedux.ToDate.toString())
                            : moment().add("5", "days")
                        }
                        onChange={(newValue: any) => {
                          dispatch(UpdatePaymentData("ToDate", newValue));
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Box>

              <Row className="mt-4">
                <Col md={4}>
                  <Button
                    className="MBS-btn"
                    onClick={() => navigate("/mbs-review")}
                    sx={{ p: 1.5 }}
                    fullWidth
                    variant="contained"
                    color="secondary"
                  >
                    MBS REVIEW
                  </Button>
                </Col>
                <Col>
                  <FormControl
                    style={{
                      width: "100%",
                    }}
                  >
                    <FormLabel
                      htmlFor="outlined-adornment-password"
                      className="form-label"
                    ></FormLabel>
                    <OutlinedInput
                      onChange={(e) => {
                        setSearchQuery(e.target.value);

                        SearchData(e.target.value);
                      }}
                      value={searchQuery}
                      id="outlined-adornment-search"
                      placeholder="Search"
                      className="mt-1 input-border"
                      sx={{
                        input: {
                          color: "#4666AE",
                          fontSize: "15px",
                          fontWeight: "400",
                          opacity: "0.6",
                          marginLeft: "5px",
                          "&::placeholder": {
                            // <----- Add this.
                            opacity: 1,
                          },
                        },
                      }}
                      startAdornment={
                        <IconButton className="search-icon">
                          <Search style={{ color: "#4666AE", width: "33px" }} />
                        </IconButton>
                      }
                    />
                  </FormControl>
                </Col>
              </Row>
              <Box
                component={Paper}
                elevation={2}
                p={0}
                mt={3}
                className="boxes"
              >
                <div className="item_container p-0">
                  {searchQuery && data.length === 0 ? (
                    <Box
                      className="date_box  p-5 justify-content-center text-center align-item-center mt-0"
                      component={Paper}
                      elevation={2}
                      p={2}
                      sx={{
                        borderRadius: "10px",
                      }}
                    >
                      <Image
                        style={{
                          margin: 10,
                        }}
                        src={require("../assets/images/Datanotfound.png")}
                        width="216"
                        height="194"
                      />
                      <Typography className="not_fond">
                        Data Not Found
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      {loading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "75vh",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Table
                          responsive
                          bordered
                          className="item_numnbertable"
                          style={{
                            marginBottom: 0,
                            width: "100%",
                          }}
                        >
                          <thead
                            style={{
                              background: theme.palette.primary.main,
                            }}
                          >
                            <tr
                              style={{
                                background: theme.palette.primary.main,
                              }}
                            >
                              <th
                                className="item"
                                scope="col"
                                style={{
                                  background: theme.palette.primary.main,
                                }}
                              >
                                <Row>
                                  <Col xs={10} className="text-center p-0 m-0">
                                    <a
                                      href="#"
                                      className="item_number text-center"
                                    >
                                      Item Number
                                    </a>
                                  </Col>
                                  <Col xs={2} className="text-center p-0 m-0">
                                    {iorder === "init" && (
                                      <Row className="text-right arrow">
                                        <Col>
                                          <ArrowDropUpIcon
                                            onClick={() => inputasc("item")}
                                          />
                                          <ArrowDropDownIcon
                                            onClick={() => inputdesc("item")}
                                          />
                                        </Col>
                                      </Row>
                                    )}
                                    {iorder === "DESC" && (
                                      <div className="text-right arrow">
                                        <ArrowDropDownIcon
                                          onClick={() => inputdesc("item")}
                                        />
                                      </div>
                                    )}
                                    {iorder === "ASC" && (
                                      <div className="text-right arrow">
                                        <ArrowDropUpIcon
                                          onClick={() => inputasc("item")}
                                        />
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                              </th>
                              <th
                                scope="col"
                                style={{
                                  background: theme.palette.primary.main,
                                }}
                              >
                                <Row>
                                  <Col xs={8} className="text-center">
                                    <a
                                      href="#"
                                      className="item_number text-center"
                                    >
                                      Count
                                    </a>
                                  </Col>
                                  <Col xs={2}>
                                    {corder === "init" && (
                                      <Row className="text-right arrow">
                                        <Col>
                                          <ArrowDropUpIcon
                                            onClick={() => inputasc("count")}
                                          />
                                          <ArrowDropDownIcon
                                            onClick={() => inputdesc("count")}
                                          />
                                        </Col>
                                      </Row>
                                    )}
                                    {corder === "DESC" && (
                                      <div className="text-right arrow">
                                        <ArrowDropDownIcon
                                          onClick={() => inputdesc("count")}
                                        />
                                      </div>
                                    )}
                                    {corder === "ASC" && (
                                      <div className="text-right arrow">
                                        <ArrowDropUpIcon
                                          onClick={() => inputasc("count")}
                                        />
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                              </th>
                              <th
                                data-sort="asc"
                                scope="col"
                                style={{
                                  background: theme.palette.primary.main,
                                }}
                              >
                                <Row>
                                  <Col xs={8} className="text-center">
                                    <a
                                      href="#"
                                      className="item_number text-center"
                                    >
                                      Billings
                                    </a>
                                  </Col>
                                  <Col xs={2}>
                                    {border === "init" && (
                                      <Row className="text-right arrow">
                                        <Col>
                                          <ArrowDropUpIcon
                                            onClick={() => inputasc("billing")}
                                          />
                                          <ArrowDropDownIcon
                                            onClick={() => inputdesc("billing")}
                                          />
                                        </Col>
                                      </Row>
                                    )}
                                    {border === "DESC" && (
                                      <div className="text-right arrow">
                                        <ArrowDropDownIcon
                                          onClick={() => inputdesc("billing")}
                                        />
                                      </div>
                                    )}
                                    {border === "ASC" && (
                                      <div className="text-right arrow">
                                        <ArrowDropUpIcon
                                          onClick={() => inputasc("billing")}
                                        />
                                      </div>
                                    )}
                                  </Col>
                                </Row>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item: any, idx: number) => (
                              <tr className="itm-custom-td">
                                <td scope="col">{item.AnaItemNumber}</td>
                                <td scope="col">{item.AnaCount}</td>
                                <td
                                  scope="col"
                                  style={{
                                    textAlign: "right",
                                    paddingRight: "15px",
                                  }}
                                >
                                  {item.AnaBillings}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                    </>
                  )}
                </div>
              </Box>
            </Col>
          </Row>
        </div>
      )}
    </Fragment>
  );
};
