import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { PaymentState as SearchReduxState, useSelector } from "../redux";
import { useDispatch } from "react-redux";
import { UpdatePaymentData } from "../redux/User/actions";
// New Drawer design imports
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { getApi } from "../services";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SettingsIcon from "@mui/icons-material/Settings";

const drawerWidth = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  elevation: 0,
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  elevation: 0,
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "center",
}));
export const SidebarList: React.FC = () => {
  // Redux
  const searchRedux = useSelector<SearchReduxState>(
    (state) => state.paymentReducer
  );
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getapi = getApi();

  // Responsive Drawer
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const logout = () => {
    document.cookie =
      "uniquevalue=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // Check if we are in an iOS WebView environment
    const isIOSWebView =
      /iPhone|iPad|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

    if (isIOSWebView) {
      // Check if 'webkit' and 'messageHandlers' are available
      if ((window as any).webkit && (window as any).webkit.messageHandlers) {
        // Call the 'postMessage' method
        (window as any).webkit.messageHandlers.logoutAction.postMessage(
          "logout"
        );
      } else {
        // Handle the case where 'webkit' or 'messageHandlers' is not available
        console.error("WebView environment does not support postMessage.");
      }
    } else {
      // Handle the case where we are not in an iOS WebView
      console.error(
        "This code should be executed in an iOS WebView environment."
      );
    }
    let data = {
      user_id: searchRedux.userid,
      user_name: searchRedux.username,
      role: searchRedux.role,
    };
    getapi
      .post("halologout/", data)
      .then((res) => {
        //console.log("logout response:->", res);

        if (res.data.Status) {
          dispatch(UpdatePaymentData("userid", ""));
        }
      })
      .catch((err) => {
        //console.log("error", err);
      });
  };

  
  return (
    <>
      <DrawerHeader>
        <Link to={"/sidebar"}>
          <Image
            style={{
              margin: 10,
            }}
            src={require("../assets/images/logo.png")}
            width="240"
            height="60"
          />
        </Link>
        {/*
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
      */}
      </DrawerHeader>
      <Divider />
      <List component="nav" className="side_menu">
        <ListItem key={"Sidebar"} disablePadding>
          <ListItemButton
            onClick={() => navigate("/sidebar")}
            selected={location.pathname === "/sidebar"}
          >
            <ListItemIcon>
              <Image src={require("../assets/images/Drawer/Sidebar.png")} />
            </ListItemIcon>
            <ListItemText primary={"Sidebar"} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"kpi"} disablePadding>
          <ListItemButton
            onClick={() => navigate("/kpi")}
            selected={location.pathname === "/kpi"}
          >
            <ListItemIcon>
              <Image src={require("../assets/images/Drawer/KPI.png")} />
            </ListItemIcon>
            <ListItemText primary={"KPI"} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"Payments"} disablePadding>
          <ListItemButton
            onClick={() => navigate("/payments")}
            selected={location.pathname === "/payments"}
          >
            <ListItemIcon>
              <Image src={require("../assets/images/Drawer/Payments.png")} />
            </ListItemIcon>
            <ListItemText primary={"Payments"} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"Item Numbers"} disablePadding>
          <ListItemButton
            onClick={() => navigate("/itemnumber")}
            selected={
              location.pathname === "/itemnumber" ||
              location.pathname === "/mbs-review"
            }
          >
            <ListItemIcon>
              <Image src={require("../assets/images/Drawer/ItemNumber.png")} />
            </ListItemIcon>
            <ListItemText primary={"Item Numbers"} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"Followup"} disablePadding>
          <ListItemButton
            onClick={() => navigate("/followup")}
            selected={location.pathname === "/followup"}
          >
            <ListItemIcon>
              <Image src={require("../assets/images/Drawer/Followup.png")} />
            </ListItemIcon>
            <ListItemText primary={"Follow Up"} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"Predictor"} disablePadding>
          <ListItemButton
            onClick={() => navigate("/predictor")}
            selected={location.pathname === "/predictor"}
          >
            <ListItemIcon>
              <Image
                width={24}
                src={require("../assets/images/Drawer/Predictor.png")}
              />
            </ListItemIcon>
            <ListItemText primary={"Predictor"} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"SMS"} disablePadding>
          <ListItemButton
            onClick={() => navigate("/sendsms")}
            selected={location.pathname === "/sendsms"}
          >
            <ListItemIcon>
              <Image
                width={24}
                src={require("../assets/images/Drawer/SMS.png")}
              />
            </ListItemIcon>
            <ListItemText primary={"SMS"} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"CDM"} disablePadding>
          <ListItemButton
            onClick={() => navigate("/cdm")}
            selected={
              location.pathname === "/cdm" ||
              location.pathname === "/cdm/diabetes" ||
              location.pathname === "/cdm/ischemic-heart-disease" ||
              location.pathname === "/cdm/HealthAssBtwFivetoNine" ||
              location.pathname === "/cdm/Healthoversevenfive"
            }
          >
            <ListItemIcon>
              <Image
                width={24}
                src={require("../assets/images/clinicalicon/wCMD.png")}
              />
            </ListItemIcon>
            <ListItemText primary={"CDM"} />
          </ListItemButton>
        </ListItem>

        <ListItem key={"settings"} disablePadding>
          <ListItemButton
            onClick={() => navigate("/settings")}
            selected={
              location.pathname === "/settings" ||
              location.pathname === "/blockedpatient" ||
              location.pathname === "/aptreason" ||
              location.pathname === "/blockedsession" ||
              location.pathname === "/blockedsessionform"
            }
          >
            <ListItemIcon>
              <SettingsIcon style={{ color: "#fff", fontSize: 24 }} />
            </ListItemIcon>
            <ListItemText primary={"Settings"} />
          </ListItemButton>
        </ListItem>

        {searchRedux.role === "superadmin" && (
          <ListItem key={"loginuser"} disablePadding>
            <ListItemButton
              onClick={() => navigate("/loginuser")}
              selected={location.pathname === "/loginuser"}
            >
              <ListItemIcon>
                <Image src={require("../assets/images/Drawer/Profile.png")} />
              </ListItemIcon>
              <ListItemText primary={"Login User"} />
            </ListItemButton>
          </ListItem>
        )}
        <ListItem key={"Log Out"} disablePadding>
          <ListItemButton onClick={() => logout()}>
            <ListItemIcon>
              <Image src={require("../assets/images/Drawer/Logout.png")} />
            </ListItemIcon>
            <ListItemText primary={"Log Out"} />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );
};
