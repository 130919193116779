// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pd-r-top-0{
  padding-right: 0px !important;
}
.cus-b-radus{
  border-radius: 25px !important;
}
.cus-b-radus-l{
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
}


@media (max-width: 380px) {
  .logincss{
    margin-left: 0px !important;
    padding: 0px !important;
    overflow-x: hidden;
  }
  main .login-mobile-view {
    margin-left: 0px!important;
  }
  .img-fluid{
    width: 100% !important;
  }
  .col-pad-img{
    padding: 13px;
    padding-left: 0px;
  }
  .login-mobile-view.loginbanner .custom-banner {
    border-bottom-left-radius: 40px !important;
    border-bottom-right-radius: 40px !important;
  }
}

@media (max-width: 500px) {
  .logincss{
    margin-left: 0px !important;
    padding: 0px !important;
    overflow-x: hidden;
  }
  .logincss .login-mobile-view {
    margin-left: 0px!important;
  }
  .logincss .img-fluid{
    width: 100% !important;
  }
  .col-pad-img{
    padding: 13px;
    padding-left: 0px;
  }
  .login-mobile-view.loginbanner .custom-banner {
    border-bottom-left-radius: 40px !important;
    border-bottom-right-radius: 40px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/login.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;AACA;EACE,8BAA8B;AAChC;AACA;EACE,uCAAuC;EACvC,0CAA0C;AAC5C;;;AAGA;EACE;IACE,2BAA2B;IAC3B,uBAAuB;IACvB,kBAAkB;EACpB;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,sBAAsB;EACxB;EACA;IACE,aAAa;IACb,iBAAiB;EACnB;EACA;IACE,0CAA0C;IAC1C,2CAA2C;EAC7C;AACF;;AAEA;EACE;IACE,2BAA2B;IAC3B,uBAAuB;IACvB,kBAAkB;EACpB;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,sBAAsB;EACxB;EACA;IACE,aAAa;IACb,iBAAiB;EACnB;EACA;IACE,0CAA0C;IAC1C,2CAA2C;EAC7C;AACF","sourcesContent":[".pd-r-top-0{\n  padding-right: 0px !important;\n}\n.cus-b-radus{\n  border-radius: 25px !important;\n}\n.cus-b-radus-l{\n  border-top-left-radius: 25px !important;\n  border-bottom-left-radius: 25px !important;\n}\n\n\n@media (max-width: 380px) {\n  .logincss{\n    margin-left: 0px !important;\n    padding: 0px !important;\n    overflow-x: hidden;\n  }\n  main .login-mobile-view {\n    margin-left: 0px!important;\n  }\n  .img-fluid{\n    width: 100% !important;\n  }\n  .col-pad-img{\n    padding: 13px;\n    padding-left: 0px;\n  }\n  .login-mobile-view.loginbanner .custom-banner {\n    border-bottom-left-radius: 40px !important;\n    border-bottom-right-radius: 40px !important;\n  }\n}\n\n@media (max-width: 500px) {\n  .logincss{\n    margin-left: 0px !important;\n    padding: 0px !important;\n    overflow-x: hidden;\n  }\n  .logincss .login-mobile-view {\n    margin-left: 0px!important;\n  }\n  .logincss .img-fluid{\n    width: 100% !important;\n  }\n  .col-pad-img{\n    padding: 13px;\n    padding-left: 0px;\n  }\n  .login-mobile-view.loginbanner .custom-banner {\n    border-bottom-left-radius: 40px !important;\n    border-bottom-right-radius: 40px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
